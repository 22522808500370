import 'react-toastify/dist/ReactToastify.css'; 
import moment from 'moment';
import { useState, useEffect } from 'react';
import Axios from "../../axiosInstance";


import {
    Card,
  } from '@mui/material';
export default function AuditLog(props) {
    const [createdBy, setCreatedBy] = useState({firstName:'',lastName:''});
    const [updatedBy, setUpdatedBy] = useState({firstName:'',lastName:''});
    const { prop } = props;

    const getCreatedByUserDetails = async () => {
        Axios.get(`api/users/${prop.createdBy}`)
        .then((response) => { 
            setCreatedBy(response.data)
        }).catch((error) => {
            console.error(error);
        });
    };

    const getUpdatedByUserDetails = async () => {
        Axios.get(`api/users/${prop?.lastModifiedBy}`)
        .then((response) => { 
            setUpdatedBy(response.data)
        }).catch((error) => {
            console.error(error);
        });
    };
    useEffect(() => { 
        getCreatedByUserDetails()
        getUpdatedByUserDetails()
    }, [prop]);
    const goBack = () => {
        if (window.history.length > 1) {
          window.history.back();
        } else {
          window.close();
        }
    };
    return (
        <>
            
            <section>

                <Card className={`mt-5 pt-5 px-5`}>
                    <div className="audit-log-header">
                        <h3>System Information:</h3>
                        <p className='audit-log-back-button' onClick={goBack}>Back</p>
                    </div>
                    <hr />
                    <p>
                        <strong>
                            Created By: 
                        </strong>
                        &nbsp;&nbsp;
                        {createdBy?.firstName +" "+ createdBy?.lastName}
                        {/* {prop?.createdBy} */}
                    </p>
                    <p>
                        <strong>
                            Date: 
                        </strong>
                        &nbsp;&nbsp;
                        {prop?.creationDate? moment(prop?.creationDate).format("MMMM Do, YYYY hh:mm A"):''}
                    </p>
                    <p>
                        <strong>
                            Last Modified By: 
                        </strong>
                        &nbsp;&nbsp;
                        {updatedBy?.firstName +" "+ updatedBy?.lastName}
                        {/* {prop?.lastModifiedBy} */}
                    </p>
                    <p>
                        <strong>
                            Date: 
                        </strong>
                        &nbsp;&nbsp;
                        {prop?.lastModifiedDate?moment(prop?.lastModifiedDate).format("MMMM Do, YYYY hh:mm A"):''}
                    </p>
                    {/* <div className="audit-log-header">
                        <h3></h3>
                        <p className='audit-log-back-button' onClick={goBack}>Back</p>
                    </div> */}
                
                </Card> 
            </section>
        </>
    );
}
