import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthContextProvider } from "./context/AuthContext"
import { BaseUrlContextProvider } from "./context/BaseUrlContext"
import { BaseUrlContextProvider2 } from "./context/BaseUrlContext2"
// import { ToastProvider } from 'react-hot-toast';


import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    {/* <ToastProvider> */}
      <BrowserRouter>
        <AuthContextProvider>
          <BaseUrlContextProvider>
          <BaseUrlContextProvider2>
              <App />
          </BaseUrlContextProvider2>
          </BaseUrlContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    {/* </ToastProvider> */}
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
