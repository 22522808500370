import { useRef, useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

HC_exporting(Highcharts);

const ColumnChart = (props) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!props.data || Object.keys(props.data).length === 0) {
      return; 
    }

    const years = Object.keys(props?.data);
      console.log("Column",props?.data);
      const categories = props?.data[years[0]]?.map(entry => entry?.month ??'Years');
      const seriesData = years.map(year => ({
        name: year,
        data: props.data[year].map(entry => entry.percentage)
      }));
    const customColors = ['#3c8dbc', '#1EB53A','#f5c700', '#f7a35c', '#ffbc75', '#8085e9', '#f15c80'];
    // Configuration options for the chart
    const options = {
      chart: {
        type: 'column'
      },
      title: {
        text: props.title,
      },
      xAxis: {
        categories: categories,
        crosshair: true,
        accessibility: {
          description: 'Months'
        },
        gridLineWidth: 1,
        gridLineColor: '#e0e0e0', 
        gridLineDashStyle: 'Dot', 
        tickmarkPlacement: 'on',
        tickLength: 10,
        tickWidth: 1, 
        lineColor: '#000',
        lineWidth: 1
      },
      yAxis: {
        title: {
          text: 'Percentage (%)'
        }
      },
      tooltip: {
        valueSuffix: '%'
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          dataLabels: {
            enabled: true, 
            format: '{point.y}%' 
          }
        }
      },
      series: seriesData,
      colors: customColors,
      credits: {
        enabled: false 
      },
      legend: {
        layout: 'horizontal', 
        align: 'center',
        verticalAlign: 'bottom',
        itemMarginTop: 10,
        itemMarginBottom: 10,
        borderWidth: 1
        // layout: 'vertical', 
        // align: 'right',
        // verticalAlign: 'top',
        // y: 50,  
        // itemMarginTop: 10,
        // itemMarginBottom: 10,
        // borderWidth: 1
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'vertical',//'horizontal'
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    };

    // Create the chart
    if (chartRef.current) {
      chartRef.current.chart = Highcharts.chart(chartRef.current, options);
    }

    // Clean up function
    return () => {
      if (chartRef.current && chartRef.current.chart) {
        chartRef.current.chart.destroy();
      }
    };
  }, [props.data, props.title]);

  return <div ref={chartRef} style={{ height: '474px' }}></div>;
  // return (
  //   <div style={{ position: 'relative' }}>
  //     <div ref={chartRef} style={{ height: '474px' }}></div>
  //     <div style={{ position: 'absolute', top: '50px', left: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  //       <div style={{
  //         background: 'linear-gradient(to right, #1EB53A, #f5c700, #3c8dbc)',
  //         width: '200px',
  //         height: '20px'
  //       }}></div>
  //     </div>
  //   </div>
  // );
};

export default ColumnChart;
