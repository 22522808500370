import React, { useState } from 'react';

const MySelectBox = ({ value, onSelect, optionList, title, disabled, required }) => {
  const [selectedOption, setSelectedOption] = useState(value);

  const handleChange = (event) => {
    let selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onSelect(selectedValue);
  };

  return (
    <div>
      {/* <label htmlFor="select-box">{title}</label> */}
      <select
        id="select-box"
        value={selectedOption}
        onChange={handleChange}
        disabled={disabled ?? false}
        required={required ?? false}
        style={{padding:'5px', width:'60px', fontSize:'14px'}}
      >
        {/* <option value="">Select</option> */}
        {optionList.map((value) => (
          <option key={value.id} value={value.id}>
            {value.name ?? value.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MySelectBox;
