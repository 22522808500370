import React, { useState } from 'react';
import { styled } from '@mui/system';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Chip from '@mui/material/Chip';

const FormContainer = styled(FormControl)`
  margin: ${(props) => props.theme.spacing(0)};
  min-width: 100%;
  max-width: 100%;
`;

const ChipsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const ChipStyled = styled(Chip)`
  margin: ${(props) => props.theme.spacing(0.5)};
`;

const MultipleSelect = (props) => {
  // console.log("props",props.items);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleChange = (event) => {
    const selected = event.target.value;
    setSelectedItems(selected);
    props.onSelectedItems(selected);
    // console.log(selected);
  };

  return (
    <FormContainer className='multiple_select_lg_size'
    sx={{
        "& .MuiInputLabel-root": {
          color: "#808080"
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#808080"
          },
          "&:hover fieldset": {
            borderColor: "#808080"
          },
          "&.Mui-focused": {
            "& fieldset": {
              borderColor: "#3c8dbc"
            },
            "& .MuiInputLabel-root": {
              color: "#3c8dbc"
            }
          }
        }
      }}
    >
      <InputLabel id="multiple-select-label">{props.title}</InputLabel>
      <Select
        labelId="multiple-select-label"
        id="multiple-select"
        label={props.title}
        multiple
        value={selectedItems}
        onChange={handleChange}
        renderValue={(selected) => (
          <ChipsContainer>
            {selected.map((value) => (
              <ChipStyled key={value.id} label={value.name} />
            ))}
          </ChipsContainer>
        )}
      >
        {/* <MenuItem value="Item 1">Item 1</MenuItem> */}
        {props.items.map((item) => (
          <MenuItem key={item.id} value={item}>
            {props.name=== "selectTeamCodes"?item.code:item.name}
          </MenuItem>
        ))}

      </Select>
    </FormContainer>
  );
};

export default MultipleSelect;
