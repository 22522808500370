import { useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

HC_exporting(Highcharts);

const LineChart = (props) => {
  const { title, data } = props;
    // console.log("line chart test", data);
  const firstYear = Object.keys(data)[0];
  const yearData = data[firstYear];
  if (!Array.isArray(yearData) || yearData.length === 0) {
    return <div>No data available for this chart yet</div>;
  }

  const seriesData = yearData.map(item => ({
    name: item.month,
    y: item.percentage,
    numerator: item.numerator,
    denominator: item.denominator,
  }));

  const options = {
    chart: {
      type: 'line', 
    },
    title: {
      text: title,
      style: {
        fontSize: '15px',           
        fontWeight: 'bold',
        color: '#333',
        textAlign: 'center',
        margin: '20px 0',
        fontFamily: 'josefin-sans-header, sans-serif, Arial',
      },
    },
    yAxis: {
      title: {
        text: 'Percentage (%)',
      },
      labels: {
        formatter: function () {
          return this.value + '%'; 
        }
      }
    },
    xAxis: {
      categories: yearData.map(item => item.month),
      accessibility: {
        rangeDescription: `Range: ${firstYear} January to December`,
      },
      gridLineWidth: 1,
      gridLineColor: '#e0e0e0', 
      gridLineDashStyle: 'Dot', 
      tickmarkPlacement: 'on',
      tickLength: 10,
      tickWidth: 1, 
      lineColor: '#000',
      lineWidth: 1
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: 0, 
      },
    },
    tooltip: {
        formatter: function () {
          const point = this.point;
          // <b>${this.series.name}</b><br/> 
          return `<b>${this.x}</b> <br> <b>Month:</b> ${this.y}%, <br> <b>Numerator:</b> ${point.numerator} <br> <b>Denominator:</b> ${point.denominator}`; 
        }
      },
      credits: {
        enabled: false 
      },
    series: [{
      name: 'Percentage',
      data: seriesData,
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      }],
    },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default LineChart;
