import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
// sections
import { LoginForm } from '../../sections/auth/login';
import TextField from '../../components/inputs/Textbox';

// @mui
// import { IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { login } from '../../actions/authActions';


// ----------------------------------------------------------------------

import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// import { toast } from 'react-hot-toast';
import { Toaster, toast } from 'react-hot-toast';

import { useContext, useState } from "react"
// import { BaseUrlContext } from 'src/context/BaseUrlContext';
import axios from 'axios';
import { useAuth } from 'src/hooks/useAuth';
import { BaseUrlContext } from 'src/context/BaseUrlContext2';




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [otp, setOTP] = useState('');
  const baseUrl = useContext(BaseUrlContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('identity');
  const email = queryParams.get('email');
  
  const goto = (route) => {
    navigate("/"+route)
  };

  const collateTextValue = (event) => {
    const { name, value } = event;
    switch (name) {
      case "otp":
        setOTP(value); 
      default:
        break;
    }
  }

  const notify_user = (message, toast_type) => {
    if (toast_type == 'success') {
      toast.success(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#d0efd0',
          color:"#000000"
        },
      })
    }else if(toast_type == 'error'){
      toast.error(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#f2c4c4',
          color:"#000000"
        },
      })
    }else if (toast_type == 'info'){
      toast.info(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#97ddef',
          color:"#000000"
        },
      })
    }
    
  };

  const handleSubmit = async (event) => {
    setIsPending(true);
    event.preventDefault();
    const formData = {
      user_id:userId,
      otp:otp
    };
    // return console.log(formData);
    axios.post(`${baseUrl}/auth/verify/otp`, formData)
    .then((response) => {
      goto(`reset_password?email=${encodeURIComponent(email)}`)
      // goto(`reset_password`)
    }).catch((error) => {
      if (error.response.data.message) {
        notify_user(error.response.data.message, "error")
      }else{
        notify_user("Email address not sent", "error")
      }
      console.error(error);
    });
  };
  
  const handleClick = () => {
    navigate('/login', { replace: true });
  };
  return (
    <>
      <Toaster />
      <Helmet>
        <title> SLHPV </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-12">
            <StyledContent >
              <Typography variant="h2" gutterBottom>
                {/* Hey! <br/>
                Welcome back */}
              </Typography>

              <Typography variant="h4" gutterBottom>
                {/* Sign in to Vaccination Campaign Team Database (VCTD) */}
              </Typography>
              <p className='theme_link'
                onClick={()=>handleClick()}
              >
                Back to Login
              </p>
              <div className='text-center'>
                <img src="/assets/icons/email_icon.png" alt="" />
              </div>
              
              <Stack spacing={1}sx={{ mt: 0 }}>
                <Typography className='theme_text_color text-center' variant="h4" gutterBottom>
                  A verification Email was sent to you! 
                </Typography>

                <Typography className='text-center' gutterBottom>
                  Kindly check your mailbox and and enter your OTP below. 
                  Check spam folder in case you couldnt find it.
                </Typography>

                <div className="row">
                  <div className="col-md-12 mt-4 mb-4">
                      <TextField 
                          name="otp"
                          title="OTP" 
                          value={otp}
                          required
                          onValueChange={collateTextValue} 
                      />
                  </div>
                </div>
              </Stack>

              <LoadingButton className='bg_green mt-5' fullWidth size="large" type="submit" variant="contained" onClick={handleSubmit}>
              <span>
                Verify &nbsp;
              </span>
              {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
              </LoadingButton>
            </StyledContent>
          </div>

          <div className="col-md-6 col-12 hide backgoundColor">
            {/* <img width="100%" height="790px" src="https://manoreporters.com/wp-content/uploads/2023/08/PDF-111.jpg" alt="verify" /> */}
            {/* <img width="100%" height="790px" src="/assets/icons/verification.png" alt="login" /> */}
          </div>
        </div>
      </div>
        
      </StyledRoot>
    </>
  );
}
