import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Scrollbar from '../../components/scrollbar'; 
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert';
import styles from "./Roles.module.css"
import Axios from "../../axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { permissions, hasPermission } from '../../Authorization'; 
import {
    Card,
    Container,
  } from '@mui/material';
import Pagination from '../Common/Pagination';

  const TABLE_HEAD = [
    { id: 'sn', label: '#', alignRight: false },
    { id: 'roles', label: 'Roles', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
  ];

export default function UserPage() {  
    const [modalOpen, setFacilityModalOpen] = useState(false);
    const [roleName, setRoleName]= useState('');
    const [roleDescription, setRoleDescription]= useState('');
    const [roleList, setRoleList] = useState([]);
    const [response, setResponse] = useState([]);
    const [rolePermissions, setRolePermissions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
    
    const navigate = useNavigate();
    const goto = (d) => {
      navigate('/dashboard/'+d)
    };

    const [loadingUser, setLoadingUser] = useState(false)

    const notify_user = (message, toast_type) => { 
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }else if (toast_type == 'info'){
        toast.info(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#97ddef',
            color:"#000000"
          },
        })
      }
      
    };

    const handleDeleteRole = (id) => {
      Axios.delete(`api/role/${id}`)
      .then((response) => {
        notify_user("Role deleted successfully", "success")
        getRoleList(currentPage);
      }).catch((error) => {
        notify_user("Could not delete Role, try later", "error")
        console.error(error);
      });
    };

    const getRoleList = async (page) => { 
      setLoadingUser(true);

      Axios.get(`api/role?page=${page - 1}&size=20`)
      .then((response) => {
        setLoadingUser(false);
        let data = response?.data?.data.data.sort((a, b) => a.name.localeCompare(b.name));
        setRoleList(data)
        setResponse(response?.data?.data)
        setTotalPages(response?.data?.data?.last_page);

      }).catch((error) => {
        setLoadingUser(false);
        console.error(error);
        const statusCode = error?.response?.status;
        if (statusCode === 401) {
          returnToLogin()
        }
      });
    };
    //Sweet Alert
  const showAlert = (id) => {
    Swal({
      title: 'Warning!',
      text: 'Are you sure you want to delete this Role?',
      icon: 'warning',
      buttons: {
        confirm: {
          text: 'Confirm',
          value: 'confirm',
          className: 'sweet_alert_true',
        },
        extraButton: {
          text: 'cancel',
          value: 'cancel',
          className: 'sweet_alert_false',
        },
      },
    }).then((value) => {
      switch (value) {
        case 'confirm':
          handleDeleteRole(id)
          break;
        case 'cancel':
          // Handle extra button click
          break;
        default:
          // Handle other cases or dismissals
          break;
      }
    });
};
    useEffect(() => {
      getRoleList(currentPage);
    }, []); 

    const returnToLogin = () => {
      localStorage.clear();
      window.location.href = '/login'
    }
  
    return (
      <>
        <Helmet>
          <title> Manage Roles </title>
        </Helmet>
        
  
        {/* <Container> */}
        <ToastContainer />
            <div className={`${styles.header_container} p-4 d-flex mb-3`} >
                <img src="/assets/icons/roles_icon.png" alt="icon" />&nbsp;&nbsp;
                <span className={`${styles.header_text} text-white mt-1`}> Roles </span>
            </div>
            <Card className='p-5 pt-3'>
                <div className=" d-flex justify-content-end">
                {/* {hasPermission("CREATE_ROLE") && */}
                    <button 
                        type="button" 
                        className="btn general_buttons" 
                        onClick={() => goto("add_roles")}
                    >
                        <img src="/assets/icons/Plus.png" alt="" />
                        &nbsp;
                        New Role
                    </button>
                {/* } */}
                </div>
                <Scrollbar>
                    <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}>
                    <table className={`${styles.table} table-hover table-striped`}>
                        <thead className={`${styles.thead}`}>
                        <tr>
                            {TABLE_HEAD.map((el, i) => {
                            return (
                                <th key={i} className="text-muted th">{el.label}</th>
                            )
                            })}
                        </tr>
                        </thead>
                        {roleList.length === 0 ? (
                        <tbody >
                            <tr >
                            <td colSpan={8}>
                                <p className="alert alert-info mt-3 w-100 text-center" role="alert">
                                  No Role available.
                                </p>
                            </td>
                            </tr>
                        </tbody>
                        ):(
                        <tbody>
                            {roleList.map((el, i) => {
                            return (
                                <tr key={i} style={{ cursor: 'pointer' }} className={`${styles.tr}`}>
                                <td key={i}>{i+1}</td>
                                <td>
                                    {el.name}
                                </td>
                                <td>
                                    {el.description}
                                </td>
                                <td>
                                <div>
                                    <span
                                      id="dropdownMenuButton" 
                                      data-toggle="dropdown" 
                                      aria-haspopup="true" 
                                      aria-expanded="false"
                                      className={`${styles.action_button} dropright text-center p-3`} style={{
                                        cursor: 'pointer',
                                    }}>
                                    <img src="/assets/icons/action.svg" alt="" />
                                    </span>
                                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                                    {/* {hasPermission("EDIT_ROLE") && */}
                                      <button type="button" onClick={() => goto("edit_roles?id="+el.id)} className="rss dropdown-item btn border-0">Edit</button>
                                    {/* } */}
                                    {/* {hasPermission("DELETE_ROLE") && */}
                                      <button type="button" onClick={() => showAlert(el.id)} className="rss dropdown-item btn border-0 text-danger">Delete</button>
                                    {/* } */}
                                      </div>
                                </div>

                                </td>
                                </tr>
                                
                            )
                            })}
                        </tbody>
                        )}
                    </table>
                    </div>
                    {loadingUser &&
                    <div className='loader'>
                        <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
                    </div>
                    }
                </Scrollbar>
                {currentPage &&<Pagination
                  page={currentPage}
                  totalPages = {totalPages}
                  onChange={handlePageChange}
                  response = {response}
                /> }
               
            </Card>
        {/* </Container> */}

      </>
    );
  }