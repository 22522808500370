import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useContext } from 'react';
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import { useNavigate, useLocation } from "react-router-dom";
import Scrollbar from '../../components/scrollbar';
import { permissions, hasPermission } from '../../Authorization';

import Select from '../../components/inputs/Select';
import styles from "./Facility.module.css"
import Axios from "../../axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import AuditLog from '../Common/AuditLog';
// import Tab from "../Teams/VaccinationTeam/Tab";


import TextField from '../../components/inputs/Textbox';
import {
    Card,
    Container,
  } from '@mui/material';

export default function UserPage() {  

    const navigate = useNavigate();

 // get values to send to backend
    const [facilityName, setfacilityName] = useState('');
    const [facilityCode, setfacilityCode] = useState('');
    const [operationalBy, setoperationalBy] = useState('');
    const [ward, setWard] = useState('');
    const [state, setState] = useState('');
    const [lga, setLga] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(true);
    const [facilityId, setFacilityId] = useState();
    const [stateList, setStateList] = useState([]);
    const [lgaList, setLgaList] = useState([]);
    const [wardList, setWardList] = useState([]);
    const [isPending, setIsPending] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sytemInformation, setSystemInformation] = useState([]);
    const [response, setResponse] = useState([]);
    const [teamList, setTeamList] = useState([]);


    const statusList  = [
      { id: 1, name: 'Active' },
      { id: 2, name: 'Inactive' },
    ];
  
    const collateTextValue = (event) => {
      const { name, value } = event;
      if (name === "facilityName") {
        setfacilityName(value);
      }else if (name === "facilityCode") {
        setfacilityCode(value);
      }else if (name === "operationalBy") {
        setoperationalBy(value);
      }
    };

    const TABLE_HEAD = [
      { id: 'sn', label: '#' },
      { id: 'code', label: 'Team Code' },
      { id: 'type', label: 'Team Type' },
      { id: 'state', label: 'State' },
      { id: 'lga', label: 'LGA' },
      { id: 'ward', label: 'Ward Name' },
      { id: 'facility', label: 'Facility Name' },
      { id: 'status', label: 'Status' },
      { id: 'action', label: 'Action' },
    ];

    
    const handleSubmit = async (event) => {
      setIsPending(true);
      event.preventDefault();
      let active = status === 1 ? true : false;
      const formData = {
        name:facilityName,
        code:facilityCode,
        operatedBy:operationalBy,
        geoLocation:{
          id:ward
        },
        active
      };
      Axios.put(`api/facilities/${facilityId}`, formData)
      .then((response) => {
        setIsPending(false);
        notify_user("Facility Edited successfully", "success")
        goto("facility")

      }).catch((error) => {
        notify_user("Unable to edit facility", "error")
        console.error(error);
        setIsPending(false);
      });
    };

    
    const getAllStates = async () => { 
      setIsLoading(true);
      Axios.get(`api/geoLocations/level/3?size=50`)
      .then((response) => {
        setStateList(response?.data?.content)
        setIsLoading(false);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    };

    const getAllLgas = async () => { 
      setIsLoading(true);
      Axios.get(`api/geoLocations/${state}/children?size=500`)
      .then((response) => {
        setLgaList(response?.data?.content)
        setIsLoading(false);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    };
    
    const getAllWards = async () => { 
      setIsLoading(true);
      Axios.get(`api/geoLocations/${lga}/children`)
      .then((response) => {
        setWardList(response?.data?.content)
        setWardList(response?.data?.content);
        setIsLoading(false);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    };

    useEffect(() => {
      if (state !== '') {
        getAllLgas();
      }
      if (lga !== '') {
        getAllWards();
      }
    }, [state, lga]); 
  
    useEffect(() => {
      getAllStates();
      // getAllWards();
    }, []); 

    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);
      const id = urlParams.get('id');
      getSingleFacilityDetails(id)
      // getAllUserTypes()
      // getRoles()
    }, [location]);

    const getSingleFacilityDetails = async (id) => { 
      Axios.get(`api/facilities/${id}`)
      .then((response) => {
        setSystemInformation(response?.data)
        setfacilityName(response?.data?.name);
        setfacilityCode(response?.data?.code);
        setoperationalBy(response?.data?.operatedBy);
        setWard(response?.data?.geoLocation?.id);
        setFacilityId(response?.data?.id);
        setStatus(response?.data?.active?1:2);
        setState(response?.data?.geoLocation?.parent?.parent?.id)
        setLga(response?.data?.geoLocation?.parent?.id)
        setLoading(false);
      }).catch((error) => {
        console.error(error);
        setLoading(false);

      });
    };

    const notify_user = (message, toast_type) => {
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }else if (toast_type == 'info'){
        toast.info(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#97ddef',
            color:"#000000"
          },
        })
      }
      
    };

    const goto = (d) => {
      navigate('/dashboard/'+d)
    };

    const handleWardChange = (geoZoneValue) => {
        setWard(geoZoneValue)
    }
    const handleStatusChange = (value) => {
      setStatus(value)
    }
    const handleStateChange = (value) => {
        setState(value)
    }
    const handlelgaChange = (value) => {
        setLga(value)
    }

    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);
      const id = urlParams.get('id');
      getFacilityTeams(id);
    }, []);
   

    const getFacilityTeams = async (id) => { 
      setLoading(true);
       
      Axios.get(`api/facilities/${id}/teams`)
      .then((response) => { 
        let data = response?.data?.content.sort((a, b) => a.name.localeCompare(b.name));
        setTeamList(data);
        setResponse(response?.data.data);
        setLoading(false);
      }).catch((error) => {
        console.error(error);
        setLoading(false);
        if (error?.response?.data.message) {
          notify_user(error?.response?.data?.message, "error")
        }else{
          notify_user("Unable to get Teams", "error")
        }
      });
    };

    const goBack = () => {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        window.close();
      }
    };

    const tabs = [
      {
        label: "Edit Facilities",
        content: <Card className={`${styles.add_hcw_body} p-5 light_card_background`}>
              <form action="" className="mt-0" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-4">
                      <TextField 
                          name="facilityName"
                          title="Facility Name" 
                          value={facilityName}
                          required
                          onValueChange={collateTextValue} 
                      />
                  </div>
                  <div className="col-md-6 mb-1">
                      <TextField 
                          name="facilityCode"
                          value={facilityCode}
                          title="Facility Code" 
                          required
                          onValueChange={collateTextValue} 
                      />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-4">
                      <TextField 
                          name="operationalBy"
                          title="Operational By" 
                          value={operationalBy}
                          onValueChange={collateTextValue} 
                          required={true}
                      />
                  </div>
              
                  <div className="col-md-6 mb-4">
                      <Select
                          name="state"
                          title="State *"
                          value={state}
                          optionList={stateList}
                          onSelect={handleStateChange}
                          required={true}
                        />
                  </div>
                  <div className="col-md-6 mb-1">
                      <Select
                          name="lga"
                          title="Lga *"
                          value={lga}
                          optionList={lgaList}
                          onSelect={handlelgaChange}
                          required={true}
                      />
                  </div>
          
                  <div className="col-md-6 mb-4">
                      <Select
                          name="ward"
                          title="Ward *"
                          value={ward}
                          optionList={wardList}
                          onSelect={handleWardChange}
                          required={true}
                      />
                  </div>
                  <div className="col-md-6 mb-1">
                      <Select
                          name="status"
                          title="Status *"
                          value={status}
                          optionList={statusList}
                          onSelect={handleStatusChange}
                          required={true}
                      />
                  </div>
                </div> 
                <div className="mb-4 mt-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button type="button" className={`btn btn-block buttons_outline`} onClick={()=>goto("facility")}>
                        Back
                    </button>
                    <button type="submit" className={`btn btn-block buttons`}>
                        Update &nbsp; &nbsp;
                        {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                    </button>
                </div>
              </form>
            {isLoading &&
              <div className='dashboard_loader'>
                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
              </div>
            }
        </Card>,
      },
      {
        label: "View teams",
        content: <Card className={`${styles.add_hcw_body} p-5 light_card_background`}>
        <Scrollbar>
          <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}>
          {/* ${styles.hcwtable} */}
              <table className={`${styles.hcw_edit_team_table} table-hover`}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      return (
                        <th key={i} className="text-muted th">{el.label}</th>
                      )
                    })}

                  </tr>

                </thead>
                {teamList.length === 0 ? (
                  <tbody >
                    <tr >
                      <td colSpan={12}>
                        <p className="alert alert-info mt-3 w-100 text-center" role="alert">
                          No team attached to this facility.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                ):(
                <tbody> 
                    {teamList.map((el, i) => {
                      return (
                        <tr key={i} style={{ cursor: 'pointer' }} className={`${styles.tr}`}>
                          <td key={i}>{i+1}</td>
                          <td>
                            {el.code}
                          </td>
                          <td>
                            {el.teamType}
                          </td>
                          <td>
                            {/* {el?.facility?.geoLocation?.parent?.parent?.name} */}
                            {el.facility.geoLocation.parent.parent? el.facility.geoLocation.parent.parent.name : "-"}
                          </td>
                          <td>
                            {/* {el?.facility?.geoLocation?.parent} */}
                            {el.facility.geoLocation.parent? el.facility.geoLocation.parent.name : "-"}
                          </td>
                          <td>
                            {el.facility.geoLocation.name}
                          </td>
                          {/* <td>
                            {el.ward}
                          </td> */}
                          <td>
                            {el.facility.name}
                          </td>
                          {/* <td>
                            <span className={el.status==="Inactive"?"badge_danger":"badge_success"}>
                              {el.status}
                            </span>
                          </td> */}
                          <td>
                            {
                              el.active ? (
                              <span className={"badge_success"}>
                                Active
                              </span>):(
                                <span className={"badge_danger"}>
                                Inactive
                              </span>
                              )
                            }
                          </td>
                          <td>
                          <div>
                            <span
                              id="dropdownMenuButton" 
                              data-toggle="dropdown" 
                              aria-haspopup="true" 
                              aria-expanded="false"
                              className={`${styles.action_button} dropleft text-center p-3`} style={{
                                cursor: 'pointer',
                              }}>
                              <img src="/assets/icons/action.svg" alt="" />
                            </span>
                            <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                            {hasPermission("EDIT_TEAM") &&
                            // <a
                            //   href={'/dashboard/edit_team?id=' + el.id}
                            //   className="dropdown-item btn border-0"
                            //   target='_blank'
                            // >
                            //   Edit
                            // </a>
                              <button type="button" onClick={()=>{goto("edit_team?id="+el.id)}} className="rss dropdown-item btn border-0">Edit</button>
                            }
                            {/* {hasPermission("DELETE_TEAM") &&
                              <button type="button" onClick={() => showAlert(el.id)} className="rss dropdown-item btn border-0 text-danger">Delete</button>
                            } */}
                              </div>
                          </div>

                        </td>
                        </tr>
                        
                      )
                    })}

                </tbody>
                )}
              </table>
            </div>
            {loading &&
              <div className='loader'>
                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
              </div>
            }
            <div className="mb-4 mt-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button type="button" className={`btn btn-block buttons_outline`} onClick={()=>goBack()}>
                      Back
                  </button> 
              </div>
        </Scrollbar>
        </Card>,
      }
    ];



    if (loading) {
      return <div>Loading...</div>;
    }
  
    return (
      <>
        <Helmet>
          <title> Edit Facility | VTCD </title>
        </Helmet>
        
  
        <Container>
        <ToastContainer />
        <div className={`${styles.header_container} p-4 d-flex mb-3`} >
                <span className={`${styles.header_text}`}> Edit Facility </span>
        </div>
          {/* <Card className='p-4 light_card_background'> */}
            {/* <form action="" className="mt-0" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-4">
                      <TextField 
                          name="facilityName"
                          title="Facility Name" 
                          value={facilityName}
                          required
                          onValueChange={collateTextValue} 
                      />
                  </div>
                  <div className="col-md-6 mb-1">
                      <TextField 
                          name="facilityCode"
                          value={facilityCode}
                          title="Facility Code" 
                          required
                          onValueChange={collateTextValue} 
                      />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-4">
                      <TextField 
                          name="operationalBy"
                          title="Operational By" 
                          value={operationalBy}
                          onValueChange={collateTextValue} 
                          required={true}
                      />
                  </div>
              
                  <div className="col-md-6 mb-4">
                      <Select
                          name="state"
                          title="State *"
                          value={state}
                          optionList={stateList}
                          onSelect={handleStateChange}
                          required={true}
                        />
                  </div>
                  <div className="col-md-6 mb-1">
                      <Select
                          name="lga"
                          title="Lga *"
                          value={lga}
                          optionList={lgaList}
                          onSelect={handlelgaChange}
                          required={true}
                      />
                  </div>
          
                  <div className="col-md-6 mb-4">
                      <Select
                          name="ward"
                          title="Ward *"
                          value={ward}
                          optionList={wardList}
                          onSelect={handleWardChange}
                          required={true}
                      />
                  </div>
                  <div className="col-md-6 mb-1">
                      <Select
                          name="status"
                          title="Status *"
                          value={status}
                          optionList={statusList}
                          onSelect={handleStatusChange}
                          required={true}
                      />
                  </div>
                </div> 
                <div className="mb-4 mt-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button type="button" className={`btn btn-block buttons_outline`} onClick={()=>goto("facility")}>
                        Back
                    </button>
                    <button type="submit" className={`btn btn-block buttons`}>
                        Update &nbsp; &nbsp;
                        {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                    </button>
                </div>
              </form>

            {isLoading &&
              <div className='dashboard_loader'>
                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
              </div>
            } */}
            {/* <Tab tabs={tabs} />  */}
          {/* </Card> */}

          <AuditLog prop={sytemInformation}/>
        </Container>

      </>
    );
  }