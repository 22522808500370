import React, { useRef, useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import highchartsHeatmap from 'highcharts/modules/heatmap';
highchartsHeatmap(Highcharts);
HC_exporting(Highcharts);

Highcharts.Templating = { helpers: {} };
Highcharts.Templating.helpers.substr = (s, from, length) => s.substr(from, length);

const HeatMapChart = (props) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (chartRef && chartRef.current) { 
            const chart = chartRef.current.chart; 
        }
    }, []);

    const transformData = () => {
        const years = Object.keys(props.data); 

        const indexToUse = years.findIndex(key => !isNaN(parseInt(key)));
       
  
        const categories = props?.data[years[indexToUse]]?.map(item => item?.location);
        // const categories = props?.data[years[1]]?.map(item => item?.location);

        // Prepare the data array for heatmap series
        const data = years.flatMap(year =>
            props?.data[year]?.map((item, index) => [index, years?.indexOf(year), item?.total_girls])
        );
        

        return {
            categories: categories,
            data: data
        };
    };

    const transformedData = transformData();

    let options = {};

    if (transformedData.data.length > 0) {
        options = {
            chart: {
                type: 'heatmap',
                marginTop: 60,
                marginBottom: 80,
                plotBorderWidth: 1
            },
            title: {
                text: props.title,
                align: 'center',
                verticalAlign: 'top',
                rotation: -90, 
                style: {
                    fontSize: '1em'
                }
            },
            xAxis: {
                categories: transformedData.categories
            },
            yAxis: {
                categories: Object.keys(props.data),
                title: null,
                reversed: true
            },
            accessibility: {
                point: {
                    descriptionFormatter: function (point) {
                        return `${this.xAxis.categories[point.x]} sales ${this.yAxis.categories[point.y]}, ${point.value}.`;
                    }
                }
            },
            colorAxis: {
                min: 0,
                minColor: '#FFFFFF',
                maxColor: Highcharts.getOptions().colors[0]
            },
            legend: {
                align: 'right',
                layout: 'vertical',
                margin: 0,
                verticalAlign: 'top',
                y: 25,
                symbolHeight: 280
            },
            tooltip: {
              formatter: function () {
                return `<b>${this.series.xAxis.categories[this.point.x]}</b><br>` +
                       `<b>Vaccination: ${Highcharts.numberFormat(this.point.value, 0, '.', ',')}</b><br>` +
                       `<b>Year ${this.series.yAxis.categories[this.point.y]}</b>`;
              }
            },
            series: [{
                name: 'Vaccination per location',
                borderWidth: 1,
                data: transformedData.data,
                dataLabels: {
                    enabled: true,
                    color: '#000000'
                }
            }],
            credits: {
                enabled: false 
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        yAxis: {
                            labels: {
                                format: '{value}'
                            }
                        }
                    }
                }]
            }
        };
    } else {
        options = {
            title: {
                text: 'Please select a Council to view Chart',
                align: 'center',
                verticalAlign: 'middle'
            },
            credits: {
                enabled: false 
            }
        };
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
            />
        </div>
    );
};

export default HeatMapChart;
