import React, { useEffect, useState } from 'react';
import { MapContainer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as shapefile from 'shapefile';

const MyMap = ({ data }) => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [featureColors, setFeatureColors] = useState({});
  const [highlightedFeatures, setHighlightedFeatures] = useState(new Set());
  const [dataLookup, setDataLookup] = useState({});
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const fetchShapeFile = async (shpPath, dbfPath) => {
      try {
        const shpResponse = await fetch(shpPath);
        const shpArrayBuffer = await shpResponse.arrayBuffer();

        const dbfResponse = await fetch(dbfPath);
        const dbfArrayBuffer = await dbfResponse.arrayBuffer();

        const source = await shapefile.open(shpArrayBuffer, dbfArrayBuffer);
        const features = [];
        let result = await source.read();
        while (!result.done) {
          features.push(result.value);
          result = await source.read();
        }

        return { type: 'FeatureCollection', features: features };
      } catch (error) {
        console.error('Error loading shapefile:', error);
        return null;
      }
    };

    const fetchAllShapeFiles = async () => {
      try {
        const shapefiles = [
          {
            shp: '/assets/chiefdomShapefiles/chiefdoms.shp',
            dbf: '/assets/chiefdomShapefiles/chiefdoms.dbf',
            cpg: '/assets/chiefdomShapefiles/chiefdoms.cpg',
            prj: '/assets/chiefdomShapefiles/chiefdoms.prj',
            sbn: '/assets/chiefdomShapefiles/chiefdoms.sbn',
            sbx: '/assets/chiefdomShapefiles/chiefdoms.sbx',
            shx: '/assets/chiefdomShapefiles/chiefdoms.shx',
            xml: '/assets/chiefdomShapefiles/chiefdoms.shp.xml'
          },
        ]; 

        const geoJsonPromises = shapefiles.map(async (shpfile) => {
          const geoJson = await fetchShapeFile(shpfile.shp, shpfile.dbf, shpfile.cpg, shpfile.prj, shpfile.sbn, shpfile.sbx);
          return geoJson;
        });

        const allGeoJson = await Promise.all(geoJsonPromises);

        const combinedFeatures = allGeoJson.reduce((acc, geoJson) => {
          if (geoJson) {
            acc.push(...geoJson.features);
          }
          return acc;
        }, []);

        setGeoJsonData({ type: 'FeatureCollection', features: combinedFeatures });

        const colors = {};
          combinedFeatures.forEach((feature) => {
          colors[feature.properties.New_Chief] = getRandomColor();
        });
        setFeatureColors(colors);
      } catch (error) {
        console.error('Error loading shapefiles:', error);
      }
    };

    fetchAllShapeFiles();

    return () => {};
  }, []);

  useEffect(() => {
    // console.log("dataLenght", Object.keys(data).length)
    if (data && Object.keys(data).length > 0) {
      const highlighted = new Set();
      data[Object.keys(data)[0]].forEach((item) => {
        const feature = getFeatureByLocation(item.location);
        if (feature) {
          highlighted.add(feature.properties.New_Chief);
        }
      });
      setHighlightedFeatures(highlighted);
      
      const lookup = data[Object.keys(data)[0]].reduce((acc, item) => { 
        acc[item.location] = item;
        return acc;
      }, {});
      // console.log("lookup",lookup);
      setDataLookup(lookup);
    } else {
      setHighlightedFeatures(new Set());
      setDataLookup({});
    }
  }, [data, geoJsonData]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const style = (feature) => {
    const featureId = feature.properties.New_Chief;
    if (highlightedFeatures.size > 0) {
      if (highlightedFeatures.has(featureId)) {
        return {
          fillColor: featureColors[featureId] || getRandomColor(),
          weight: '0.5',
          opacity: 1,
          color: '#37393d',
          fillOpacity: 0.7,
        };
      } else {
        return {
          fillColor: '#c0c1c5',
          weight: '0.5',
          opacity: 1,
          color: '#37393d',
          fillOpacity: 0.7,
        };
      }
    } else {
      return {
        fillColor: '#c0c1c5',
        weight: '0.5',
        opacity: 1,
        color: 'black',
        fillOpacity: 0.7,
      };
    }
  };

const onEachFeature = (feature, layer) => {
  // console.log("Shape files data",feature.properties);
  // console.log("Shape files features",feature);
  setLoading(true);
  const chiefName = feature.properties.New_Chief+" Chiefdom";
  // console.log("chiefName", chiefName) 
  // console.log(dataLookup)
  const dataItem = dataLookup[chiefName];
  // console.log("dataItem", dataItem)
  const tooltipText = dataItem
    ? `${chiefName}: Total Girls Vaccinated - ${dataItem.total_girls} (${dataItem.year})`
    : chiefName;
  
  layer.bindTooltip(tooltipText);

  layer.on('mouseover', function (e) {
    this.openTooltip();
  });
  layer.on('mouseout', function (e) {
    this.closeTooltip();
  });
  setLoading(false);
};
  

  const getFeatureByLocation = (location) => {
    if (!geoJsonData) return null;
    let parts = location.split(' ');
    parts.pop();
    let locationName = parts.join(' ').toUpperCase();
    // console.log('locationNames', locationName)
    const foundFeature = geoJsonData.features.find(
      (feature) => {
        const newChief = feature.properties.New_Chief.toUpperCase();
        return newChief === locationName;
      }
    );
    return foundFeature;
  };

  const  getRandomNumber = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <MapContainer
      center={[8.4606, -11.7799]}
      zoom={7}
      style={{ height: '71vh', width: '100%' }}
      scrollWheelZoom={false}
      zoomControl={false}
      attributionControl={false}
      className='custom-map-container'
    >
      {geoJsonData && (
        geoJsonData.features.map((feature) => (
          <GeoJSON
            key={feature.properties.New_Chief + getRandomNumber("12345", "67890") }
            data={feature}
            style={style(feature)}
            onEachFeature={onEachFeature}
          />
        ))
      )}
    </MapContainer>
  );
};

export default MyMap;
