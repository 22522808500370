// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Coverage_header_container__K7MCN{
    background-color: #3c8dbc;
}
.Coverage_header_text__LKmgq{
    margin-top: 5px;
    font-weight: 700;
    font-size: 20px;
}

.Coverage_table__sTp-l {
    width: 100% !important;
}

.Coverage_thead__5L46W  th {
    border-bottom: 1px solid #EAECF0 !important;
    border-top: 1px solid #EAECF0 !important;
    color: #6B7280 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    padding: 16px !important;
    padding-left: 23px !important;
    padding-right: 9px !important;
}
.Coverage_tr__J2ozh td{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #6B7280;
    padding-left: 23px;
    padding-right: 9px;   
}

.Coverage_tr__J2ozh {
    height: 77px !important;
    border-bottom: 1px solid #b3b7b4;

}
.Coverage_tr__J2ozh td{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #6B7280;
}`, "",{"version":3,"sources":["webpack://./src/pages/Coverage/Coverage.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,2CAA2C;IAC3C,wCAAwC;IACxC,yBAAyB;IACzB,2BAA2B;IAC3B,0BAA0B;IAC1B,wBAAwB;IACxB,6BAA6B;IAC7B,6BAA6B;AACjC;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,gCAAgC;;AAEpC;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".header_container{\n    background-color: #3c8dbc;\n}\n.header_text{\n    margin-top: 5px;\n    font-weight: 700;\n    font-size: 20px;\n}\n\n.table {\n    width: 100% !important;\n}\n\n.thead  th {\n    border-bottom: 1px solid #EAECF0 !important;\n    border-top: 1px solid #EAECF0 !important;\n    color: #6B7280 !important;\n    font-weight: 500 !important;\n    font-size: 16px !important;\n    padding: 16px !important;\n    padding-left: 23px !important;\n    padding-right: 9px !important;\n}\n.tr td{\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 150%;\n    color: #6B7280;\n    padding-left: 23px;\n    padding-right: 9px;   \n}\n\n.tr {\n    height: 77px !important;\n    border-bottom: 1px solid #b3b7b4;\n\n}\n.tr td{\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 150%;\n    color: #6B7280;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header_container": `Coverage_header_container__K7MCN`,
	"header_text": `Coverage_header_text__LKmgq`,
	"table": `Coverage_table__sTp-l`,
	"thead": `Coverage_thead__5L46W`,
	"tr": `Coverage_tr__J2ozh`
};
export default ___CSS_LOADER_EXPORT___;
