import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import { useAuth } from './hooks/useAuth';

//
import UserPage from './pages/Users/UserPage';
import EpiPopulationPage from './pages/EpiPopulation/EpiPopulationPage';
import AddEpiPopulation from './pages/EpiPopulation/AddEpiPopulation';
import CoveragePage from './pages/Coverage/CoveragePage';
import StockoutPage from './pages/Stockout/StockoutPage';
import GeolocationPage from './pages/Geolocation/GeolocationPage';
import AddUsers from './pages/Users/AddUsers';
import AddGeolocation from './pages/Geolocation/AddGeolocation';
import AddFacility from './pages/Facility/AddFacility';
import RolesPage from './pages/Roles/RolesPage';
import AddRoles from './pages/Roles/AddRoles'; 
import EditRoles from './pages/Roles/EditRole'; 
import EditUsers from './pages/Users/EditUsers';
import EditFacilty from './pages/Facility/EditFacilty';
import EditGeolocation from './pages/Geolocation/EditGeolocation';
import FacilityPage from './pages/Facility/FacilityPage';
import LoginPage from './pages/Auth/LoginPage';
import VerificationPage from './pages/Auth/VerificationPage';
import PasswordChanged from './pages/Auth/PasswordChanged';
import ResetPassword from './pages/Auth/ResetPassword';
import ForgotPassword from './pages/Auth/ForgotPassword';
import RegisterPage from './pages/Auth/RegisterPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import withAuth from './auth.hoc.js';
import Profile from './pages/Profile/Index';



// ----------------------------------------------------------------------

export default function Router() {
  const { data } = useAuth();
  // console.log(data);
  let isLoggedIn = Object.keys(data || {}).length;
  //  Object.keys(data).length;
  const openRoutes = ['/login', '/forgot_password', '/verify_email', '/password_changed','/reset_password','/verification'];


  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/login" />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        
        { element: <Navigate to="/login" />, index: true },
        { path: 'app', element: isLoggedIn ? withAuth(DashboardAppPage, openRoutes) : <Navigate to="/login"/>  },
        { path: 'user', element: isLoggedIn ? withAuth(UserPage, openRoutes) : <Navigate to="/login"/> },
        { path: 'coverage', element: isLoggedIn ? withAuth(CoveragePage, openRoutes) : <Navigate to="/login"/> },
        { path: 'epi_population', element: isLoggedIn ? withAuth(EpiPopulationPage, openRoutes) : <Navigate to="/login"/> },
        { path: 'stockout', element: isLoggedIn ? withAuth(StockoutPage, openRoutes) : <Navigate to="/login"/> },
        { path: 'add_users', element: isLoggedIn ? withAuth(AddUsers, openRoutes) : <Navigate to="/login"/> },
        { path: 'add_epi_population', element: isLoggedIn ? withAuth(AddEpiPopulation, openRoutes) : <Navigate to="/login"/> },
        { path: 'add_facility', element: isLoggedIn ? withAuth(AddFacility, openRoutes) : <Navigate to="/login"/> },
        { path: 'edit_users', element: isLoggedIn ? withAuth(EditUsers, openRoutes) : <Navigate to="/login"/> },
        { path: 'facility', element: isLoggedIn ? withAuth(FacilityPage, openRoutes) : <Navigate to="/login"/> },
        { path: 'profile', element: isLoggedIn ? withAuth(Profile, openRoutes) : <Navigate to="/login" /> },
        { path: 'roles', element: isLoggedIn ? withAuth(RolesPage, openRoutes) : <Navigate to="/login"/> },
        { path: 'add_roles', element: isLoggedIn ? withAuth(AddRoles, openRoutes) : <Navigate to="/login"/> },
        { path: 'edit_roles', element: isLoggedIn ? withAuth(EditRoles, openRoutes) : <Navigate to="/login"/> },
        { path: 'add_geolocation', element: isLoggedIn ? withAuth(AddGeolocation, openRoutes) : <Navigate to="/login"/> },
        { path: 'edit_geolocation', element: isLoggedIn ? withAuth(EditGeolocation, openRoutes) : <Navigate to="/login"/> },
        { path: 'geolocations', element: isLoggedIn ? withAuth(GeolocationPage, openRoutes) : <Navigate to="/login"/> },
        { path: 'edit_facility', element: isLoggedIn ? withAuth(EditFacilty, openRoutes) : <Navigate to="/login"/> },
         
      ], 
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forgot_password',
      element: <ForgotPassword />,
    },
    {
      // path: 'reset_password/:token',
      // path: 'reset_password/:identity/:email',
      path: 'reset_password',
      element: <ResetPassword />,
    },{
      path: 'verification',
      element: <VerificationPage />,
    },
    {
      path: 'password_changed',
      element: <PasswordChanged />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
