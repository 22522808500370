// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import '../../../layouts/dashboard/header/sty.css';
import CircularProgress from '@mui/material/CircularProgress';
import {useState, useEffect} from 'react';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

InnerAppAnalytics.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  title1: PropTypes.string,
  title2: PropTypes.string,
  total: PropTypes.number,
  total1: PropTypes.number,
  total2: PropTypes.number,
  sx: PropTypes.object,
};

export default function InnerAppAnalytics({ title1, total1, title2, total2,title, total, icon, color = 'primary', sx, ...other }) {
  const [isPending, setIsPending] = useState(false);
  useEffect(() => {
    if (total=== '') {
      setIsPending(true)
    }else{
      setIsPending(false);

    }
  }, [total]);
  
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <div className="row">
        <div className="col-4">
          <img src="/assets/icons/users_icon.png" alt="" />
        </div>
        <div className="col-8" style={{borderLeft:'2px solid white'}}>
          <Typography variant="h3">
          {isPending && <CircularProgress style={{ color: '#3c8dbc' }} size="1rem" />}
          {!isPending && <span> {total ? total.toLocaleString() : 0}</span>}
          {/* {!isPending && <span> {total.toLocaleString()}</span>} */}
          </Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            <span className='title'>
              {title}
            </span>
          </Typography>
        </div>
      </div> 
    </Card>
  );
}
