import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Select from '../../components/inputs/Select';
import styles from "./Geolocation.module.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '../../components/inputs/Textbox';
import Axios from "../../axiosInstance";

import {
    Card,
    Container,
  } from '@mui/material';

export default function UserPage() {  
    const [modalOpen, setFacilityModalOpen] = useState(false);

    const navigate = useNavigate();
    const handleModalClose = () => {
      setFacilityModalOpen(false);
    };

    const [code, setcode] = useState('');
    const [locationName, setlocationName] = useState('');

    const [level, setLevel] = useState('');
    const [levelList, setlevelList] = useState([]);
    const [state, setState] = useState('');
    const [lga, setLG] = useState('');
    const [stateList, setStateList] = useState([]);
    const [lgaList, setLgaList] = useState([]);
    const [showState, setShowState] = useState(false);
    const [showLga, setShowLga] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [loading, setLoading] = useState(false);
    const [national, setNational] = useState('');
    const [nationalList, setNationalList] = useState([]);
    const [showNational, setShowNational] = useState(false);


    const handleNationalChange = (value) => {
      setNational(value)
    }

    const getNational = async () => { 
      Axios.get(`api/geoLocations/level/1`)
      .then((response) => {
        setNationalList(response?.data?.content)
      }).catch((error) => {
        console.error(error);
      });
    };


    const collateTextValue = (event) => {
      const { name, value } = event;
      switch (name) {
        case "code":
          setcode(value);
        case "locationName":
          setlocationName(value);
        default:
          break;
      }
    };
    
    // const handleSubmit = (event) => {
    const handleSubmit = async (event) => {
      setIsPending(true);
      event.preventDefault();
      let parent = '';

      if (national === '' && state === '' && lga ==='') {
        parent = null;
      }else if (state !== '' && lga ===''){
        parent = state;
      }else if (state !== '' && lga !==''){
        parent = lga;
      }else if (national !== '') {
        parent = national;
      }
      
      const formData = {
        code,
        name:locationName,
        level:{
          id:level
        },
        parent:{
          id:parent
        }
        
      };

      Axios.post(`api/geoLocations`, formData)
      .then((response) => {
        setIsPending(false);
        notify_user("Geolocation added successfully", "success")
        goto("geolocations")
        setcode('');
        setlocationName('');
        setLevel('');
        setState('');
        setLG('');

      }).catch((error) => {
        setIsPending(false);
        notify_user("Geolocation was not added", "error")
        console.error(error);
      });
      handleModalClose()
    };

    const getGeoLevels = async () => {
      setLoading(true)
      Axios.get(`api/geoLevels`)
      .then((response) => {
        setlevelList(response?.data?.content);
        setLoading(false)
      }).catch((error) => {
        console.error(error);
        setLoading(false);
      });
    };
    useEffect(() => {
      getGeoLevels();
      getNational()
    }, []); 

    const notify_user = (message, toast_type) => {
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }else if (toast_type == 'info'){
        toast.info(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#97ddef',
            color:"#000000"
          },
        })
      }
      
    };
    const goto = (d) => {
      navigate('/dashboard/'+d)
    };

    const handleStateChange = (value) => {
        setState(value)
      }
      useEffect(() => {
        if (level !== '') {
          var desiredCode = null;

          for (var i = 0; i < levelList.length; i++) {
              if (levelList[i].id === level) {
                  desiredCode = levelList[i].code;
                  break;
              }
          }
          if (desiredCode.toLowerCase() === "lga") {
            setShowState(true);
            setShowLga(false);
            setShowNational(false);
            setLG('');
            setNational('');

          }else if (desiredCode.toLowerCase() === "ward") {
            setShowState(true);
            setShowLga(true);
            setShowNational(false);
            setNational('');

          }else if (desiredCode?.toLowerCase() === "state") {
            setShowState(false);
            setShowLga(false);
            setShowNational(true);
          }else{
            setShowState(false);
            setShowLga(false);
            setShowNational(false);
            setNational('');
          }

        }
        
      }, [level]); 

    const handlelevelChange = (value) => {
        setLevel(value);
    }

    const handleLgaChange = (lgaValue) => {
      setLG(lgaValue)
    }

    const getAllStates = async () => { 
      setLoading(true);
      Axios.get(`api/geoLocations/level/3?size=50`)
      .then((response) => {
        setStateList(response?.data?.content)
        setLoading(false)
      }).catch((error) => {
        console.error(error);
        setLoading(false)
      });
    };

    const getAllLgas = async () => { 
      setLoading(true)
      Axios.get(`api/geoLocations/${state}/children?size=500`)
      .then((response) => {
        setLgaList(response?.data?.content)
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        console.error(error);
      });
    };

    useEffect(() => {
      if (state !== '') {
        getAllLgas()
      }
    }, [state]); 
  
    useEffect(() => {
      getAllStates();
    }, []); 
  
    return (
      <>
        <Helmet>
          <title> Add Location | VTCD </title>
        </Helmet>
        
  
        <Container>
        <ToastContainer />

        <div className={`${styles.header_container} p-4 d-flex mb-3`} >
                <span className={`${styles.header_text}`}> Add Location </span>
            </div>
          <Card className='p-4 light_card_background'>
          <form action="" className="mt-0" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-4">
                    <TextField 
                        name="code"
                        title="Location Code" 
                        value={code}
                        required
                        onValueChange={collateTextValue} 
                    />
                </div>
                <div className="col-md-6 mb-1">
                    <TextField 
                        name="locationName"
                        value={locationName}
                        title="LocationName" 
                        required
                        onValueChange={collateTextValue} 
                    />
                </div>
                
                <div className="col-md-6 mb-4">
                     <Select
                        name="level"
                        title="Level"
                        value={level}
                        optionList={levelList}
                        onSelect={handlelevelChange}
                        required={true}
                      />
                </div>
                {
                  showState && <div>
                    <p className=''>Select parent</p>
                    <div className="col-md-6 mb-4">
                        <Select
                            name="state"
                            title="State"
                            value={state}
                            optionList={stateList}
                            onSelect={handleStateChange}
                        />
                    </div>
                  </div>
                }
                {
                  showNational && <div>
                    <p className=''>Select parent</p>
                    <div className="col-md-6 mb-4">
                        <Select
                            name="national"
                            title="National"
                            value={national}
                            optionList={nationalList}
                            onSelect={handleNationalChange}
                        />
                    </div>
                  </div>
                }
                {
                  showLga && 
                  <div className="col-md-6 mb-4">
                    <Select
                        name="lga"
                        title="Lga"
                        value={lga}
                        optionList={lgaList}
                        onSelect={handleLgaChange}
                    />
                </div>
                }
                
              </div> 
              <div className="mb-4 mt-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button type="button" className={`btn btn-block buttons_outline`} onClick={()=>goto("geolocations")}>
                      Back
                  </button>
                  <button type="submit" className={`btn btn-block buttons`}>
                      Add &nbsp; &nbsp;
                      {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                  </button>
              </div>
            </form>
            {loading &&
              <div className='dashboard_loader'>
                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
              </div>
            }
          </Card>
        </Container>

      </>
    );
  }