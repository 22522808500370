import axios from 'axios';

const Axios = axios.create({
  baseURL:  process.env.REACT_APP_BASE_URL,
});

Axios.interceptors.request.use(
  async (config) => {
      let auth = localStorage.getItem('auth');
      auth = JSON.parse(auth);
      let accessExpiry = auth.accessExpiry
      let refreshExpiry = auth.refreshExpiry
      // console.log("refreshExpiry",refreshExpiry);
      let accessToken = localStorage.getItem('token');
      let expiryTime = localStorage.getItem('expiryTime');
          expiryTime = new Date(JSON.parse(expiryTime))

      const now = new Date(); 
      // if(expiryTime ){  

      //   if((now >= expiryTime) && (accessExpiry < refreshExpiry)){
      //        try {
      //          let refreshToken = localStorage.getItem('refreshToken'); 
                        
      //               const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/refresh`, {
      //                 refreshToken,
      //                 "clientSecret": "$2a$10$v5kda8L6jlnkknLfyBF/2erePIuofC0vJoyraDaUl69nID89qAEu6",
      //                 "clientId": "trackpay-front-bY928",
      //               }, {
      //                 headers: {
      //                   "Authorization":`Bearer ${accessToken}`,
      //                   "Content-Type": 'application/json',
      //                 }
      //               });

      //               localStorage.removeItem('token');
      //               localStorage.removeItem('refreshToken');
      //               localStorage.removeItem('expiryTime');

                  
      //               const currentTime = new Date();
      //               const millisecondsToAdd = accessExpiry - 180000;
      //               const newExpiryTime = new Date(currentTime.getTime() + millisecondsToAdd); 

      //               localStorage.setItem('expiryTime', JSON.stringify(newExpiryTime));
      //               localStorage.setItem('token', response.data.accessToken);
      //               localStorage.setItem('refreshToken', response.data.refreshToken); 

      //               accessToken = localStorage.getItem('token');
      //          } catch (error) {
      //               console.log(error);
      //          }
               
      //      }
      // }

      if(typeof accessToken !== 'undefined') {
          config.headers['Content-Type'] = 'multipart/form-data';
          config.headers['Authorization'] = `Bearer ${accessToken}`;  
      }
      
      return config;
  },
  error => {
      return Promise.reject(error);
  } 
);

/**
 * 
 * @param {*} response API
 * @returns {object} response
 */
const successHandler = response => {
  return response;
};

/**
* 
* @param {*} error API
* @returns {object} response
*/
const errorHandler = error => {

  const statusCode = error.response.status;
  if (statusCode === 401) {
    localStorage.clear();
    window.location.href = '/login'
  }
  return Promise.reject(error);
};

Axios.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);

export default Axios;


















