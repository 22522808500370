import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Select from '../../components/inputs/Select';
import styles from "./Geolocation.module.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import { permissions, hasPermission } from '../../Authorization';
import Axios from "../../axiosInstance";
import TextField from '../../components/inputs/Textbox';
import AuditLog from '../Common/AuditLog'
import {
    Card,
    Container,
  } from '@mui/material';

export default function UserPage() {  
  const navigate = useNavigate(); 
  const [code, setcode] = useState('');
  const [locationName, setlocationName] = useState('');
  const [levelList, setlevelList] = useState([]);
  const [state, setState] = useState('');
  const [national, setNational] = useState('');
  const [lga, setLG] = useState('');
  const [loading, setLoading] = useState(true);
  const [level, setLevel] = useState('');
  const [locationId, setLocationId] = useState('');
  const [lgaList, setLgaList] = useState([]);
  const [showState, setShowState] = useState(false);
  const [showNational, setShowNational] = useState(false);
  const [showLga, setShowLga] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nationalList, setNationalList] = useState([]);
  const [sytemInformation, setSystemInformation] = useState([]);

  const handlelevelChange = (value) => {
      setLevel(value)
  }
  const handleStateChange = (value) => {
    setState(value)
  }
  const handleNationalChange = (value) => {
    setNational(value)
  }

    const collateTextValue = (event) => {
      const { name, value } = event;
      if (name === "locationName") {
        setlocationName(value);
      }else if (name === "code") {
        setcode(value);
      }
    };

    const getNational = async () => { 
      Axios.get(`api/geoLocations/level/1`)
      .then((response) => {
        setNationalList(response?.data?.content)
      }).catch((error) => {
        console.error(error);
      });
    };
    
   
    const handleSubmit = async (event) => {
      setIsPending(true)
      event.preventDefault();
      let parent = '';

      if (national === '' && state === '' && lga ==='') {
        parent = null;
      }else if (state !== '' && lga ===''){
        parent = state; 
      }else if (state !== '' && lga !==''){
        parent = lga; 
      }else if (national !== '') {
        parent = national;
      }
      const formData = {
        code,
        name:locationName,
        level:{
          id:level
        },
        parent:{
          id:parent
        }
      }
      Axios.put(`api/geoLocations/${locationId}`, formData)
      .then((response) => {
        setIsPending(false)
        notify_user("Geolocation edited successfully", "success")
        setcode('');
        setlocationName('');
        setLevel('');
        setState('');
        setLG();
        goto("geolocations")

      }).catch((error) => {
        setIsPending(false)
        notify_user(error, "error")
        console.error(error);
      });
    };

    const getGeoLevels = async () => {
      setIsLoading(true);
      Axios.get(`api/geoLevels`)
      .then((response) => {
        setlevelList(response?.data?.content)
        setIsLoading(false);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    };

    const getAllStates = async () => { 
      setIsLoading(true);
      Axios.get(`api/geoLocations/level/3?size=50`)
      .then((response) => {
        setStateList(response?.data?.content)
        setIsLoading(false);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    };

    const getAllLgas = async () => { 
      setIsLoading(true);
      Axios.get(`api/geoLocations/${state}/children?size=500`)
      .then((response) => {
        setLgaList(response?.data?.content);
        setIsLoading(false);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    };

    useEffect(() => {
      getAllStates();
      getNational()
    }, []); 

    useEffect(() => {
      if (level !== '') {
        var desiredCode = null;

        for (var i = 0; i < levelList.length; i++) {
            if (levelList[i].id === level) {
                desiredCode = levelList[i].code;
                break;
            }
        }
        if (desiredCode?.toLowerCase() === "lga") {
          setShowState(true);
          setShowLga(false);
          setShowNational(false);

        }else if (desiredCode?.toLowerCase() === "ward") {
          setShowState(true);
          setShowLga(true);
          setShowNational(false);
        }else if (desiredCode?.toLowerCase() === "state") {
          setShowState(false);
          setShowLga(false);
          setShowNational(true);
        }else{
          setShowState(false);
          setShowLga(false);
          setShowNational(false);
        }

      }
      
    }, [level]); 

    useEffect(() => {
      if (state !== '') {
        getAllLgas()
      }
    }, [state, lga]); 


    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);
      const id = urlParams.get('id');
      getSingleLocationDetaiuls(id)
      getGeoLevels()
    }, [location]);


    const getSingleLocationDetaiuls = async (id) => { 
      Axios.get(`api/geoLocations/${id}`)
      .then((response) => {
        setSystemInformation(response?.data)
        setLocationId(response?.data?.id)
        setcode(response?.data?.code)
        setlocationName(response?.data?.name)
        setLevel(response?.data?.level?.id)
        if (response?.data?.parent?.level?.code.toLowerCase() === "lga" ) {
          setState(response?.data?.parent?.parent?.id) //Added to fix issue raised by Mr Leke 30/12/2023
          setLG(response?.data?.parent?.id);
        }else if (response?.data?.parent?.level?.code.toLowerCase() === "state" ) {
          setState(response?.data?.parent?.id);
        }else if (response?.data?.parent?.level?.code.toLowerCase() === "national" ) {
          setNational(response?.data?.parent?.id);

        }
        setLoading(false);
      }).catch((error) => {
        console.error(error);
        setLoading(false);

      });
    };

    const notify_user = (message, toast_type) => {
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }else if (toast_type == 'info'){
        toast.info(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#97ddef',
            color:"#000000"
          },
        })
      }
      
    };
    const goto = (d) => {
      navigate('/dashboard/'+d)
    };

    const handleLgaChange = (lgaValue) => {
      setLG(lgaValue)
    }


    if (loading) {
      return <div>Loading...</div>;
    }
  
    return (
      <>
        <Helmet>
          <title> Edit Location | VTCD </title>
        </Helmet>
        
  
        <Container>
        <ToastContainer />
        <div className={`${styles.header_container} p-4 d-flex mb-3`} >
                <span className={`${styles.header_text}`}> Edit Location </span>
            </div>
          <Card className='p-4 light_card_background'>
          <form action="" className="mt-0" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-4">
                    <TextField 
                        name="code"
                        title="Location Code" 
                        value={code}
                        required
                        onValueChange={collateTextValue} 
                    />
                </div>
                <div className="col-md-6 mb-1">
                    <TextField 
                        name="locationName"
                        value={locationName}
                        title="Location Name" 
                        required
                        onValueChange={collateTextValue} 
                    />
                </div>
              </div>
              
              <div className="row">
                <div className="col-md-6 mb-4">
                     <Select
                        name="level"
                        title="Level"
                        value={level}
                        optionList={levelList}
                        onSelect={handlelevelChange}
                        required={true}
                      />
                </div>
                {
                  showState && <div>
                    <p className=''>Select parent</p>
                    <div className="col-md-6 mb-4">
                        <Select
                            name="state"
                            title="State"
                            value={state}
                            optionList={stateList}
                            onSelect={handleStateChange}
                        />
                    </div>
                  </div>
                }
                {
                  showNational && <div>
                    <p className=''>Select parent</p>
                    <div className="col-md-6 mb-4">
                        <Select
                            name="national"
                            title="National"
                            value={national}
                            optionList={nationalList}
                            onSelect={handleNationalChange}
                        />
                    </div>
                  </div>
                }
                {
                  showLga && 
                  <div className="col-md-6 mb-4">
                      <Select
                          name="lga"
                          title="Lga"
                          value={lga}
                          optionList={lgaList}
                          onSelect={handleLgaChange}
                      />
                  </div>
                }
              
              </div> 
              <div className="mb-4 mt-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button type="button" className={`btn btn-block buttons_outline`} onClick={()=>goto("geolocations")}>
                      Back
                  </button>
                  {hasPermission("EDIT_GEOLOCATION") &&
                    <button type="submit" className={`btn btn-block buttons`}>
                        Update &nbsp; &nbsp;
                        {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                    </button>
                  }
              </div>
            </form>
            {isLoading &&
              <div className='dashboard_loader'>
                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
              </div>
            }
          </Card>
          <AuditLog prop={sytemInformation}/>

        </Container>

      </>
    );
  }