import { Helmet } from 'react-helmet-async';
import styles from "./Profile.module.css";
import { useState, useEffect, useContext } from 'react';
import { useAuth } from "src/hooks/useAuth";
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import emitter from '../../emitter/eventEmitter';


import {
    Card,Typography
  } from '@mui/material';
  import Select from '../../components/inputs/Select';
  import TextField from '../../components/inputs/Textbox';
  import Axios from "../../axiosInstance";

export default function ProfilePage() {
    const { data } = useAuth();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [userType, setUserType] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [role, setRole] = useState('');
    const [geoZone, setGeoZone] = useState('');
    const [address, setAddress] = useState('');
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState('');
    const [location, setLocation] = useState('');
    const [lga, setLG] = useState('');
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [userTypeList, setUserTypeList] = useState([]);
    const [isPending, setIsPending] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [roleName, setRoleName] = useState([]);
    const handleInputChange = (event, newInputValue) => {
      setInputValue(newInputValue);
    }; 

  
    const collateTextValue = (event) => {
      const { name, value } = event;
      if (name === "firstName") 
        setFirstName(value);
      if (name === "lastName") 
        setLastName(value);
      if (name === "email") 
        setEmail(value);
      if (name === "phoneNumber") 
      setPhoneNumber(value);
    };
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      setIsPending(true)
      const formData = {
        first_name:firstName,
        active:true,
        last_name:lastName,
        user_type_id:userType,
        role_id:role,
        email,
        phone_no:phoneNumber 
        // location: {
        //   id: location,
        // }
      };
      // return console.log(formData);
      Axios.put(`api/user/${data.user.id}`, formData)
      .then((response) => {
        notify_user("Update successful", "success")
        setIsPending(false);
        // Emit the event when the profile is updated
        emitter.emit('profileUpdated');
      }).catch((error) => {
        setIsPending(false);
        notify_user(error.message, "error")
        console.error(error);
      });
    };
 
    const handleUserTypeChange = (value) => {
        setUserType(value)
    }

    const notify_user = (message, toast_type) => {
        if (toast_type == 'success') {
          toast.success(message, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: '#d0efd0',
              color:"#000000"
            },
          })
        }else if(toast_type == 'error'){
          toast.error(message, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: '#f2c4c4',
              color:"#000000"
            },
          })
        }else if (toast_type == 'info'){
          toast.info(message, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: '#97ddef',
              color:"#000000"
            },
          })
        }
        
      };
      
    const getUserDetails = async () => {
      Axios.get(`api/user/${data.user.id}`)
      .then((response) => { 
        setFirstName(response?.data?.data?.first_name);
        setLastName(response?.data?.data?.last_name);
        setEmail(response?.data?.data?.email);
        setRoleName(response?.data?.data?.role?.name);
        // setLocation(response?.data?.data?.location?.id)
        setPhoneNumber(response?.data?.data?.profile?.phone_no);
        setRole(response?.data?.data?.role?.id)
        setUserType(response?.data?.data?.user_type?.id)
        // setState(response?.data?.data?.location?.id)
        setLoading(false);
      }).catch((error) => {
        console.error(error);
        setLoading(false);
        const statusCode = error?.response?.status;
        if (statusCode === 401) {
          returnToLogin()
        }
      });
    };

    const returnToLogin = () => {
      localStorage.clear();
      window.location.href = '/login'
    }

    // const getAllStates = async () => { 
    //   Axios.get(`api/geoLocations/level/3?size=50`)
    //   .then((response) => {
    //     setStateList(response?.data?.content)
    //   }).catch((error) => {
    //     console.error(error);
    //     const statusCode = error?.response?.status;
    //     if (statusCode === 401) {
    //       returnToLogin()
    //     }
    //   });
    // };

    useEffect(() => {
      getUserDetails();
      getAllUserTypes();
      // getAllStates();
    }, []);

    const handleStateChange = (value) => {
      setState(value);
    };


    const getAllUserTypes = async () => {
      Axios.get(`api/userTypes`)
      .then((response) => {
        setUserTypeList(response?.data?.data)
      }).catch((error) => {
        console.error(error);
      });
    };

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
        <>
            <Helmet>
            <title> Edit Profile </title>
            </Helmet>
            <section>
                <ToastContainer />
                <Card className={`${styles.profile_edit_header}`}>

                </Card>
                <div className='profile_picture'>
                  <img src="/assets/icons/profile_place_holder.png" alt="pics" />
                </div>
                <div className={`${styles.profile_edit_body_container}`}>
                    <Card className={`${styles.profile_edit_body} p-5`}>
                        <Typography variant="subtitle3" noWrap>
                            <h3 className={`${styles.edit_text}`}>
                                Edit basic info
                            </h3>
                            <p>
                                <small>
                                    Update your picture and personal details here
                                </small>
                            </p>
                        </Typography>
                        
                        <section>
                            <form action="" className="mt-0" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6 mb-4">
                                        <TextField 
                                            name="firstName"
                                            title="First Name" 
                                            value={firstName}
                                            required
                                            onValueChange={collateTextValue} 
                                        />
                                    </div>
                                    <div className="col-md-6 mb-1">
                                        <TextField 
                                            name="lastName"
                                            value={lastName}
                                            title="Last Name" 
                                            required
                                            onValueChange={collateTextValue} 
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6 mb-4">
                                        <TextField 
                                            name="email"
                                            title="Email" 
                                            value={email}
                                            type="email"
                                            onValueChange={collateTextValue} 
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-1">
                                        <TextField 
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            title="Phone Number"
                                            type="tel"
                                            onValueChange={collateTextValue} 
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6 mb-1">
                                        <Select
                                            name="userType"
                                            title="User Type"
                                            value={userType}
                                            optionList={userTypeList}
                                            onSelect={handleUserTypeChange}
                                            disabled = {true}
                                        />
                                    </div> 
                                </div>
                                <div className={`${styles.save_container}`}>
                                    <div className="mb-4">
                                        <button type="submit" className={`btn btn-block ${styles.save_btn}`}>
                                            Save Changes
                                            &nbsp; &nbsp;
                                            {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </section>
                    </Card>
                </div>
            </section>
        </>
    );
}
