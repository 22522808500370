import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';

const WithAuth = (WrappedComponent, protectedRoutes) => {
    const {data} =useAuth();
    const location = useLocation();
      return <WrappedComponent />;
}

export default WithAuth;