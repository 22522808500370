import { useRef, useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

HC_exporting(Highcharts);

const StackedColumnChart = (props) => {
  const { data, title } = props;

  const formatNumberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const customColors = ['#1EB53A','#3c8dbc'];
  
  const options = {
    chart: {
      type: 'column',
      height: 550
    },
    title: {
      text: title,
      style: {
        fontSize: '15px',           
        fontWeight: 'bold',
        color: '#333',
        textAlign: 'center',
        margin: '20px 0',
        fontFamily: 'josefin-sans-header, sans-serif, Arial',
      },
    },
    xAxis: {
      categories: data.map(item => item.district),
      crosshair: true,
      gridLineWidth: 1,
      gridLineColor: '#e0e0e0', 
      gridLineDashStyle: 'Dot', 
      tickmarkPlacement: 'on',
      tickLength: 10,
      tickWidth: 1, 
      lineColor: '#000',
      lineWidth: 1
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: '#000'
        }
      }
    },
    credits: {
        enabled: false 
      },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true
      },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function() {
            return formatNumberWithCommas(this.y);
          }
        }
      }
    },
    series: [{
      name: 'Vaccinated',
      data: data.map(item => item.numerator)
    }, {
      name: 'Unvaccinated',
      data: data.map(item => item.denominator)
    }],
    colors: customColors,
    legend: {
      layout: 'horizontal',
      // symbolWidth: 20, 
      // symbolHeight: 20,
      align: 'center',
      verticalAlign: 'bottom',
      itemMarginTop: 10,
      itemMarginBottom: 10,
      borderWidth: 1
      // layout: 'vertical', 
      //   align: 'right',
      //   verticalAlign: 'top',
      //   y: 50,  
      //   itemMarginTop: 10,
      //   itemMarginBottom: 10,
      //   borderWidth: 1
    },

  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default StackedColumnChart;
