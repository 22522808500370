import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import styles  from './Auth.module.css';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';

// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';

// import { toast } from 'react-hot-toast';
import { Toaster, toast } from 'react-hot-toast'; 
import LogoHeader from './ExternalHeader';
import {useEffect } from "react"
import {
  Card
} from '@mui/material';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
export default function LoginPage() {

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.location.href = '/login'
  //   }, 3000);
  // }, []); 
  const navigate = useNavigate();

  const goto = () => {
    navigate('/login')
  };


  return (
    <>
      <Toaster />
      <Helmet>
        <title> Password Changed </title>
      </Helmet>

      
      <StyledRoot sx={{backgroundColor:"#c5dbe8"}}>
        <LogoHeader>
          <Logo
            sx={{
              position: 'fixed',
              top: { xs: 16, sm: 24, md: 40 },
              left: { xs: 16, sm: 24, md: 40 },
            }}
          />
        </LogoHeader>

        <Container maxWidth="sm">
          <StyledContent>
            <div className={`${styles.div_container}`}>
                <div className={`${styles.password_changed_success}`}>
                    <img style={{width:"100px"}} src="/assets/images/avatars/success.png" alt="success" />
                </div>
            </div>
            <p className={`${styles.password_changed} text-center mt-3`} >Password Changed</p>
            <a className='text-center' href="#" style={{color:"#3c8dbc"}} onClick={goto}>Go to Login</a>
            {/* <button className='btn btn-success' st  yle={{backgroundColor:"#3c8dbc"}} onClick={goto}>Go to Login</button> */}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
