/* eslint-disable no-unreachable */


// ----------------------------------------------------------------------
import toast from 'react-hot-toast';
import { BaseUrlContext } from '../../context/BaseUrlContext';

import styles from './sty.module.css'
import 'bootstrap/dist/css/bootstrap.css';

// import Logo from "../logo/appLogo"
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import { useContext, useEffect, useRef, useState } from "react"
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
// import { useNavigate } from "react-router-dom";


export default function RegisterPage() {
  const navigate = useNavigate();


  const [category, setCategory] = useState('brand');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setCPassword] = useState('');
  const [agree, setAgree] = useState(false);
  const [doesNotMath, setDoesNotMath] = useState(false);
  const [signUpError, setSignUpError] = useState(null);
  const [response, setResponse] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showText1, setShowText1] = useState(false);
  const baseUrl = useContext(BaseUrlContext);
  const [pageContent, setPageContent] = useState(" Lorem, ipsum dolor. <br /> Dignissimos quas doloribus <br />  consequatur numquam qui fuga!")
  const [pageDescription, setDescription] = useState("  Lorem ipsum dolor sit amet consectetur adipisicing elit. <br /> Lorem ipsum dolor sit amet consectetur adipisicing elit.  Ipsam sequi adipisci nulla aut. ")
  const [bg, setBg] = useState("/assets/happyMan.png");
  const prevCategoryRef = useRef('');



  const notify = () => toast.success('Registration successful .');
  const notifyError = () => toast.error("That didn't work please try again later .");

  useEffect(() => {
    if (category !== '' && category !== prevCategoryRef.current) {
      if (category === 'brand') {
        setPageContent("Lorem, ipsum dolor. <br /> Dignissimos quas doloribus <br />  consequatur numquam qui fuga!");
        setDescription("Lorem ipsum dolor sit amet consectetur adipisicing elit. <br /> Lorem ipsum dolor sit amet consectetur adipisicing elit.  Ipsam sequi adipisci nulla aut. ");
        setBg("/assets/happyMan.png");
      } else if (category === 'shop') {
        setPageContent(" Buy, sell and monitor <br />  your customers on a <br /> single platform");
        setDescription(" Make purchases and keep track of all your business transactions in one <br /> convenient place.");
        setBg("/assets/retailerBg.svg");
      }
      prevCategoryRef.current = category;
    }
  }, [category])

  const handleChange = (event) => {
    let value = event.target.value;
    setCategory(value);


  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleCPasswordChange = (event) => {
    setCPassword(event.target.value);

  };

  const handleChange2 = (e) => {
    setAgree(!agree)
  }

  const changeType = () => {
    setShowText(!showText)
  }
  const changeType1 = () => {
    setShowText1(!showText1)
  }






  function HandleFormSubmit(e) {
    e.preventDefault();

    if (confirm_password !== password) {
      setDoesNotMath(true);
      return;
    }


    if (password.length < 8) {
      setSignUpError('Password must be at least 8 characters long');
      return;

    }

    setSignUpError(null)
    let payload = {
      merchant_type: category, email, password, confirm_password

    };
    // return console.log(payload);
    const fetchData = async () => {
      try {
        setIsPending(true)
        // Make the API request
        const res = await axios({
          method: "POST",
          url: `${baseUrl}/merchant/create`,
          data: payload,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        localStorage.setItem('e', email)
        localStorage.setItem('mt', payload.merchant_type)

        setResponse(res.data.message);
        setPassword('');
        setCPassword('');
        setEmail('');
        setCategory('manufacturer');
        notify()

// return console.log("signed ip");
        // setTimeout(() => {
        //   navigate('/verify_email');
        // }, 2000);
      } catch (err) {
        setSignUpError(err.response.data.message);
        notifyError()
      } finally {
        setIsPending(false)


      }
    };
    fetchData(true);








  }


  return (
    <>

      <div className={`row ${styles.sav}`}>


        <div className="col-md-6 d-none d-md-block">
          <div className={styles.bg} style={{ backgroundImage: ` url(https://images.pexels.com/photos/5938644/pexels-photo-5938644.jpeg?auto=compress&cs=tinysrgb&w=600)` }}>
            {/* <img className={styles.star} src='/assets/Stars.svg' alt='stars' /> */}
            <div className={styles.content} styles={{zIndex:'30'}}>
              <h5 className={`${styles.header_text}`} dangerouslySetInnerHTML={{ __html: pageContent }}>


              </h5>
              <p dangerouslySetInnerHTML={{ __html: pageDescription }}>

              </p>
              <div className={styles.icon_n_text}>
                {/* <img className={styles.icons} src='/assets/Avatars.svg' alt='avatars' /> */}
                <span style={{ color: '#EAECF0' }}>
                  Lorem ipsum dolor sit amet consectetur adipisicing.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 ">
          <div className={`${styles.sign_up} container`}>


            <form onSubmit={HandleFormSubmit}>
              <h4 className='mb-4 mt-5' style={{
                fontSize: '36px',
                fontWeight: '600',
                color: '#111827',


              }}>Sign up</h4>
              {(signUpError) && <Alert style={{
                border: '1px solid red'
              }} severity="error">{signUpError}</Alert>}
              {(response) && <Alert style={{
                border: '1px dotted gray'
              }} severity="success">{response}</Alert>}
              <div className='my-4 inputs_n_label'>
                <label htmlFor='email'>Email</label>
                <TextField
                  type='email'
                  value={email}
                  onChange={handleEmailChange}
                  id="email"
                  placeholder="Joe@shopriete.com"
                  size="small"
                  fullWidth

                />
              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='password'>Password</label>
                <TextField
                  type={showText ? 'text' : 'password'}
                  value={password}
                  onChange={handlePasswordChange}
                  id="password"
                  placeholder="Create a password"
                  size="small"
                  fullWidth

                />
                <div className="icon" onClick={changeType}>
                  {
                    !showText &&
                    <img src="/assets/icons/eye.svg" alt="" />

                  }
                  {
                    showText &&
                    <img src="/assets/icons/eye-off.svg" alt="" />


                  }

                </div>
                <small>Enter at least 8 characters</small>

              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='c_pass'>Confirm password</label>
                <TextField
                  type={showText1 ? 'text' : 'password'}
                  value={confirm_password}
                  onInput={handleCPasswordChange}
                  id="c_pass"
                  placeholder="Confirm password"
                  size="small"
                  fullWidth

                />
                <div className="icon mt-2" onClick={changeType1}>
                  {
                    !showText1 &&
                    <img src="/assets/icons/eye.svg" alt="" />

                  }
                  {
                    showText1 &&
                    <img src="/assets/icons/eye-off.svg" alt="" />


                  }

                </div>

                {doesNotMath && <small className='text-danger'>Password confirmation does not match</small>}

              </div>
              <div className="form-check-inline">

                <label htmlFor='agree' className='form-check-label'>
                  <input id='agree' value={agree} onChange={handleChange2} type="checkbox" className='form-check-input' /> &nbsp; I agree with the terms of the service and conditions
                </label>
              </div>
              <div className={styles.buttons}>
                {!isPending &&
                  <button disabled={!agree} className={`btn btn-primary ${styles.btn_custom_color}`}>
                    <span className={`${styles.span}`}>
                      Get started

                    </span>
                  </button>}
                {isPending && <button disabled className={`btn btn-primary ${styles.btn_custom_color}`}>
                  Signing you in &nbsp;
                  {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                </button>}
                {/* <button disabled={!agree} className={`${styles.btn_g} btn btn-block`}><img src="/assets/icons/Social icon.svg" alt="" /> Sign up  with Google</button> */}
                <p className='text-center'>
                  Already have an account? <RouterLink to='/login'>Sign in</RouterLink>
                </p>

              </div>

            </form>
          </div>
        </div>

      </div>
    </>
  );
}
