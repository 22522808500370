// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_profile_edit_header__7dlkU{
    height: 200px;
    background-color:#e1edf4;
    border-radius: 10px 10px 0px 0px !important;

}
.Profile_profile_edit_body__3Ln38{
    margin-top: 40px;
    background-color:#e1edf4;
}
.Profile_profile_edit_body_container__8Nnf-{
    border: 1px solid #d2d3d4;
}
.Profile_edit_text__UfNag{
    color: #3c8dbc;
}
.Profile_save_btn__5rTV7{
    margin-top: 100px;
    color: #ffffff;
    background-color: #3c8dbc;
}
.Profile_save_btn__5rTV7:hover{
    margin-top: 100px;
    color: #ffffff;
    background-color: #3c8dbc;
}
.Profile_cancel_btn__51MpK{
    background-color: transparent;
    content: #3c8dbc;
}
.Profile_save_container__FJO-I{
    display: flex;
    justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/pages/Profile/Profile.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wBAAwB;IACxB,2CAA2C;;AAE/C;AACA;IACI,gBAAgB;IAChB,wBAAwB;AAC5B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,cAAc;AAClB;AACA;IACI,iBAAiB;IACjB,cAAc;IACd,yBAAyB;AAC7B;AACA;IACI,iBAAiB;IACjB,cAAc;IACd,yBAAyB;AAC7B;AACA;IACI,6BAA6B;IAC7B,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".profile_edit_header{\n    height: 200px;\n    background-color:#e1edf4;\n    border-radius: 10px 10px 0px 0px !important;\n\n}\n.profile_edit_body{\n    margin-top: 40px;\n    background-color:#e1edf4;\n}\n.profile_edit_body_container{\n    border: 1px solid #d2d3d4;\n}\n.edit_text{\n    color: #3c8dbc;\n}\n.save_btn{\n    margin-top: 100px;\n    color: #ffffff;\n    background-color: #3c8dbc;\n}\n.save_btn:hover{\n    margin-top: 100px;\n    color: #ffffff;\n    background-color: #3c8dbc;\n}\n.cancel_btn{\n    background-color: transparent;\n    content: #3c8dbc;\n}\n.save_container{\n    display: flex;\n    justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile_edit_header": `Profile_profile_edit_header__7dlkU`,
	"profile_edit_body": `Profile_profile_edit_body__3Ln38`,
	"profile_edit_body_container": `Profile_profile_edit_body_container__8Nnf-`,
	"edit_text": `Profile_edit_text__UfNag`,
	"save_btn": `Profile_save_btn__5rTV7`,
	"cancel_btn": `Profile_cancel_btn__51MpK`,
	"save_container": `Profile_save_container__FJO-I`
};
export default ___CSS_LOADER_EXPORT___;
