import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/logo';
// sections
import { LoginForm } from '../../sections/auth/login';
import { useLocation } from 'react-router-dom';

// @mui
import { IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { login } from '../../actions/authActions';


// ----------------------------------------------------------------------

import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// import { toast } from 'react-hot-toast';
import { Toaster, toast } from 'react-hot-toast';

import { useContext, useState, useEffect } from "react"
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import axios from 'axios';
import { useAuth } from 'src/hooks/useAuth';
import LogoHeader from './ExternalHeader';
import Iconify from '../../components/iconify';
import Axios from "../../axiosInstance";
import {
  Card
} from '@mui/material';
import { useParams } from 'react-router-dom';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
export default function ResetPage() {
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');

  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const[displayError, SetDisplayError] = useState(false); 
  const [isPending,setIsPending] = useState(false);
  const baseUrl = useContext(BaseUrlContext);
  // const { token } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('identity');
  const email = queryParams.get('email');
  // const { email, userId } = useParams();
  const [tokenValidated, setTokenValidated] = useState(0)
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false); 
  const handleEmailChange = (event) => {
    setPassword(event.target.value);
  };


  // useEffect(() => {
  //   verifyTokenExpiry()
  // }, []);

  const notify_user = (message, toast_type) => {
    if (toast_type == 'success') {
      toast.success(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#d0efd0',
          color:"#000000"
        },
      })
    }else if(toast_type == 'error'){
      toast.error(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#f2c4c4',
          color:"#000000"
        },
      })
    }
    
  };

  const verifyTokenExpiry = async()=>{
    let payLoad = {
      resetToken:token
    }
    axios.post(`${baseUrl}/reset/validate-token`, payLoad)
      .then((response) => {
        setTokenValidated(2)
      }).catch((error) => {
        setTokenValidated(1)
        notify_user("Token has expired", "error")
        console.error(error);
      });
  }

  // const HandleFormSubmit = async (event) => {

  //   if (cpassword !== password) {
  //     SetDisplayError(true)
  //     return;
  //   }else{
  //     setIsPending(true);
  //     event.preventDefault(); 
  //       let payload = {
  //         email,
  //         password,
  //         confirmPassword:cpassword
  //       }
  //     axios.post(`${baseUrl}/auth/reset-password`, payload)
  //     .then((response) => {
  //       goto('password_changed')
  //     }).catch((error) => {
  //       if (typeof error.response.data.message === 'string') {
  //         notify_user(error.response.data.message, "error");
  //       } else {
  //           notify_user("Password not changed!", "error");
  //       }
  //       console.error(error);
  //     });
  //   }
  // };

  const HandleFormSubmit = async (event) => {
    event.preventDefault(); 
    if (cpassword !== password) {
        SetDisplayError(true);
        return;
    }

    setIsPending(true);
    let payload = {
        email,
        password,
        confirmPassword: cpassword
    };

    try {
        await axios.post(`${baseUrl}/auth/reset-password`, payload);
        goto('password_changed');
    } catch (error) {
        const message = error.response?.data?.message;
        if (typeof message === 'string') {
          notify_user(message, "error");
        } else if (typeof message === 'object' && message !== null) {
            const errorMessages = Object.values(message).join(', ');
            notify_user(errorMessages, "error");
        } else {
            notify_user("Password not changed!", "error");
        }
        console.error(error);
    } finally {
        setIsPending(false);
    }
};

  const goto = (d) => {
    navigate('/'+d)
  };

  

  const handleClick = () => {
    navigate('/login', { replace: true });
  };

 

  return (
    <>
      <Toaster />
      <Helmet>
        <title> Reset Passowrd  </title>
      </Helmet>

      
      <StyledRoot sx={{backgroundColor:"#c5dbe8"}}>
        <LogoHeader>
          <Logo
            sx={{
              position: 'fixed',
              top: { xs: 16, sm: 24, md: 40 },
              left: { xs: 16, sm: 24, md: 40 },
            }}
          />
        </LogoHeader>
        {/* {tokenValidated === 2 && */}
        <Container maxWidth="sm">
          <StyledContent>

            <Typography variant="h4" gutterBottom>
              <div className='text-center'>
                Update Password
              </div>
            </Typography>

            <p className='theme_link'
              onClick={()=>handleClick()}
            >
              Go to login
            </p>
            <Card sx={{p:4}}>
            {/* <Form /> */}
            <Stack spacing={3} className="mt-5"> 
              <TextField
                name="password"
                label="New Password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack spacing={3} className="mt-3">
              <TextField 
                name="cpassword" 
                type={showCPassword ? 'text' : 'password'}
                label="Confirm New Password" 
                value={cpassword}
                onChange={(e) => setCPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowCPassword(!showCPassword)} edge="end">
                        <Iconify icon={showCPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            {
              displayError &&  <div><p className='text-danger mt-3'>Your inputs did not match</p></div>
            }
           

            <LoadingButton className="mt-3 bg_green" size="large" type="submit" variant="contained" onClick={HandleFormSubmit}>
              <span>
                Send &nbsp;
              </span>
              {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
            </LoadingButton>
            </Card>
          </StyledContent>
        </Container>
        {/* } */}
        {/* {tokenValidated === 1 && <Container maxWidth="sm">
          <StyledContent>
            <div className="center-container">
              <div className="centered-div">
              <h3>Token has expired.</h3>
              <h4>Click on the button below to retry <br /> 👇👇</h4> 
              <button className="btn pl-3 pr-3 mt-3 btn-success bg_green " size="large" type="submit" variant="contained" onClick={()=>{goto('forgot_password')}}>
                <span>
                  Reset Password
                </span>
              </button>
              </div>
            </div>
            </StyledContent>
          </Container>} */}
      </StyledRoot>
    </>
  );
}
