import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { FormControl,FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';
import Scrollbar from '../components/scrollbar'; 

// import Select from '../components/inputs/Select';
import styles from "./Dashboard.module.css";
import MulipleSelect from '../components/inputs/MultipleSelect';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from "../axiosInstance"; 
import { getFilterYears } from '../CommonMethods';
import DashboardTableExportMenu from '../components/exports/DashboardTableExportMenu';
import CountryDitrictHeatMap from '../components/highcharts/CountryDistrictHeatMap';
// import Tables from '../components/highcharts/Tables';

// components
import Iconify from '../components/iconify';
// sections
import {
  AppWebsiteVisits,
  DashboardAnalytics
} from '../sections/@dashboard/app';
import {
  Card,
} from '@mui/material';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting'; 
// import HighchartsReact from 'highcharts-react-official';
import DonutChart from '../components/highcharts/DonutChart';
import FRRDistrictColumnChart from '../components/highcharts/FRRDistrictColumnChart';
// init the module
HC_exporting(Highcharts);

const TABLE_HEAD = [
  { id: 'sn', label: '#', alignRight: false },
  { id: 'Districts', label: 'Districts', alignRight: false },
  { id: 'outVaccinated', label: 'Vaccinated Out of School', alignRight: false },
  { id: 'inVaccinated', label: 'Vaccinated In School', alignRight: false },
  { id: 'out', label: '% Vaccinated Out of School', alignRight: false },
  { id: 'in', label: '% Vaccinated In School', alignRight: false },
  // { id: 'Unvaccinated', label: 'Unvaccinated', alignRight: false },
  // { id: 'Percentage', label: '% Unvaccinated', alignRight: false },
  { id: 'Total', label: 'Target', alignRight: false },
];

const monthList = [
  { 'id': '01', 'name': 'January' },
  { 'id': '02', 'name': 'February' },
  { 'id': '03', 'name': 'March' },
  { 'id': '04', 'name': 'April' },
  { 'id': '05', 'name': 'May' },
  { 'id': '06', 'name': 'June' },
  { 'id': '07', 'name': 'July' },
  { 'id': '08', 'name': 'August' },
  { 'id': '09', 'name': 'September' },
  { 'id': '10', 'name': 'October' },
  { 'id': '11', 'name': 'November' },
  { 'id': '12', 'name': 'December' }
];

// ----------------------------------------------------------------------
export default function DashboardAppPage() {
  let role = JSON.parse(localStorage.getItem('role'));
  let locationID = JSON.parse(localStorage.getItem('location'));
  const [showSearch, setShowSearch] = useState(false);
  const [filterYearList, setFilterYearList] = useState([]);
  const baseUrl = useContext(BaseUrlContext);
  const [yearlyInOutVaccinationPerDistrict, setYearlyInOutVaccinationPerDistrict] = useState([]);
  const [yearlyInVaccinationPerDistrict, setYearlyInVaccinationPerDistrict] = useState([]);
  const [yearlyOutVaccinationPerDistrict, setYearlyOutVaccinationPerDistrict] = useState([]);
  const [tabledataPerDistrict, setTableDataPerDistrict] = useState([]);
  const [filterMonth, setFilterMonth] = useState('');

  const [analytics, setAnalytics] = useState(
    {
      "out_school": '',
      "in_school": '',
      "total": '',
      'total_doses':''
    }
  );
  const [inOutSchoolVaccinationReadyData, setInOutSchoolVaccinationReadyData] = useState([{
    'y': 0,
    'color': '',
    'target':0
  }]);
  const [inSchoolVaccinationReadyData, setInSchoolVaccinationReadyData] = useState([{
    'y': 0,
    'color': '',
    'target':0
  }]);
  const [outSchoolVaccinationReadyData, setOutSchoolVaccinationReadyData] = useState([{
    'y': 0,
    'color': '',
    'target':0
  }]);
  const [categories, setCategories] = useState([
    'Vaccinated',
    'Unvaccinated'
  ]);
  const [inOutSchoolVaccinatedData, setInOutSchoolVaccinatedData] = useState(
    {
      "numerator": 0,
      "denominator": 0 
    }
  );
  const [inSchoolVaccinatedData, setInSchoolVaccinatedData] = useState(
    {
      "numerator": 0,
      "denominator": 0 
    }
  );
  const [outSchoolVaccinatedData, setOutSchoolVaccinatedData] = useState(
    {
      "numerator": 0,
      "denominator": 0 
    }
  );
  const currentYear = new Date().getFullYear();
  const [chart1Title, setChart1Title] = useState('');
  const [chart2Title, setChart2Title] = useState('');
  const [chart3Title, setChart3Title] = useState('');
  const [reportRatePerDistrict, setReportRatePerDistrict] = useState([]);
  const [dashboardTitle, setDashboardTitle] = useState('');
  const [filterYear, setFilterYear] = useState('All');
  const [changed, setChanged] = useState(''); 
  const [isPending, setIsPending] = useState(false);
  const [heatMapTitle, setHeatMapTitle] = useState('');
  const [yearMonth, setYearMonth] = useState('');

  // const [dashboardTitle, setDashboardTitle] = useEffect('');
  const [loading, setIsIsLoading] = useState(false);
  
  const handleYearFilterChange = (event) => { 
    let selectedValue = event.target.value;
    setFilterYear(selectedValue);
    setChanged(filterYear)
  }
  const handleMonthFilterChange = (event) => {
    let selectedValue = event.target.value;
    setFilterMonth(selectedValue);
  }

  // useEffect(() => { 
  //   getAllChartsForFilteredYear();
  // }, [changed]); 
  
  const theme = useTheme();

    useEffect(() => {
      getAllFilterYears()
      getDashboardAnalytics();
      getVaccinatedInOutSchoolsChartData();
      getVaccinatedInSchoolChartData();
      getVaccinatedOutSchoolChartData();
      getReportingRatePerDistrictChartData();
      getYearyInOutVaccinationPerDistrictChartData();
      getYearyInVaccinationPerDistrictChartData();
      getYearyOutVaccinationPerDistrictChartData();
      setHeatMapTitle(`Total number of girls in schools and out of schools vaccinated per district `) //(${filterYear == 'All' ? maxYear: filterYear})
      getTableNationalHPVDataPerDistrict()
    }, []); 

    const getReportingRatePerDistrictChartData = async () => {
      setIsIsLoading(true); 
      const formData = {
        year:filterYear,//'2023',
        month:filterMonth
      };
      Axios.post(`api/reporting-rate-district`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setReportRatePerDistrict(response?.data?.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };
    
    useEffect(() => {
      if(analytics?.year){
        const firstYear = analytics?.year[0];
        const lastYear = analytics?.year[analytics?.year.length - 1];
        const formattedPeriod = analytics?.year.length > 1 ? `${firstYear} - ${lastYear}` : analytics?.year;
        const foundMonth = monthList.find(month => month.id === filterMonth);
        const month = filterMonth == "" ? "" : ` ${foundMonth.name}`
        const titleMonth = filterMonth == "" ? "" : ` ${foundMonth.name},`
        setDashboardTitle(`General Overview for `+titleMonth+" "+formattedPeriod);
        setYearMonth(month+' '+formattedPeriod)
        // setChart1Title('Percentage of girls vaccinated with HPV vaccine, ' +analytics?.year+ ' national')
        setChart1Title('National HPV vaccination coverage')
        // setChart2Title('Percentage of girls vaccinated In school with HPV vaccine, ' +analytics?.year+ ' national')
        setChart2Title('National HPV vaccination coverage for school vaccination ')
        // setChart3Title('Percentage of girls vaccinated Out school with HPV vaccine, ' +analytics?.year+ ' national ')
        setChart3Title('National HPV vaccination coverage for out of school vaccination ')
        setFilterYear(analytics?.year);
        if(typeof analytics?.year == 'object' && Object.keys(analytics?.year).length > 1){
          setFilterYear('All');
        }
      }
    }, [analytics]); 

    useEffect(() => {
      let total = getTotal(inOutSchoolVaccinatedData);
      // let total = inOutSchoolVaccinatedData.denominator; 
      procesVaccinatedInOutSchools(total, inOutSchoolVaccinatedData)
      // console.log("total", total)
      // console.log("inOutSchoolVaccinatedData", inOutSchoolVaccinatedData)
    }, [inOutSchoolVaccinatedData]);

    useEffect(() => {
      let total = getTotal(inSchoolVaccinatedData);
      processVaccinatedInSchools(total, inSchoolVaccinatedData)
    }, [inSchoolVaccinatedData]);

    useEffect(() => {
      let total = getTotal(outSchoolVaccinatedData);
      processVaccinatedOutSchools(total, outSchoolVaccinatedData)
    }, [outSchoolVaccinatedData]);

    const procesVaccinatedInOutSchools = (total, inOutSchoolVaccinatedData) =>{
      const colors = Highcharts.getOptions().colors
  
      const numeratorKey = 'numerator';
      const denominatorKey = 'denominator';

      let chartData = Object.keys(inOutSchoolVaccinatedData).map((key, index) => { 
          return {
              y: (inOutSchoolVaccinatedData[numeratorKey]/inOutSchoolVaccinatedData[denominatorKey])*100,
              color: colors[index+3],  
              target: inOutSchoolVaccinatedData[denominatorKey],  
              vaccination: inOutSchoolVaccinatedData[numeratorKey]
          };
      });
    
    //  console.log(colors, chartData)
      setInOutSchoolVaccinationReadyData(chartData);
    }
    const processVaccinatedInSchools = (total, inSchoolVaccinatedData) =>{
      const colors = Highcharts.getOptions().colors
  
      const numeratorKey = 'numerator';
      const denominatorKey = 'denominator';

      let chartData = Object.keys(inSchoolVaccinatedData).map((key, index) => {
          return {
              y: (inSchoolVaccinatedData[numeratorKey]/inSchoolVaccinatedData[denominatorKey])*100,
              color: colors[index+3],  
              target: inSchoolVaccinatedData[denominatorKey],  
              vaccination: inSchoolVaccinatedData[numeratorKey]
          };
      });
     
      setInSchoolVaccinationReadyData(chartData);
    }
    const processVaccinatedOutSchools = (total, outSchoolVaccinatedData) =>{
      const colors = Highcharts.getOptions().colors
  
      const numeratorKey = 'numerator';
      const denominatorKey = 'denominator';

      let chartData = Object.keys(outSchoolVaccinatedData).map((key, index) => {
          return {
              y: (outSchoolVaccinatedData[numeratorKey]/outSchoolVaccinatedData[denominatorKey])*100,
              // y: getPercentage(outSchoolVaccinatedData[key], total),
              color: colors[index+3],  
              target: outSchoolVaccinatedData[denominatorKey],  
              vaccination: outSchoolVaccinatedData[numeratorKey]
          };
      });
     
      setOutSchoolVaccinationReadyData(chartData);
    }

    const getTotal = (array) => { 
      let total = array['denominator'] + array['numerator'];
      
      return total;
    }

    const getPercentage = (number, total) => {
      // console.log('number', number)
      // console.log('total', total)
      // console.log('percentage', (number / total) * 100)
      const percentage = (number / total) * 100;
    
      // const roundedPercentage = Math.ceil(percentage * 100) / 100;
      
      return percentage;
      
    };

    const notify_user = (message, toast_type) => {
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }else if (toast_type == 'info'){
        toast.info(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#97ddef',
            color:"#000000"
          },
        })
      }
      
    };

    const getYearyInOutVaccinationPerDistrictChartData = async () => {
      setIsIsLoading(true); 
      let location;
      // if(district != '' && council != ''){
      //   location = council;
      // }else if(district != '' && council == ''){
      //   location = district;
      // }else{
      //   location = '';
      // }
      const formData = {
        year:filterYear,
        month:filterMonth
        // location:location
      };
      Axios.post(`api/coverage/in-out-analysis-per-location`, formData)
      .then((response) => {
        setIsIsLoading(false);
        // console.log(response.data.data);
        setYearlyInOutVaccinationPerDistrict(response.data.data)
        // if(response?.data?.data){
        //   setFilterClicked(true);
        // }
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };

    const getYearyOutVaccinationPerDistrictChartData = async () => {
      setIsIsLoading(true); 
      let location;
      // if(district != '' && council != ''){
      //   location = council;
      // }else if(district != '' && council == ''){
      //   location = district;
      // }else{
      //   location = '';
      // }
      const formData = {
        year:filterYear,
        month:filterMonth
        // location:location
      };
      Axios.post(`api/coverage/out-school-analysis-per-location`, formData)
      .then((response) => {
        setIsIsLoading(false);
        // console.log(response.data.data);
        setYearlyOutVaccinationPerDistrict(response.data.data)
        // if(response?.data?.data){
        //   setFilterClicked(true);
        // }
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };

    const getYearyInVaccinationPerDistrictChartData = async () => {
      setIsIsLoading(true); 
      let location;
      // if(district != '' && council != ''){
      //   location = council;
      // }else if(district != '' && council == ''){
      //   location = district;
      // }else{
      //   location = '';
      // }
      const formData = {
        year:filterYear,
        month:filterMonth
        // location:location
      };
      Axios.post(`api/coverage/in-school-analysis-per-location`, formData)
      .then((response) => {
        setIsIsLoading(false);
        // console.log(response.data.data);
        setYearlyInVaccinationPerDistrict(response.data.data)
        // if(response?.data?.data){
        //   setFilterClicked(true);
        // }
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };

    const getTableNationalHPVDataPerDistrict = async () => {
      setIsIsLoading(true); 
      
      const formData = {
        year:filterYear,
        month:filterMonth
      };
      Axios.post(`api/coverage/separate-in-out-schools`, formData)
      .then((response) => {
        setIsIsLoading(false);
        let data = response?.data?.data.sort((a, b) => {
          if (a.district < b.district) {
              return -1;
          }
          if (a.district > b.district) {
              return 1;
          }
          return 0;
      });
        setTableDataPerDistrict(data)
        // if(response?.data?.data){
        //   setFilterClicked(true);
        // }
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };
    const getAllChartsForFilteredYear = () => {
      setIsPending(true);
      getDashboardAnalytics();
      getVaccinatedInOutSchoolsChartData();
      getVaccinatedInSchoolChartData();
      getVaccinatedOutSchoolChartData();
      getReportingRatePerDistrictChartData();
      setIsPending(false);
      getTableNationalHPVDataPerDistrict()
      getYearyInOutVaccinationPerDistrictChartData();
      getYearyInVaccinationPerDistrictChartData();
      getYearyOutVaccinationPerDistrictChartData();
    }

    const getDashboardAnalytics = async () => {
      setIsIsLoading(true);
      const formData = {
        year:filterYear,
        month:filterMonth
      };
      Axios.post(`api/dashboard-analytics`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setAnalytics(response.data.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        notify_user("Unable to get analytics", "error")
        const statusCode = error.response.status;
        if (statusCode === 401) {
          returnToLogin()
        }
      });
    };

    const getVaccinatedInOutSchoolsChartData = async () => {
      setIsIsLoading(true); 
      const formData = {
        year:filterYear,
        month:filterMonth
      };
      Axios.post(`api/in-out-school-vaccination`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setInOutSchoolVaccinatedData(response.data.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        notify_user("Unable to get data", "error");
      });
    };

    const getVaccinatedInSchoolChartData = async () => {
      setIsIsLoading(true); 
      const formData = {
        year:filterYear,
        month:filterMonth
      };
      Axios.post(`api/in-school-vaccination`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setInSchoolVaccinatedData(response.data.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        notify_user("Unable to get data", "error") 
      });
    };

    const getVaccinatedOutSchoolChartData = async () => {
      setIsIsLoading(true); 
      const formData = {
        year:filterYear,
        month:filterMonth
      };
      Axios.post(`api/out-school-vaccination`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setOutSchoolVaccinatedData(response.data.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        notify_user("Unable to get data", "error") 
      });
    };

    const getAllFilterYears = async () => {
      setIsIsLoading(true); 
      try {
        let years = await getFilterYears();
        setFilterYearList(years);
        setIsIsLoading(false);

      } catch (error) {
        console.error('Failed to fetch roles:', error);
        notify_user("Unable to get filter years", "error")
        setIsIsLoading(false);

      }
    }
    const returnToLogin = () => {
      localStorage.clear();
      window.location.href = '/login'
    }

  return (
    <>
    <ToastContainer />
      <Helmet>
        <title> Dashboard  </title>
      </Helmet>

          <div className="container mb-5">
            <div className="d-flex justify-content-end">
              <div className="mb-2 filter-select-right">  
                <FormControl fullWidth >
                  <InputLabel id="select-label">Filter by Year</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select-box"
                    value={filterYear }
                    label={"Filter by Year"}
                    onChange={handleYearFilterChange}
                    fullWidth
                    size="small" 
                  > 
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="All">All</MenuItem>
                    {filterYearList?.map((value) => (
                      <MenuItem key={value.id} value={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}  
                  </Select>
                </FormControl>
                
              </div>
              &nbsp;&nbsp;&nbsp;
              
              {/* <div className="col-md-3"> */}
              {(filterYear !== "All" && filterYear !== "") && (
                  <div className="mb-2 filter-select-right">  
                    <FormControl fullWidth >
                      <InputLabel id="select-label">Filter by Month</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select-box"
                        value={filterMonth }
                        label={"Filter by Month"}
                        onChange={handleMonthFilterChange}
                        fullWidth
                        size="small" 
                        // disabled={filterYear === "All"}
                      > 
                        <MenuItem value="">Select</MenuItem>
                        {/* <MenuItem value="All">All</MenuItem> */}
                        {monthList?.map((value) => (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}  
                      </Select>
                    </FormControl>
                  </div> 
              )}
              <div className="pt-2 pl-0">
                <button onClick={getAllChartsForFilteredYear} className={`btn btn-block buttons ml-0 mr-2`}>
                    Filter &nbsp; &nbsp;
                    {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                </button>
              </div>
            </div>
            
            {/* general_overview */}
            <Card className='p-1  bg_success no_raduis'>
              <h4 className='card_overview text-light'>
                {dashboardTitle}
              </h4> 
            </Card>

            <div className="row">
                <div className="col-md-6" style={{margin:"0px !important"}}>
                    <DashboardAnalytics className="bg-white text_success mt-3 no_raduis p-2 bg1" title="Total Girls Vaccinated In/Out Of School" total={analytics?.total ?? 0} icon="dashboard_icon_blue.png" bg_color="bg1" underline_title="underline_title"/>
                </div>
                <div className="col-md-6">
                    <DashboardAnalytics className="bg-white text_success mt-3 no_raduis p-2 bg2" title="Total Girls Vaccinated In School" total={analytics?.in_school ?? 0} bg_color="bg2" icon="icon_analytic.png" underline_title="underline_title"/>
                </div>
                <div className="col-md-6">
                  <DashboardAnalytics className="bg-white text_success mt-3 no_raduis p-2 bg3" total={analytics?.out_school ?? 0} title="Total Girls Vaccinated Out School" bg_color="bg2" icon="icon_analytic.png"  underline_title="underline_title"/>
                  {/* <DashboardAnalytics className="bg-white text_success mt-3 no_raduis p-2 bg3" total={analytics?.out_school ?? 0} title="Total Girls Vaccinated Out School" bg_color="bg3" icon="dashboard_icon_green.png"  underline_title="underline_title"/> */}
                </div>
                <div className="col-md-6">
                  {/* <DashboardAnalytics className="bg-white text_success mt-3 no_raduis p-2 bg4" total={analytics?.total_doses ?? 0} title="Total Doses Administered" bg_color="bg1" icon="icon_analytic.png"  underline_title="underline_title"/> */}
                  <DashboardAnalytics className="bg-white text_success mt-3 no_raduis p-2 bg4" total={analytics?.total_doses ?? 0} title="Total Doses Administered" bg_color="bg1" icon="dashboard_icon_blue.png"  underline_title="underline_title"/>
                </div>
                
            </div> 
           
          </div>
          {loading &&
              <div className='dashboard_loader'>
                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
              </div>
            }
          <div className="container">
            <div className="row">
              <div className="col-md-4 pt-2">
                <div className="card">
                  <DonutChart data={inOutSchoolVaccinationReadyData} categories={categories} title={chart1Title}/>
                </div>
              </div> 
              <div className="col-md-4 pt-2">
                <div className="card">
                  <DonutChart data={inSchoolVaccinationReadyData} categories={categories} title={chart2Title}/>
                </div>
              </div>
              <div className="col-md-4 pt-2 pr-2">
                  <div className="card">
                    <DonutChart data={outSchoolVaccinationReadyData} categories={categories} title={chart3Title}/>
                  </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-4 pt-2">
                <div className="card">
                  <div className="p-2 text-center fw-bold">
                    National HPV vaccination coverage  <br/><br/>
                  </div>
                    <CountryDitrictHeatMap data={yearlyInOutVaccinationPerDistrict} subTitle="Coverage In/Out of school per district"/>
                </div>
              </div>
              <div className="col-md-4 pt-2">
                <div className="card">
                  <div className="p-2 text-center fw-bold">National HPV vaccination coverage for school vaccination per district</div>
                    <CountryDitrictHeatMap data={yearlyInVaccinationPerDistrict} subTitle="Coverage In school per district"/>
                </div>
              </div>
              <div className="col-md-4 pt-2">
                <div className="card">
                  <div className="p-2 text-center fw-bold">
                    National HPV vaccination coverage for out of cchool Vaccination per district
                  </div>
                    <CountryDitrictHeatMap data={yearlyOutVaccinationPerDistrict} subTitle="Coverage Out of school per district"/>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
              <div className="card card-fixed-height p-3" style={{height: "594px", overflowY:"auto"}}>
                  <div className='row'>
                    <div className="col-10 p-3 text-center fw-bold">
                      National HPV Coverage In and Out Schools breakdown per district, {yearMonth}
                    </div>
                    <div className="col-2 text-end">
                      <DashboardTableExportMenu tabledataPerDistrict={tabledataPerDistrict} yearMonth={yearMonth} />
                    </div>
                  </div>
                  <Scrollbar>
                    <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}>
                        <table className={`${styles.table} table-hover table-striped`} id="tableToExport">
                            <thead className={`${styles.thead}`}>
                            <tr>
                                {TABLE_HEAD.map((el, i) => {
                                return (
                                    <th key={i} className="text-bold th">{el.label}</th>
                                )
                                })}
                            </tr>
                            </thead>
                            {tabledataPerDistrict.length === 0 ? (
                            <tbody >
                                <tr >
                                <td colSpan={8}>
                                    <p className="alert alert-info mt-3 w-100 text-center" role="alert">
                                      No data available.
                                    </p>
                                </td>
                                </tr>
                            </tbody>
                            ):(
                              <tbody>
                                {tabledataPerDistrict.map((el, i) => {
                                  const target = el?.target || 0;
                                  const outSchoolPercentage = target === 0 ? 0 : Math.round((el?.outSchool / target) * 100, 2);
                                  const inSchoolPercentage = target === 0 ? 0 : Math.round((el?.inSchool / target) * 100, 2);
                                  const remaining = target - (el?.outSchool + el?.inSchool);
                                  const remainingPercentage = target === 0 ? 0 : Math.round((remaining / target) * 100, 2);

                                  return (
                                    <tr key={i} style={{ cursor: 'pointer' }} className={`${styles.tr}`}>
                                      <td>{i + 1}</td>
                                      <td>{el?.district}</td>
                                      <td>{el?.outSchool.toLocaleString()}</td>
                                      <td>{el?.inSchool.toLocaleString()}</td>
                                      <td>{outSchoolPercentage}{"%"}</td>
                                      <td>{inSchoolPercentage}{"%"}</td>
                                      {/* <td>{remaining.toLocaleString()}</td>
                                      <td>{remainingPercentage}{"%"}</td> */}
                                      <td>{el?.target.toLocaleString()}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )}
                        </table>
                    </div>
                  </Scrollbar>
                </div>
                
              </div>
            </div>
            
            <div className="row mt-4">
              <div className="card">
                <FRRDistrictColumnChart data={reportRatePerDistrict} title={`Reporting rate (by district), ${yearMonth}`}/>
              </div>
              {/* <div className="col-md-12">
                <Tables />
              </div> */}
            </div>
          </div>
          
    </>
  );
}
