import PropTypes from 'prop-types';
import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import { useAuth } from 'src/hooks/useAuth';
import '../header/sty.css'
import axios from 'axios';
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import logout from './logout.svg';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const {data, dispatch} = useAuth();
  const baseUrl = useContext(BaseUrlContext);
  // console.log(data);
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  // const LogoutUser=()=>{
  //   localStorage.removeItem('auth');
  //   dispatch({type:'LOGIN', credentials: {}})
  //   // setTimeout(() => {
  //   //   navigate('/login')
  //   // }, 1000);
  //   setTimeout(() => {
  //     window.location.href = '/login'
  //   }, 2000);
  // }

  const LogoutUser=async()=>{
    localStorage.clear();
    setTimeout(() => {
      window.location.href = '/login'
    }, 500);
    return
    const res = await axios({
      method: "get",
      url: `${baseUrl}/auth/logout`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((response) => {
      // localStorage.removeItem('auth');
      // localStorage.removeItem('token');
      localStorage.clear();
      dispatch({type:'LOGIN', credentials: {}})

      setTimeout(() => {
        window.location.href = '/login'
      }, 2000);
    }).catch((error) => {
      console.error(error);
    });
}



  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
    style={{backgroundColor:"#222d32"}}
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        {/* <Logo /> */}
        <img src="/favicon/coa.png" height={'55px'}  alt="Logo" className='nav_logo'/>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          {/* <StyledAccount>
            <Avatar src={data.business_logo ? data.business_logo:account.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: '#374151' }}>
                {data.business_name ? data.business_name : 'User full name'}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {data.role ? data.role :"Admin"}
              </Typography>
            </Box>
          </StyledAccount> */}
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 0 }}>
        <Stack alignItems="left" spacing={3} sx={{ pt: 5, pl:6, borderRadius: 2, position: 'relative' }}>
          {/* <Box
            component="img"
            src="/assets/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box> */}
          <p className='logout'onClick={LogoutUser}>
            <img src={logout} alt="icon" style={{height:'20px'}} /> &nbsp;
            {/* <FontAwesomeIcon icon="fa-solid fa-right-from-bracket" style={{color: "#ffffff",}} /> */}
            Logout
          </p>
          {/* <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
          </Button> */}
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
