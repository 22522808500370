import { useRef, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
// init the module
HC_exporting(Highcharts);

const DonutChart = (chartData) => {
  const chartRef = useRef(null);
  const [donutChartOptions, setDonutChartOptions] = useState();

  useEffect(() => {

    if (!chartData) return;

    const colors = ['#1EB53A', '#3c8dbc'];
    const mainData = [
      {
        name: 'Vaccinated',
        y: chartData.data[0]?.y, 
        color: colors[0],
        numerator: chartData.data[0]?.vaccination,
        denominator: chartData.data[0]?.target
      },
      {
        name: 'Unvaccinated',
        y: 100 - chartData.data[0]?.y, 
        color: colors[1],
        numerator: chartData.data[0]?.target - chartData.data[0]?.vaccination,
        denominator: chartData.data[0]?.target
      }
    ];

    // Highcharts configuration options
    const donut_chart_options = {
      chart: {
        type: 'pie',
        events: {
          render: function () {
            const chart = this,
                  center = chart.series[0].center,
                  renderer = chart.renderer;

            if (chart.targetText) {
              chart.targetText.destroy();
            }

            let totalTarget = 0;
            mainData.forEach(data => {
              totalTarget += (data.denominator/2) || 0;
            });

            chart.targetText = renderer.text(
              // `Target: ${Highcharts.numberFormat(totalTarget, 0)}`,
              center[0],
              center[0]
            ).attr({
              zIndex: 3
            }).css({
              color: '#000',
              fontSize: '12px'
            }).add();
          }
        }
      },
      title: {
        text: chartData?.title,
        style: {
          fontSize: '15px',           
          fontWeight: 'bold',
          color: '#333',
          textAlign: 'center',
          margin: '20px 0',
          fontFamily: 'josefin-sans-header, sans-serif, Arial',
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%']
        }
      },
      tooltip: {
        valueSuffix: '%',
        pointFormatter: function () {
          return `<b>${this.name}:</b> ${Highcharts.numberFormat(this.y, 1)}% <br> (<b>Numerator:</b> ${Highcharts.numberFormat(this.numerator, 0, ',', ',')} / <b>Denominator:</b> ${Highcharts.numberFormat(this.denominator, 0, ',', ',')})`;
        }
      },
      series: [{
        name: 'Percent',
        data: mainData,
        size: '60%',
        innerSize: '60%',
        dataLabels: {
          format: '<b>{point.name}:</b> <br/> <span style="opacity: 1">{y:.1f}%</span>',
          filter: {
            property: 'y',
            operator: '>',
            value: 1
          },
          style: {
            fontWeight: 'bold'
          }
        },
        id: 'versions'
      }],
      legend: {
        enabled: true,
        align: 'center',
        layout: 'vertical',
        verticalAlign: 'middle',
        labelFormat: '{point.name}', 
        itemStyle: {
          fontSize: '10px'
        },
        symbolWidth: 10, 
        symbolHeight: 10, 
        symbolRadius: 0,
        labelFormatter: function () {
          return this.name;
        }
      },
      credits: {
        enabled: false 
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 300
          },
          chartOptions: {
            series: [{
              id: 'versions',
              dataLabels: {
                distance: 0,
                filter: {
                  property: 'percentage',
                  operator: '>',
                  value: 2
                }
              }
            }]
          }
        }]
      }
    };

    setDonutChartOptions(donut_chart_options);
  }, [chartData]);

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={donutChartOptions}
        containerProps={{ ref: chartRef }}
      />
    </>
  );
};

export default DonutChart;