import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useContext } from 'react';
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import { useNavigate, useLocation } from "react-router-dom";
import Select from '../../components/inputs/Select';
import styles from "./EpiPopulation.module.css";
import Axios from "../../axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-toastify/dist/ReactToastify.css';
import { getRoles, getUsertTypes } from '../../CommonMethods';
import { getFilterYears } from '../../CommonMethods';
// import { useLocation } from 'react-router-dom';
// import TextField from '../../components/inputs/Textbox';
import TextField from '@mui/material/TextField';
import {
  Card,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem
} from '@mui/material';
const TABLE_HEAD = [
  { id: 'sn', label: '#', alignRight: false },
  { id: 'year', label: 'Year', alignRight: false },
  { id: 'district', label: 'District', alignRight: false },
  { id: 'epi_population', label: 'Epi Population', alignRight: false },
  { id: 'target', label: 'Target', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: false },
];
export default function UserPage() {  
    const baseUrl = useContext(BaseUrlContext);
    const location = useLocation();
    const navigate = useNavigate();

  // get values to send to backend
    const [epiPopulation, setEpiPopulation] = useState('');
    const [target, setTarget] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const [filterYearList, setFilterYearLists] = useState([]);
    const [formDataArray, setFormDataArray] = useState([]);
    const [loading, setIsIsLoading] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);

    const [district, setDistrict] = useState(''); 
    const [role, setRole] = useState('');  
    const [districtList, setDistrictList] = useState([]); 
    const [isPending, setIsPending] = useState(false);
    const handleInputChange = (event, newInputValue) => {
      setInputValue(newInputValue);
    }; 
    const handleEPiPopulationChange = (event) => {
      setEpiPopulation(event.target.value);
    };
    const handleTargetChange = (event) => {
      setTarget(event.target.value);
    };
    const handleDelete = (index) => {
      const newData = [...formDataArray];
      newData.splice(index, 1); 
      setFormDataArray(newData);
    }
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const yearParam = searchParams.get('year');
  
      if (yearParam) {
        setFilterYear(yearParam)
      }
    }, [location.search]);

      const statusList  = [
        { id: 1, name: 'Active' },
        { id: 2, name: 'Inactive' }
      ];

      const handleYearFilterChange = (event) => {
        let selectedValue = event.target.value;
        setFilterYear(selectedValue);
      }

      const getAllFilterYears = async () => {
        setIsIsLoading(true);
        try {
          let years = await getFilterYears(); 
          let yearsUpdate = getYearPlusOne(years);
          setFilterYearLists(yearsUpdate);
          setIsIsLoading(false); 
    
        } catch (error) {
          console.error('Failed to fetch roles:', error);
          notify_user("Unable to get filter years", "error")
          setIsIsLoading(false);
        }
      }
    
      const getYearPlusOne = (years) => {
        let maxIdObject = years.reduce((max, year) => {
          let currentId = parseInt(year.id);
          if (currentId > parseInt(max.id)) {
              return year;
          } else {
              return max;
          }
        }, years[0]);
      
        let newId = parseInt(maxIdObject.id) + 1;
        let newYear = {
            "id": `${newId}`,
            "name": `${newId}`
        };
        years.push(newYear);
        // setFilterYearLists(years);
        return years;
    
      } 
      const handleAdd = () => {
        setFilterLoading(true); 
        const foundDistrict = districtList.find(item => item.id === district)?.name;
    
        const newFormData = {
          year: filterYear,
          district: foundDistrict,
          districtId: district,
          epiPopulation: epiPopulation,
          target: target
        };
    
        setFormDataArray([...formDataArray, newFormData]);
        setFilterLoading(true); 
        setDistrict('');
        setEpiPopulation('');
        setTarget(''); 
        setFilterLoading(false); 
      };

      const collateTextValue = (event) => {
        const { name, value } = event;
        if (name === "epiPopulation") {
          setEpiPopulation(value);
        }else if (name === "target") {
          setTarget(value);
        }
      };
  
    
    const handleSubmit = async (event) => {
      setIsPending(true);
      event.preventDefault();
      
      const formData = {
        data:formDataArray
      };
    
      Axios.post(`api/create/epi/data`, formData)
      .then((response) => {
        setIsPending(false);
        notify_user("Created successfully", 'success');
        goto("epi_population")

      }).catch((error) => {
        setIsPending(false);
        notify_user(error?.response?.data?.message, "error")
        console.error(error);
      });
    };

    const getAllDistricts = async () => { 
      setIsIsLoading(true);
      Axios.get(`api/geo-locations?level=2`)
      .then((response) => {
        setIsIsLoading(false);
        setDistrictList(response.data.data)
      }).catch((error) => {
        setIsIsLoading(false);
        notify_user(error?.response?.data?.message, "error")
        console.error(error);
      });
    };

    const getRolesData = async () => { 
      try {
        let roles = await getRoles();
        setRoleList(roles);
      } catch (error) {
        console.error('Failed to fetch roles:', error);
      }
    };
    useEffect(() => {
      getAllDistricts();
      getRolesData();
      getAllFilterYears(); 
    }, []); 

    const notify_user = (message, toast_type) => {
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }else if (toast_type == 'info'){
        toast.info(message, {
          autoClose: 20000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#97ddef',
            color:"#000000"
          },
        })
      }
      
    };
    const goto = (d) => {
      navigate('/dashboard/'+d)
    };
    const formatNumber = (number)=>{
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

 
    const handleRoleChange = (value) => {
        setRole(value)
      }
    const handleDistrictChange = (event) => {
        let selectedValue = event.target.value;
        setDistrict(selectedValue);
    }
    const handleStatusChange = (value) => {
        setStatus(value)
    }

    if (filterLoading) {
      return <div>Loading...</div>;
    }
  
    return (
      <>
        <Helmet>
          <title> Add Epi Data </title>
        </Helmet>
        
  
        {/* <Container> */}
        <ToastContainer /> 
        <div className={`${styles.header_container} p-4 d-flex mb-3`} >
            {/* <img src="/assets/icons/roles_icon.png" alt="icon" />&nbsp;&nbsp; */}
                <span className={`${styles.header_text} text-white`}> Add Record </span>
            </div>
          <Card className='p-4 light_card_background'>
            <form action="" className="mt-0" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-3">
                  <FormControl fullWidth >
                    <InputLabel id="select-label">Select Year</InputLabel>
                    <MuiSelect
                      labelId="select-label"
                      id="select-box"
                      value={filterYear }
                      label={"Select Year"}
                      onChange={handleYearFilterChange}
                      fullWidth
                      size="small" 
                    > 
                      <MenuItem value="">Select</MenuItem> 
                      {filterYearList?.map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}  
                    </MuiSelect>
                  </FormControl>
                  </div>
                  <div className="col-md-3 mb-4"> 
                  <FormControl fullWidth >
                    <InputLabel id="select-label">Select District</InputLabel>
                    <MuiSelect
                      labelId="select-label"
                      id="select-box"
                      value={district}
                      label={"Select District"}
                      onChange={handleDistrictChange}
                      fullWidth
                      size="small" 
                      // required
                    > 
                      <MenuItem value="">Select</MenuItem> 
                      {districtList?.map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}  
                    </MuiSelect>
                  </FormControl> 
                  </div> 
              
                  <div className="col-md-3 mb-4">
                      <TextField 
                          label="Epi Population"
                          variant="outlined"
                          fullWidth
                          name="epiPopulation"
                          value={epiPopulation}
                          onChange={handleEPiPopulationChange}
                          type={'text'}
                          // required
                      />
                  </div>
                  <div className="col-md-2 mb-1">
                      <TextField 
                          label="Target"
                          variant="outlined"
                          fullWidth
                          name="target"
                          value={target}
                          title="Target" 
                          type={'text'}
                          // required
                          onChange={handleTargetChange} 
                      />
                  </div> 
                  <div className="col-md-1">
                    <button type="button" className={`btn btn-block buttons mt-2`} onClick={handleAdd}>
                        Add 
                    </button>
                  </div>
                </div> 
                <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}>
                <table className={`${styles.table} table-hover table-striped`}>
                  <thead className={`${styles.thead}`}>
                    <tr>
                      {TABLE_HEAD.map((el, i) => {
                        return (
                          <th key={i} className="text-muted th">{el.label}</th>
                        )
                      })}

                    </tr>

                  </thead>
                  {formDataArray.length === 0 ? (
                    <tbody >
                      <tr >
                        <td colSpan={8}>
                          <p className="alert alert-info mt-3 w-100 text-center" role="alert">
                            No data available.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  ):(
                  <tbody>
                  {formDataArray?.map((el, i) => {
                        return (
                          <tr key={i} style={{ cursor: 'pointer' }} className={`${styles.tr}`}>
                            <td key={i}>{i+1}</td>
                            <td>
                              {el?.year}
                            </td>
                            <td>
                                  {el?.district}
                            </td>
                            <td>
                              {formatNumber(el?.epiPopulation)}
                            </td>
                            <td>
                              {formatNumber(el?.target)}
                            </td> 
                            <td>
                              <button className="btn btn-danger btn-sm" onClick={() => handleDelete(i)}>
                                Delete
                              </button>
                            </td>
                          </tr>
                          
                        )
                      })}

                  </tbody>
                  )}
                </table>
            </div>
                <div className="mb-4 mt-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button type="button" className={`btn btn-block buttons_outline`} onClick={()=>goto("epi_population")}>
                        Back
                    </button>
                    <button type="submit" className={`btn btn-block buttons`}>
                        Submit &nbsp; &nbsp;
                        {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                    </button>
                </div>
            </form>
            {loading &&
              <div className='loader'>
                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
              </div>
            }

            
          </Card>
        {/* </Container> */}

      </>
    );
  }