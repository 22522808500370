import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsMap from 'highcharts/modules/map';
import HighchartsReact from 'highcharts-react-official';
import { feature } from 'topojson-client';
import topoJson from './distr19d.json';

HighchartsMap(Highcharts);

const MapChart = ({ data, title, subTitle }) => {
    const [chartOptions, setChartOptions] = useState({
        chart: {
            height: 500,
            margin: [60, 20, 80, 20],
            spacing: [0, 0, 0, 0],
        },
        title: {
            text: 'Loading...',
            align: 'center',
            verticalAlign: 'middle'
        },
        credits: {
            enabled: false
        }
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [geoJsonData, setGeoJsonData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const convertedData = feature(topoJson, topoJson.objects.distr19d);
                setGeoJsonData(convertedData);

                const year = Object.keys(data)[0];
                const formattedData = data[year]
                    .filter(item => item.longitude && item.latitude)
                    .map(item => ({
                        x: parseFloat(item.longitude),
                        y: parseFloat(item.latitude),
                        location: item.location,
                        value: item.total_girls,
                        year: item.year
                    }));

                const valueMap = formattedData.reduce((acc, item) => {
                    acc[item.location] = item.value;
                    return acc;
                }, {});

                const seriesData = convertedData.features.map(feature => {
                    const locationName = feature.properties.name; 
                    const value = valueMap[locationName] || 0; 
                    return {
                        name: locationName,
                        value: value
                    };
                });

                setChartOptions({
                    chart: {
                        type: 'map',
                        height: 500,
                        margin: [55, 20, 80, 2],
                        spacing: [0.5, 0.5, 0.5, 0.5],
                    },
                    title: {
                        text: title,
                        align: 'center',
                        verticalAlign: 'top'
                    },
                    mapNavigation: {
                        enabled: false,
                        buttonOptions: {
                            verticalAlign: 'bottom'
                        }
                    },
                    colorAxis: {
                        min: 0,
                        max: Math.max(...seriesData.map(d => d.value)),
                        type: 'linear',
                        labels: {
                            format: '{value}'
                        },
                        stops: [
                            [0, '#FF0000'], // light red for low value color
                            [0.5, '#FFFF00'], // light yellow for mid value color
                            [1, '#00FF00']  // light green for high value color
                        ]
                    },
                    series: [{
                        data: seriesData,
                        mapData: convertedData,
                        joinBy: ['name', 'name'],
                        name: subTitle,
                        states: {
                            hover: {
                                color: '#BADA55'
                            }
                        }, 
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                const parts = this.point.name.split(' '); 
                                const part1 = parts[0] || ''; 
                                const part2 = parts.slice(1).join(' ') || ''; 
                                return `${part1}<br>${parts.length > 2 ? part2 :''}`; 
                            }
                        }

                    }]
                });

                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [data]);

    if (loading) return <p>Loading map...</p>;

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType="mapChart"
                options={chartOptions}
            />
        </div>
    );
};

export default MapChart;
