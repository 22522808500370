import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useContext } from 'react';
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import { useNavigate } from "react-router-dom";

import Select from '../../components/inputs/Select';
import styles from "./Facility.module.css";
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-toastify/dist/ReactToastify.css';
import Axios from "../../axiosInstance";
import TextField from '../../components/inputs/Textbox';
import {
    Card,
    Container,
  } from '@mui/material';

export default function UserPage() {  
    const [modalOpen, setFacilityModalOpen] = useState(false);
    const navigate = useNavigate();
    const handleModalClose = () => {
      setFacilityModalOpen(false);
    };

  //============================================ Text Filed End=================================================//
  // get values to send to backend
    const [facilityName, setfacilityName] = useState('');
    const [facilityCode, setfacilityCode] = useState('');
    const [operationalBy, setoperationalBy] = useState('');
    const [ward, setWard] = useState('');
    const [state, setState] = useState('');
    const [lga, setLga] = useState('');
    const [status, setStatus] = useState('');
    const [stateList, setStateList] = useState([]);
    const [lgaList, setLgaList] = useState([]);
    const [wardList, setWardList] = useState([]);
    const [isPending, setIsPending] = useState(false);
    const [loading, setLoading] = useState(true);
      const statusList  = [
        { id: 1, name: 'Active' },
        { id: 2, name: 'Inactive' },
      ];

    const collateTextValue = (event) => {
      const { name, value } = event;
      switch (name) {
        case "facilityName":
          setfacilityName(value);
        case "facilityCode":
          setfacilityCode(value);
        case "operationalBy":
            setoperationalBy(value);
        default:
          break;
    }
  
      // if (name === 'facilityName') {
      //   setfacilityName(value);
      // } else if (name === 'facilityCode') {
      //   setfacilityCode(value);
      // }
    };

    const handleSubmit = async (event) => {
      setIsPending(true);
      event.preventDefault();
      let active = status === 1 ? true : false;
      const formData = {
        name:facilityName,
        code:facilityCode,
        operatedBy:operationalBy,
        geoLocation:{
          id:ward
        },
        active
      };
      Axios.post(`api/facilities`, formData)
      .then((response) => {
        notify_user("Facility added successfully", "success");
        goto("facility");
        setIsPending(false)
      }).catch((error) => {
        setIsPending(false)
        notify_user("Unable to add facility", "error") 
      });
      handleModalClose()
    };


    const notify_user = (message, toast_type) => {
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }else if (toast_type == 'info'){
        toast.info(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#97ddef',
            color:"#000000"
          },
        })
      }
      
    };
    const goto = (d) => {
      navigate('/dashboard/'+d)
    };

  
    const handleWardChange = (geoZoneValue) => {
        setWard(geoZoneValue)
    }
    const handleStatusChange = (value) => {
      setStatus(value)
      }
    const handleStateChange = (value) => {
        setState(value)
    }
    const handlelgaChange = (value) => {
        setLga(value)
    }

    const getAllStates = async () => { 
      setLoading(true);
      Axios.get(`api/geoLocations/level/3?size=50`)
      .then((response) => {
        setStateList(response?.data?.content);
        setLoading(false);
      }).catch((error) => {
        console.error(error);
        setLoading(false);
      });
    };

    const getAllLgas = async () => { 
      setLoading(true);
      Axios.get(`api/geoLocations/${state}/children?size=500`)
      .then((response) => {
        setLgaList(response?.data?.content)
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        console.error(error);
      });
    };
    
    const getAllWards = async () => { 
      setLoading(true);
      Axios.get(`api/geoLocations/${lga}/children`)
      .then((response) => {
        setWardList(response?.data?.content)
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        console.error(error);
      });
    };

    useEffect(() => {
      if (state !== '') {
        getAllLgas()
      }
      if (lga !== '') {
        getAllWards()
      }
    }, [state, lga]); 
  
    useEffect(() => {
      getAllStates();
    }, []); 

    return (
      <>
        <Helmet>
          <title> Add Facility | VTCD </title>
        </Helmet>
        
  
        <Container>
        <ToastContainer />
        <div className={`${styles.header_container} p-4 d-flex mb-3`} >
            {/* <img src="/assets/icons/roles_icon.png" alt="icon" />&nbsp;&nbsp; */}
                <span className={`${styles.header_text}`}> Add  Facility </span>
            </div>
          <Card className='p-4 light_card_background'>
          <form action="" className="mt-0" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-4">
                    <TextField 
                        name="facilityName"
                        title="Facility Name" 
                        value={facilityName}
                        required
                        onValueChange={collateTextValue} 
                    />
                </div>
                <div className="col-md-6 mb-1">
                    <TextField 
                        name="facilityCode"
                        value={facilityCode}
                        title="Facility Code" 
                        required
                        onValueChange={collateTextValue} 
                    />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-4">
                    <TextField 
                        name="operationalBy"
                        title="Operational By" 
                        value={operationalBy}
                        onValueChange={collateTextValue} 
                        required
                    />
                </div>
            
                <div className="col-md-6 mb-4">
                     <Select
                        name="state"
                        title="State *"
                        value={state}
                        optionList={stateList}
                        onSelect={handleStateChange}
                        required={true}
                      />
                </div>
                <div className="col-md-6 mb-1">
                    <Select
                        name="lga"
                        title="Lga *"
                        value={lga}
                        optionList={lgaList}
                        onSelect={handlelgaChange}
                        required={true}
                    />
                </div>
        
                <div className="col-md-6 mb-4">
                    <Select
                        name="ward"
                        title="Ward *"
                        value={ward}
                        optionList={wardList}
                        onSelect={handleWardChange}
                        required={true}
                    />
                </div>
                <div className="col-md-6 mb-1">
                    <Select
                        name="status"
                        title="Status *"
                        value={status}
                        optionList={statusList}
                        onSelect={handleStatusChange}
                        required={true}
                    />
                </div>
              </div> 
              <div className="mb-4 mt-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button type="button" className={`btn btn-block buttons_outline`} onClick={()=>goto("facility")}>
                      Back
                  </button>
                  <button type="submit" className={`btn btn-block buttons`}>
                      Add &nbsp; &nbsp;
                      {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                  </button>
              </div>
            </form>
            {loading &&
              <div className='dashboard_loader'>
                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
              </div>
            }
          </Card>
        </Container>

      </>
    );
  }