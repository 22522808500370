import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Scrollbar from '../../components/scrollbar'; 
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert';
import styles from "./Coverage.module.css"
import Axios from "../../axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import LineChart from '../../components/highcharts/LineChart';
import R23LineChart from '../../components/highcharts/R23LineChart';
import HeatMapChart from '../../components/highcharts/HeatMapChart';
import ColumnChart from '../../components/highcharts/ColumnChart';
import RegionalColumnChart from '../../components/highcharts/RegionalColumnChart';
import DistrictColumnChart from '../../components/highcharts/DistrictColumnChart';
import StackedColumnChart from '../../components/highcharts/StackedColumnChart';
import CountryChiefdomHeatMap from '../../components/highcharts/CountryChiefdomHeatMap';
import CountryDitrictHeatMap from '../../components/highcharts/CountryDistrictHeatMap';
import CountryHeatMap from '../../components/highcharts/CountryHeatMap';
import CountryHeatMap2 from '../../components/highcharts/CountryHeatMap2';
import { permissions, hasPermission } from '../../Authorization'; 
import { FormControl,FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';
import { getFilterYears } from '../../CommonMethods';
import CustomSelect from '../../components/inputs/Select';
import { Tabs } from 'antd';

import {
    Card,
    Container,
  } from '@mui/material';
import Pagination from '../Common/Pagination';
HC_exporting(Highcharts);

const monthList = [
  { 'id': '01', 'name': 'January' },
  { 'id': '02', 'name': 'February' },
  { 'id': '03', 'name': 'March' },
  { 'id': '04', 'name': 'April' },
  { 'id': '05', 'name': 'May' },
  { 'id': '06', 'name': 'June' },
  { 'id': '07', 'name': 'July' },
  { 'id': '08', 'name': 'August' },
  { 'id': '09', 'name': 'September' },
  { 'id': '10', 'name': 'October' },
  { 'id': '11', 'name': 'November' },
  { 'id': '12', 'name': 'December' }
];


export default function CoveragePage() { 
    const { TabPane } = Tabs;
    const [chart1Title, setChart1Title] = useState('');
    const [monthlyAnalysisTitle, setmonthlyAnalysisTitle] = useState('');
    const [percentageMonthlyAnalysisTitle, setpercentageMonthlyAnalysisTitle] = useState('');
    const [heatMapTitle, setHeatMapTitle] = useState('');
    const [loading, setIsIsLoading] = useState(false);
    const [filterYear, setFilterYear] = useState('All');
    const [filterMonth, setFilterMonth] = useState('');
    const [filterYearList, setFilterYearList] = useState([]);
    const [isPending, setIsPending] = useState(false);
    const [yearlyMontlyAnalysis, setYearlyMontlyAnalysis] = useState([]);
    const [yearlyRegionalAnalysis, setYearlyRegionalAnalysis] = useState([]);
    const [montlyAnalysis, setMontlyAnalysis] = useState([]);
    const [montlyAnalysisPerDistrict, setMontlyAnalysisPerDistrict] = useState([]);
    const [inSchoolMontlyAnalysisPerDistrict, setInSchoolMontlyAnalysisPerDistrict] = useState([]);
    const [outSchoolMontlyAnalysisPerDistrict, setOutSchoolMontlyAnalysisPerDistrict] = useState([]);
    const [yearlyAnalysis, setYearlyAnalysis] = useState([]);
    const [yearlyInOutVaccinationPerDistrict, setYearlyInOutVaccinationPerDistrict] = useState([]);
    const [filterClicked, setFilterClicked] = useState(false);
    const [district, setDistrict] = useState("");
    const [council, setCouncils] = useState("");
    const [districtList, setDistrictList] = useState([])
    const [councilList, setCouncilList] = useState([])
    const [showReset, setShowReset] = useState(false);
    const [previousState, setPreviousState] = useState('');
    const [inOutSchoolVaccinatedData, setInOutSchoolVaccinatedData] = useState([]);
    const [inOutSchoolVaccinationReadyData, setInOutSchoolVaccinationReadyData] = useState([{
      'y': 0,
      'color': '',
      'target':0
    }]);
    const [categories, setCategories] = useState([
      'Vaccinated',
      'Unvaccinated'
    ]);
    const [searchParameter, setSearchParameter] = useState({
      'district':'',
      'council':'',
    });
    
    const navigate = useNavigate();
    const goto = (d) => {
      navigate('/dashboard/'+d)
    };

    const handleDistrictChange = (value) => {
      setDistrict(value);
      setSearchParameter((prevSearchParameter) => ({
        ...prevSearchParameter, 
        district: value,
      }));
    };

    const getAllDistricts = async () => { 
      setIsIsLoading(true)
      Axios.get(`api/geo-locations?level=2`)
      .then((response) => {
        const districts = response?.data?.data;
        if (districts) {
          const sortedDistricts = districts.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
          setDistrictList(sortedDistricts);
        } else {
          setDistrictList([]); 
        }
        // setDistrictList(response?.data?.data)
        setIsIsLoading(false)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false)
      });
    };

    useEffect(() => {
      if (district !== '') {
        // getAllCouncils();
      } 
      // if (lg !== '') {
      //   getAllWards()
      // }
      setPreviousState(district);
    }, [district]); 

    const handleCouncilChange = (value) => {
      setCouncils(value);
      setSearchParameter((prevSearchParameter) => ({
        ...prevSearchParameter, 
        council: value,
      }));
    };
    const getAllCouncils = async () => { 
      setIsIsLoading(true)
      Axios.get(`api/geo-location-children?locationId=${district}`)
      .then((response) => {
        setCouncilList(response?.data?.data);
        setIsIsLoading(false)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false)
      });
    };
    

    const getAllFilterYears = async () => {
      setIsIsLoading(true); 
      try {
        let years = await getFilterYears();
        setFilterYearList(years);
        setIsIsLoading(false);

      } catch (error) {
        console.error('Failed to fetch roles:', error);
        notify_user("Unable to get filter years", "error")
        setIsIsLoading(false);

      }
    } 

    const notify_user = (message, toast_type) => { 
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }else if (toast_type == 'info'){
        toast.info(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#97ddef',
            color:"#000000"
          },
        })
      }
      
    };

    const handleYearFilterChange = (event) => {
      let selectedValue = event.target.value;
      setFilterYear(selectedValue);
    }

    const handleMonthFilterChange = (event) => {
      let selectedValue = event.target.value;
      setFilterMonth(selectedValue);
    }

    const getVaccinatedInOutSchoolsChartData = async () => {
      setIsIsLoading(true);  
      const formData = {
        year:'All'//filterYear,
        // location:council
      };
      Axios.post(`api/coverage/proportion-since-introduction`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setInOutSchoolVaccinatedData(response.data.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };

    const getYearyMonthlyVaccinatedInOutSchoolsChartData = async () => {
      setIsIsLoading(true); 
      let location;
      if(district != '' && council != ''){
        location = council;
      }else if(district != '' && council == ''){
        location = district;
      }else{
        location = '';
      }
      const formData = {
        year:filterYear,
        location: location
      };
      Axios.post(`api/coverage/yearly-monthly-analysis`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setYearlyMontlyAnalysis(response.data.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };

    const getYearyRegionalVaccinatedInOutSchoolsChartData = async () => {
      setIsIsLoading(true);  
      const formData = {
        year:filterYear,
      };
      Axios.post(`api/coverage/yearly-regional-analysis`, formData)
      .then((response) => {
        setIsIsLoading(false);
        console.log(response.data.data);
        setYearlyRegionalAnalysis(response.data.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };

    const getMonthlyVaccinatedInOutSchoolsChartData = async () => {
      setIsIsLoading(true); 
      let location;
      if(district != '' && council != ''){
        location = council;
      }else if(district != '' && council == ''){
        location = district;
      }else{
        location = '';
      }
      const formData = {
        year:filterYear, //'2023'
        location: location
        // month:filterMonth,
      };
      Axios.post(`api/coverage/monthly-vaccination-coverage`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setMontlyAnalysis(response?.data?.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };

    const getMonthlyVaccinatedInOutSchoolsPerDistrictChartData = async () => {
      setIsIsLoading(true); 
      const formData = {
        year:filterYear,//'2023',
        month:filterMonth
      };
      Axios.post(`api/coverage/in-out-per-district`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setMontlyAnalysisPerDistrict(response?.data?.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };
    const getMonthlyVaccinatedInSchoolsPerDistrictChartData = async () => {
      setIsIsLoading(true); 
      const formData = {
        year:filterYear, //'2023',
        month:filterMonth
      };
      Axios.post(`api/coverage/in-school-per-district`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setInSchoolMontlyAnalysisPerDistrict(response?.data?.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };

    const getMonthlyVaccinatedOutSchoolsPerDistrictChartData = async () => {
      setIsIsLoading(true); 
      const formData = {
        year: filterYear, //'2023',
        month:filterMonth
      };
      Axios.post(`api/coverage/out-school-per-district`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setOutSchoolMontlyAnalysisPerDistrict(response?.data?.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };

    const getYearlyVaccinatedInOutSchoolsChartData = async () => {
      setIsIsLoading(true); 
      const formData = {
        year:filterYear, //'2023'
        month:filterMonth
      };
      Axios.post(`api/coverage/in-out-per-district`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setYearlyAnalysis(response.data.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };
    const getMaxYear = () => { 
      const yearIds = filterYearList?.map(item => item.id); 
      const highestYear = Math.max(...yearIds);
      
      return highestYear;
    }

    const getYearyInOutVaccinationPerDistrictChartData = async () => {
      setIsIsLoading(true); 
      let location;
      if(district != '' && council != ''){
        location = council;
      }else if(district != '' && council == ''){
        location = district;
      }else{
        location = '';
      }
      const formData = {
        year:filterYear == "All" ? getMaxYear():filterYear,
        location:location
      };
      Axios.post(`api/coverage/in-out-analysis-per-location`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setYearlyInOutVaccinationPerDistrict(response.data.data)
        // if(response?.data?.data){
        //   setFilterClicked(true);
        // }
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        // notify_user("Unable to get data", "error");
      });
    };

    const getAllChartsForFilteredYear = () => { 
      getVaccinatedInOutSchoolsChartData(); 
    }

    useEffect(() => {
      getVaccinatedInOutSchoolsChartData();
      getAllFilterYears();
      getYearlyVaccinatedInOutSchoolsChartData();
      getYearyMonthlyVaccinatedInOutSchoolsChartData();
      getYearyRegionalVaccinatedInOutSchoolsChartData();
      getMonthlyVaccinatedInOutSchoolsChartData();
      getAllDistricts();
      getMonthlyVaccinatedInOutSchoolsPerDistrictChartData()
      getMonthlyVaccinatedInSchoolsPerDistrictChartData();
      getYearyInOutVaccinationPerDistrictChartData(); 
      // R18
      getMonthlyVaccinatedOutSchoolsPerDistrictChartData();
    }, []); 

    useEffect(() => {
      let years = harvestYears();
      let maxYear = getMaxYear()??'';
      setpercentageMonthlyAnalysisTitle(`Monthly HPV vaccination coverage in schools and out of school vaccination`);
      setmonthlyAnalysisTitle(`Yearly monthly analysis of girls in schools and out of  HPV vaccinated since introduction by national`)
      // setmonthlyAnalysisTitle(`Yearly monthly analysis of girls in schools and out of  HPV vaccinated (${years}) since introduction by national`)
      setHeatMapTitle(`Total number of girls in schools and out of schools vaccinated per district `) //(${filterYear == 'All' ? maxYear: filterYear})
      if(filterYear == 'All'){ //(${years})
        setChart1Title(`Proportion of girls vaccination since introduction`);
      }
      // else{
      //   setChart1Title(`HPV vaccination coverage national by year (${filterYear})`);
      // }
    }, [filterYear, filterYearList]);

    const harvestYears = () => {
      return filterYearList?.map(item => item.id).join(', ');
    }
    

    useEffect(() => {
      let total = getTotal(inOutSchoolVaccinatedData);
      procesVaccinatedInOutSchools(total, inOutSchoolVaccinatedData)
    }, [inOutSchoolVaccinatedData]);

    const getTotal = (array) => { 
      let total = array['denominator'] + array['numerator'];
      
      return total;
    }

    const getPercentage = (number, total) => {

      const percentage = (number / total) * 100;
    
      const roundedPercentage = Math.ceil(percentage * 100) / 100;
      
      return roundedPercentage;
      
    };

    const procesVaccinatedInOutSchools = (total, inOutSchoolVaccinatedData) =>{
      const colors = Highcharts.getOptions().colors
  
      const numeratorKey = 'numerator';
      const denominatorKey = 'denominator';

      let chartData = Object.keys(inOutSchoolVaccinatedData).map((key, index) => {
          return {
              y: getPercentage(inOutSchoolVaccinatedData[key], total),
              color: colors[index+3],  
              target: inOutSchoolVaccinatedData[denominatorKey],  
              vaccination: inOutSchoolVaccinatedData[numeratorKey]
          };
      });
     
      setInOutSchoolVaccinationReadyData(chartData);
    }

    const handleSearchClick = (event) => {
      event.preventDefault();
      setIsPending(true);
      setIsIsLoading(true);
    
      //   let location = "";
      //   if (district !== "") 
      //     location = handleFilterForm(); 
    
      //   let prevUrl = localStorage.getItem('searchedUrl');
      //   if (prevUrl) {
      //     localStorage.removeItem('searchedUrl')
      //   }
      //   let setUrl = `api/geoLocations/filter?page=${currentPage - 1}&size=20&location=${location}`;
      //   localStorage.setItem('searchedUrl',setUrl);
    
        // const queryString = JSON.stringify(searchParameter);
        // localStorage.setItem('queryString',queryString);
        // if(district !== ''){
          getYearyInOutVaccinationPerDistrictChartData(); 
        // }
          getAllChartsForFilteredYear();
          getYearlyVaccinatedInOutSchoolsChartData();
          getMonthlyVaccinatedInOutSchoolsPerDistrictChartData();
          getMonthlyVaccinatedInSchoolsPerDistrictChartData();
          getMonthlyVaccinatedOutSchoolsPerDistrictChartData();
          getMonthlyVaccinatedInOutSchoolsChartData();
          getYearyMonthlyVaccinatedInOutSchoolsChartData();
          getYearyRegionalVaccinatedInOutSchoolsChartData()
          setTimeout(() => {
            setIsIsLoading(false);
            setIsPending(false);
            setFilterClicked(true);
          }, 3000);

    };

  
    return (
      <>
        <Helmet>
          <title> Coverage </title>
        </Helmet>
        
  
        <ToastContainer />
            <div className={`${styles.header_container} p-4 d-flex mb-3`} >
                {/* <img src="/assets/icons/roles_icon.png" alt="icon" />&nbsp;&nbsp; */}
                <span className={`${styles.header_text} text-white`}> Coverage </span>
            </div>
            {/* justify-content-end d-flex filter-select-right*/}
            <div className="">
              <form action="" onSubmit={handleSearchClick}>  
                <div className="row mb-2 ">
                  <span className="text-danger mb-3"><i><strong>Please Note:</strong> Filters apply to relevant charts only</i></span>

                  <div className="col-md-3">
                    <FormControl fullWidth >
                      <InputLabel id="select-label">Filter by Year</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select-box"
                        value={filterYear }
                        label={"Filter by Year"}
                        onChange={handleYearFilterChange}
                        fullWidth
                        size="small" 
                      > 
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="All">All</MenuItem>
                        {filterYearList?.map((value) => (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}  
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-3">
                    <FormControl fullWidth >
                      <InputLabel id="select-label">Filter by Month</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select-box"
                        value={filterMonth }
                        label={"Filter by Month"}
                        onChange={handleMonthFilterChange}
                        fullWidth
                        size="small" 
                      > 
                        <MenuItem value="">Select</MenuItem>
                        {/* <MenuItem value="All">All</MenuItem> */}
                        {monthList?.map((value) => (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}  
                      </Select>
                    </FormControl>
                  </div> 
                  <div className="col-md-6"> 
                      <div className="row">
                        <div className={`col-md-4`}>
                          <div className="mb-3 ">
                              <CustomSelect
                              name="district"
                              title="District"
                              value={district}
                              optionList={districtList}
                              onSelect={handleDistrictChange}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-4">
                          <div className="mb-3">
                            <CustomSelect
                              name="council"
                              title="Council"
                              value={council}
                              optionList={councilList}
                              onSelect={handleCouncilChange}
                            />
                          </div>
                        </div>   */}
                          <div className="col-md-3">
                            <button type="submit" className={` btn modal_btn filter_btns`}>
                              Filter &nbsp; &nbsp;
                              {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                            </button>
                            {
                              showReset && 
                              <span style={{paddingLeft:"15px"}}>
                                <button type="button" onClick={reset} className={`  filter_btns btn btn-warning`}>
                                  Reset 
                                </button>
                              </span>
                            }
                          </div>
                      </div>

                    
                  </div>
                </div>
                {/* <div className="pt-2 pl-0">
                  <button onClick={getAllChartsForFilteredYear} className={`btn btn-block buttons ml-0 mr-2`}>
                      Filter &nbsp; &nbsp;
                      {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                  </button>
                </div> */}
              </form>
            </div>
            {/* <Card className='p-0 pt-3'> */}
            <div className="row">
              <div className="col-md-6"> 
                <Card className='p-4'>
                  <div className=" pt-2">
                    <ColumnChart data={inOutSchoolVaccinatedData} categories={categories} title={chart1Title}/>
                  </div> 
                </Card>
              </div>
              <div className="col-md-6">
                <Card className=''>
                  {/* filter */}
                  {/* <form action="" onSubmit={handleSearchClick}>
                    <div className="row pl-5 ml-5 pt-2 space">
                     
                    </div>
                    
                  </form> */}
                  <div className="col-md-12 pt-4">
                    {/* <div className="p-2 text-center fw-bold">{heatMapTitle}</div> */}

                    {district !== '' && filterClicked === true  ? (
                      <CountryChiefdomHeatMap data={yearlyInOutVaccinationPerDistrict} title={heatMapTitle} />
                    ) : (
                      <CountryDitrictHeatMap data={yearlyInOutVaccinationPerDistrict} title={heatMapTitle}/>
                    )}

                      {/* <CountryDitrictHeatMap data={yearlyInOutVaccinationPerDistrict}/> */}
                      {/* <CountryHeatMap1 data={yearlyInOutVaccinationPerDistrict}/> */}
                      {/* <CountryHeatMap data={yearlyInOutVaccinationPerDistrict} title={heatMapTitle}/> */}
                      {/* <CountryHeatMap2 data={yearlyInOutVaccinationPerDistrict} title={heatMapTitle}/> */}
                  </div>
                  {/* filter */}
                  {/* <div className="">
                    <HeatMapChart data={yearlyInOutVaccinationPerDistrict} title={heatMapTitle} />
                  </div> */}
                </Card>
              </div>
            </div> 
            
            {loading &&
              <div className='dashboard_loader'>
                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
              </div>
            }  
            {/* </Card> */}
            {/* R8 */}
            <Card className='p-5 mt-3'>
              <ColumnChart data={yearlyMontlyAnalysis} title={monthlyAnalysisTitle} />
            </Card>

            {/* R9 */}
            <Card className='p-5 mt-3'>
              <RegionalColumnChart data={yearlyRegionalAnalysis} title={"Proportion of girls in schools and out schools vaccinated per Region since introduction "} />
            </Card>
            <div className="row mt-2">
              <div className="col-md-12"> 
                <Card className='p-4'>
                  <div className=" pt-2">
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Stacked Column Chart" key="1">
                        <StackedColumnChart data={yearlyAnalysis} title="Total Number of girls vaccinated in schools and out of schools monthly per district" />
                      </TabPane>
                      <TabPane tab="Line Chart" key="2">
                        <R23LineChart data={yearlyAnalysis} title="Total Number of girls vaccinated in schools and out of schools monthly per district" />
                      </TabPane>
                    </Tabs>
                  </div> 
                </Card> 
              </div> 
            </div>  
            <div className="row">
              <div className="col-md-12">
                <Card className='p-5 mt-3'>
                  <LineChart data={montlyAnalysis} title={percentageMonthlyAnalysisTitle}/>
                </Card>
              </div>
            </div>
            {/* R24 */}
            <div className="row">
              <div className="col-md-12">
                <Card className='p-5 mt-3'>
                  <DistrictColumnChart data={montlyAnalysisPerDistrict} title={`Percentage of girls vaccinated in school and out of school with HPV vaccine, Monthly national `}/>
                </Card>
              </div>
            </div>
            {/* R18 */}
            {/* <div className="col-md-12">
              <Card className='p-5 mt-3'>
                <DistrictColumnChart data={outSchoolMontlyAnalysisPerDistrict} title={`Monthly HPV vaccination coverage out schools vaccination`}/>
              </Card>
            </div> */}
            {/* R17 */}
            <div className="row">
              <div className="col-md-6">
                <Card className='p-5 mt-3'>
                  <DistrictColumnChart data={inSchoolMontlyAnalysisPerDistrict} title={`Monthly HPV vaccination coverage in schools vaccination`}/>
                </Card>
              </div>
              <div className="col-md-6">
                <Card className='p-5 mt-3'>
                  <DistrictColumnChart data={outSchoolMontlyAnalysisPerDistrict} title={`Monthly HPV vaccination coverage out schools vaccination`}/>
                </Card>
              </div>
            </div>
            


      </>
    );
  }