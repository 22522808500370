import { useState } from 'react';
import React from 'react';
import Pagination from '@mui/material/Pagination';
import Select from '../../components/inputs/SelectPaginationSize';


const CustomPagination = ({ page, totalPages, onChange, response }) => {
    const [currentPage, setCurrentPage] = useState(page);
    const handleChange = (value) => {
      setCurrentPage(value);
      onChange(value);
    };

    return ( 
      <nav style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span style={{ flexGrow: 1 }}>{response?.from ?? 0} - {response?.to ?? 0} of {response?.total ?? 0}</span>
          <ul className="pagination" style={{ listStyle: 'none', display: 'flex', justifyContent: 'flex-end', margin: 0, padding: 0 }}>
              {/* Previous page button */}
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button className="page-link nonactive-button" onClick={() => handleChange(currentPage - 1)}>&laquo;</button>
              </li>

              {/* Page numbers */}
              {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                      <button className={`page-link ${currentPage === index + 1 ? 'active-button' : 'nonactive-button'}`} onClick={() => handleChange(index + 1)}>{index + 1}</button>
                  </li>
              ))}

              {/* Next page button */}
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                  <button className="page-link nonactive-button" onClick={() => handleChange(currentPage + 1)}> &raquo;</button>
              </li>
          </ul>
      </nav>
    );
  };
  
  export default CustomPagination;
