import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
// sections
import { LoginForm } from '../../sections/auth/login';

// @mui
import { IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { login } from '../../actions/authActions';


// ----------------------------------------------------------------------

import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// import { toast } from 'react-hot-toast';
import { Toaster, toast } from 'react-hot-toast';

import { useContext, useState } from "react"
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import axios from 'axios';
import { useAuth } from 'src/hooks/useAuth';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  
  const { dispatch } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signUpError, setSignUpError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const baseUrl = useContext(BaseUrlContext);
  const [showText, setShowText] = useState(false);
  const alertSuccess = () => toast.success('login successful');
  const alertError = (msg) => {
    toast.error(msg)
  };
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + '/' + dd + '/' + yyyy;

  const handleClick = () => {
    navigate('/register', { replace: true });
  };

  const handleForgotPwordClick = () => {
    navigate('/forgot_password', { replace: true });
  }

 
  const handleLogin = async () => {
    setIsPending(true);
      let emailContent = email.toLowerCase(); 
      const credentials = {
        email: emailContent,
        password,
      };
  
      // return console.log(`${baseUrl}/auth/login`);
       try {
      // Making the API request
      const res = await axios.post(`${baseUrl}/auth/login`, credentials, {
        headers:{
          "Accept": "*/*",
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        }
      });
  
      setIsPending(true);
      setSignUpError(null);
  
      alertSuccess();
      // console.log(error?.response?.data?.message);
      
      if (res.data) {
        console.log(res.data);
        const currentTime = new Date(); 
        // const expiryTime = new Date(currentTime.getTime() + (240000 - 180000));
        const expiryTime = new Date(currentTime.getTime() + (res.data.accessExpiry - 180000));

        // return console.log(futureTime); 
  
        localStorage.setItem('auth', JSON.stringify(res.data));
        // localStorage.setItem('location', JSON.stringify(res.data.location));
        localStorage.setItem('permissions', JSON.stringify(res.data.user.permissions));
        localStorage.setItem('role', JSON.stringify(res.data.user.role.name));
        localStorage.setItem('expiryTime', JSON.stringify(expiryTime));
        // localStorage.setItem('refreshToken', res.data.refreshToken);
        localStorage.setItem('token', res.data.accessToken);
  
        // Assuming 'dispatch' is a valid function used for state management
        dispatch({ type: 'LOGIN', credentials: res.data.data });
  
        setTimeout(() => {
          window.location.href = 'dashboard/app';
        }, 500);
        setIsPending(false);

      }
      
    } catch (error) {
      if(error?.response){
        alertError(error?.response?.data?.message ?? "Invalid Credentials");
      }
      console.error('Error while logging in:', error);
    }
    setIsPending(false);

  };
  
 

  return (
    <>
      <Toaster />
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
            // width:'170px'
          }}
        /> */}
      <img src="/favicon/coa.png" height={'70px'}  alt="Logo" 
        style={{
          position: 'fixed',
          top:'40px',//{ xs: '16px', sm: '24px', md: '40px' },
          left:'80px',// { xs: '16px', sm: '24px', md: '40px' }
        }} 
      />

      <div className="container">
        <div className="row">
          <div className="col-md-6 col-12">
            <StyledContent >
              <Typography variant="h4" gutterBottom>
                Sign in to Sierra Leone HPV integrated Dashboard
              </Typography>

              {(signUpError) && <Alert style={{
                border: '1px solid red'
              }} severity="error">{signUpError}</Alert>}

              <Stack spacing={3}sx={{ mt: 5 }}>
              <TextField 
                name="email" 
                label="Email address" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <TextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <Link className='theme_link' variant="subtitle2" onClick={handleForgotPwordClick} underline="hover">
                Forgot password?
              </Link>
              </Stack>

              <LoadingButton className='bg_green' fullWidth size="large" type="submit" variant="contained" onClick={handleLogin}>
              <span>
                Sign in &nbsp;
              </span>
              {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
              </LoadingButton>
            </StyledContent>
          </div>

          <div className="col-md-6 col-12 hide">
            <img width="100%" height="790px" src="/assets/illustrations/loginImage.jpg" alt="login" />
          </div>
        </div>
      </div>
        
      </StyledRoot>
    </>
  );
}
