import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isArray } from 'lodash';

export default function AutocompleteInput(props) {
    const {labelKey, name} = props;
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const notify_user = (message, toast_type) => {
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }
      
    };
    
    const handleSearch = async (e) => {
        
        const payload = {
            name: e.target.value,
        };
        let code = e.target.value
        // let url = `${props.url}`;
        let url = `${props.url}${code}`;
        setIsLoading(true);
        const res = await axios({
          method: "get",
          url: url,
          data: payload,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }).then((response) => {
          setIsLoading(false);
          setData(response.data.content)
        }).catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
  }
  
  const handleChange = (e, value) => {
    if (value) {
      if (isArray(value)) {
        const selectedData = value.map((option) => {
          if (Array.isArray(labelKey)) {
            return value.map((option) => option['id']);
          } else {
            return option[labelKey];
          }
        });
        props.handleSelectChange(name, selectedData);
      }else{
        let selectedData;
        if (Array.isArray(labelKey)) {
          selectedData = value['id'];
        } else {
          selectedData = value[labelKey];
        }

        props.handleSelectChange(name, [selectedData]);
      }
      
    } else {
      props.handleSelectChange(name, []);
    }
  
  };

  // const handleChange = (e, value) => {
  //   if (value) {
  //     let selectedData;

  //     if (Array.isArray(labelKey)) {
  //       selectedData = value['id'];
  //     } else {
  //       selectedData = value[labelKey];
  //     }

  //     props.handleSelectChange(name, [selectedData]);
  //   } else {
  //     props.handleSelectChange(name, []);
  //   }
  // };
  
  return (
    <>
    <ToastContainer/>
    <Autocomplete
      freeSolo
      options={data}
      getOptionLabel={(option) => {
        if (Array.isArray(labelKey)) {
          return option[labelKey[0]] + ' ' + option[labelKey[1]];
        } else {
          return option[labelKey];
        }
      }}
      onInputChange={handleSearch}
      onChange={handleChange}
      className="w-100"
      title={props.title}
      loading={isLoading}
      multiple={props.multiple??true}
      required={props.required??false}
      sx={{
        "& .MuiInputLabel-root": {
          color: "#808080"
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#808080"
          },
          "&:hover fieldset": {
            borderColor: "#808080"
          },
          "&.Mui-focused": {
            "& fieldset": {
              borderColor: "#3c8dbc"
            },
            "& .MuiInputLabel-root": {
              color: "#3c8dbc"
            }
          }
        }
      }}
      renderInput={(params) => 
        <TextField {...params} 
          label={props.label} 
          className={`w-100 bg-white`} 
          name={name}
          variant="outlined"  
        />}
    />
    
    </>
  );
};
