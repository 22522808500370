import { useState } from 'react';
import React from 'react';
import Pagination from '@mui/material/Pagination';
import Select from '../../components/inputs/SelectPaginationSize';


const CustomPagination = ({ page, count, onChange, response }) => {
    const [currentPage, setCurrentPage] = useState(page);
    const [size, setSize] = useState(20);
    // return console.log(page, count, onChange, response)
    const handleSizeChange = (value) => {
        setSize(value)
    }
  
    const handleChange = (event, value) => {
      setCurrentPage(value);
      onChange(event, value);
    };
  
    const formatPaginationDisplayText = (pageNumber, pageSize, totalElements) => {
        const startIndex = (pageNumber * pageSize)??0;
        const endIndex = (Math.min(startIndex + pageSize, totalElements)); 
        
        return `${(isNaN(startIndex) ? 0 : startIndex) + (isNaN(startIndex) ? 0 : 1)}-${isNaN(endIndex) ? 0 : endIndex} of ${totalElements || 0}`;
    }; 

    return (
      <div className="card border-0 p-0 m-0">
        <div className="card-body pt-0 mt-0">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-item-center pt-2">
              {formatPaginationDisplayText(
                response.pageable?.pageNumber,
                response.pageable?.pageSize,
                response.total
              )}
            </div> 
            <Pagination
              className="pt-2"
              count={count}
              page={currentPage}
              onChange={handleChange}
              variant="outlined"
              color="success"
            />
          </div>
        </div>
      </div>
    );
  };
  
  export default CustomPagination;
