import { Helmet } from 'react-helmet-async'; 
import { useState, useEffect, useContext, useRef } from 'react';
import Autocomplete from '../../components/inputs/Autocomplete'; 
// import Select from '../../components/inputs/Select';
import styles from "./EpiPopulation.module.css" 
import { useNavigate } from "react-router-dom";
import Axios from "../../axiosInstance";
// import { FormControl,FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';
import FileAxios from "../../axiosFileInstance";
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { permissions, hasPermission } from '../../Authorization';
import Pagination from '../Common/Pagination';
import { getFilterYears } from '../../CommonMethods';
import Stack from '@mui/material/Stack';
import { exportDownloader } from '../../utils/exportUtils';
import TextField from '@mui/material/TextField';

// import TextField from '../../components/inputs/Textbox';
// import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import emitter from 'src/emitter.js';

import Modal from "src/Modals/Modal";
// @mui
import {
  Card,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import {
  InnerAppAnalytics,
} from '../../sections/@dashboard/app';
// components
import Scrollbar from '../../components/scrollbar';

const TABLE_HEAD = [
  { id: 'sn', label: '#', alignRight: false },
  { id: 'year', label: 'Year', alignRight: false },
  { id: 'district', label: 'District', alignRight: false },
  { id: 'epi_population', label: 'EPI Population', alignRight: false },
  { id: 'target', label: 'Target', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: false },
];


export default function UserPage() {

  const navigate = useNavigate();
  const goto = (d) => {
    navigate('/dashboard/'+d)
  };
 

  
  const handleModalClose = () => {
    setEditPopulationModalOpen(false);
    setAddPopulationModalOpen(false);
    setSelectedFiles([]);
  };


  const [state, setState] = useState([]);
  const [filterYear, setFilterYear] = useState('');
  const [filterYearList, setFilterYearLists] = useState([]);
  const [lg, setLG] = useState([]);
  const [userType, setUserType] = useState("");
  const [populationList, setPopulationList] = useState([]);
  const [response, setResponse] = useState([]);
  const [district, setDistrict] = useState(''); 
  const [targetId, setTargetId] = useState(''); 
  const [populationId, setPopulationId] = useState(''); 
  const [filterUserValues,setFilterUserValues] = useState([]);
  const [status, setStatus] = useState("");
  const [districtList, setDistrictList] = useState([]); 
  const [epiPopulation, setEpiPopulation] = useState('');
  const [target, setTarget] = useState('')

  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dialogue, setDialogue] = useState(false);
  const [dialogueContent, setDialogueContent] = useState('');
  const [editPopulationModalOpen, setEditPopulationModalOpen] = useState(false);
  const [addPopulationModalOpen, setAddPopulationModalOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loading, setIsIsLoading] = useState(false);

  const csvDownloadBaseUrl = process.env.REACT_APP_CSV_TEMPLATE_BASE_URL;
  const [currentPage, setCurrentPage] = useState(1);
  const [showReset, setShowReset] = useState(false);
  const [name, setName] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageAnalytics, setPageAnalytics] = useState({
    "activeUsers":'',
    "inactiveUsers":'',
    "totalUsers":''
  });
  const [searchParameter, setSearchParameter] = useState({
    'name':'',
    'state':'',
    'userType':'',
  });
  const handleDialogueCLose = () => {
    setDialogue(false)
  }
  const handleDistrictChange = (event) => {
    let selectedValue = event.target.value;
    setDistrict(selectedValue);
  }
  const handleYearFilterChange = (event) => {
    let selectedValue = event.target.value;
    setFilterYear(selectedValue);
  }
  const handleStateChange = (value) => {
    setState(value);
    setSearchParameter((prevSearchParameter) => ({
      ...prevSearchParameter, 
      state: value,
    }));
  };

  const getAllDistricts = async () => { 
    setIsIsLoading(true);
    Axios.get(`api/geo-locations?level=2`)
    .then((response) => {
      setIsIsLoading(false);
      let result = sortDistrictsByName(response?.data?.data)
      setDistrictList(result)
    }).catch((error) => {
      setIsIsLoading(false);
      notify_user(error?.response?.data?.message, "error")
      console.error(error);
    });
  };

  const sortDistrictsByName = (districts) => {
    districts.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
    
    return districts;
  }

  const openDialogue = (response) =>{
    setDialogue(true);
    setDialogueContent(response)
  }

  const handleUserTypeChange = (value) => {
    setUserType(value);
    setSearchParameter((prevSearchParameter) => ({
      ...prevSearchParameter, 
      userType: value,
    }));
  };

  const getAllFilterYears = async () => {
    setIsIsLoading(true);
    setFilterLoading(true); 
    try {
      let years = await getFilterYears(); 
      setFilterYearLists(years);
      let yearsUpdate = getYearPlusOne(years);
      setIsIsLoading(false); 
      setFilterLoading(false); 

    } catch (error) {
      console.error('Failed to fetch roles:', error);
      notify_user("Unable to get filter years", "error")
      setIsIsLoading(false);
      setFilterLoading(false); 
    }
  }

  const getYearPlusOne = (years) => {
    // let maxIdObject = years.reduce((max, year) => {
    //   let currentId = parseInt(year.id);
    //   if (currentId > parseInt(max.id)) {
    //       return year;
    //   } else {
    //       return max;
    //   }
    // }, years[0]);
  
    // let newId = parseInt(maxIdObject.id) + 1;
    // let newYear = {
    //     "id": `${newId}`,
    //     "name": `${newId}`
    // };
    // years.push(newYear);
    // // setFilterYearLists(years);
    // return years;

  } 

  //Sweet Alert
  const showAlert = (id, targetId) => {
      Swal({
        title: 'Warning!',
        text: 'Are you sure you want to delete this Data?',
        icon: 'warning',
        buttons: {
          confirm: {
            text: 'Confirm',
            value: 'confirm',
            className: 'sweet_alert_true',
          },
          extraButton: {
            text: 'cancel',
            value: 'cancel',
            className: 'sweet_alert_false',
          },
        },
      }).then((value) => {
        switch (value) {
          case 'confirm':
            handleDeleteData(id, targetId)
            break;
          case 'cancel':
            // Handle extra button click
            break;
          default:
            // Handle other cases or dismissals
            break;
        }
      });
  };

  const notify_user = (message, toast_type) => {
    if (toast_type == 'success') {
      toast.success(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#d0efd0',
          color:"#000000"
        },
      })
    }else if(toast_type == 'error'){
      toast.error(message, {
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#f2c4c4',
          color:"#000000"
        },
      })
    }else if (toast_type == 'info'){
      toast.info(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#97ddef',
          color:"#000000"
        },
      })
    }
    
  };
  // Delete Users
  const handleDeleteData = (id, targetId) => {
    setLoadingUser(true);
    Axios.delete(`api/delete/epi/data/${id}/${targetId}`)
    .then((response) => {
      notify_user("Deleted successfully", "success")
      getAllEpiPopulation(currentPage);
      setLoadingUser(false);
    }).catch((error) => {
      notify_user("Could not delete data, try later", "error")
      console.error(error);
      setLoadingUser(false);
    }); 
  };

//============================================ Normal Select End=================================================//


  const baseUrl = useContext(BaseUrlContext);
  const autoCompleteEndpoint = `${baseUrl}/users/filter`;

  const handleAutoCompleteChange = (name, value) => {
    setFilterUserValues(value);
  }

  const handleSearchClick = (event) => {
    event.preventDefault();

    setLoadingUser(true);
    let location = "";
    if (state !== "") 
      location = handleFilterForm();

    let active = true;
    if (status !== '') 
      active = (status === 1) ? true : false;

    let prevUrl = localStorage.getItem('searchedUrl');
    if (prevUrl) {
      localStorage.removeItem('searchedUrl')
    }
    let setUrl = `api/users/filter?page=${currentPage - 1}&size=20&name=${name}&active=${active}&location=${location}&userType=${userType}`;
    localStorage.setItem('searchedUrl',setUrl);

    const queryString = JSON.stringify(searchParameter);
    localStorage.setItem('queryString',queryString);

    setIsPending(true);
    getAllEpiPopulation(currentPage); 
      setTimeout(() => {
        setShowReset(true);
        setIsPending(false);
      }, 1200);
  };

  const getAllEpiPopulation = async (page) => { 
    setLoadingUser(true);

     let url = localStorage.getItem('searchedUrl');
      let newUrl; 
        if (url) {
          newUrl = url;
          setShowReset(true);
          let queryString = localStorage.getItem('queryString');
  
          if (queryString) {
            setFilterLoading(true);
            let searchParameters = JSON.parse(queryString); 
            setName(searchParameters.name);
            setState(searchParameters.state);
            setUserType(searchParameters.userType);
            setLG(searchParameters.lga);
            setStatus(searchParameters.status);
            setTimeout(() => {
              setFilterLoading(false);
            }, 500)
            //Setting the url back to default without reloading
            window.history.replaceState({}, document.title, window.location.pathname); 
            setLoadingUser(false);
          }
        } else {
          newUrl = `api/retrieve/epi/data?page=${page - 1}&size=20&year=${filterYear}`;
        }

    Axios.get(newUrl) 
    .then((response) => {
      setLoadingUser(false);
      let results = response?.data?.data?.data;
      let result = sortDistrict(results)
      setPopulationList(response?.data?.data?.data)
      setResponse(response?.data?.data);
      setPageAnalytics(response?.data?.analytics);
      setTotalPages(response?.data?.data?.last_page);
    }).catch((error) => {
      setLoadingUser(false);
      console.error(error);
      const statusCode = error?.response?.status;
      if (statusCode === 401) {
        returnToLogin()
      }
    });
  };
  const sortDistrict = (result) =>{
    result.sort((a, b) => {
      const nameA = a.geolocation.name.toUpperCase();
      const nameB = b.geolocation.name.toUpperCase();
      if (nameA < nameB) {
          return -1;
      }
      if (nameA > nameB) {
          return 1;
      }
      return 0;
  });
  }
  const handlePageChange = (page, event) => {
    setCurrentPage(page);
  }; 
  
  useEffect(() => {
    getAllEpiPopulation(currentPage); 
  }, [currentPage]); 

  useEffect(() => {
    getAllFilterYears();
    getAllDistricts();
  }, []);
 

  const returnToLogin = () => {
    localStorage.clear();
    window.location.href = '/login'
  }
  useEffect(() => {
    let maxYear = getMaxYear();
    setFilterYear(maxYear);
  }, [filterYearList]); 

  useEffect(() => {
    getAllEpiPopulation(currentPage)
  }, [filterYear]); 
  
  const getMaxYear = () => { 
    const yearIds = filterYearList?.map(item => item.id); 
    const highestYear = Math.max(...yearIds);
    
    return highestYear;
  }

  const searchFilter = async (event) => { 
    event.preventDefault();
    return
    setLoadingUser(true);

    let location = "";
    if (state !== "") 
      location = handleFilterForm();
    setLoadingUser(true);
    let active = true;
    if (status !== '') 
      active = (status === 1) ? true : false;

    let userId = [];
    if (filterUserValues != []) 
      userId = filterUserValues[0];
    
    setLoadingUser(true);
    Axios.get(`api/users/search?codes=${userId}&active=${active}&location=${location}&userType=${userType}`)
    .then((response) => {
      setLoadingUser(false);
      setTeamList(response?.data?.content)
      setResponse(response?.data)
      setShowReset(true)
    }).catch((error) => {
      setLoadingUser(false);
      console.error(error);
    });
  };

  const handleFilterForm = () =>{
    let  location = '';
    if (state !== '') 
      location = state;
    
    // if (state !== '' && lg !== '') 
    //   location = lg;

    // if (state !== '' && filterLg !== '' && ward !== '') 
    //   location = ward;
    return location;
  } 

  const handleEPiPopulationChange = (event) => {
    setEpiPopulation(event.target.value);
  };
  const handleTargetChange = (event) => {
    setTarget(event.target.value);
  };

  const handleUpdate = async (event) => {
    setIsPending(true);
    event.preventDefault();
    
    const formData = {
      populationId:populationId,
      targetId:targetId,
      locationId:district,
      population:epiPopulation,
      target:target,
      year:filterYear
    };
    Axios.put(`api/update/epi/data`, formData)
    .then((response) => {
      setIsPending(false);
      notify_user("Created successfully", 'success');
      handleModalClose();
      getAllEpiPopulation(currentPage);

    }).catch((error) => {
      setIsPending(false);
      notify_user(error?.response?.data?.message, "error")
      console.error(error);
    });
  };

  const handleSubmit = async (event) => {
    setIsPending(true);
    event.preventDefault();
    
    const formDataArray = {
      districtId:district,
      epiPopulation:epiPopulation,
      target:target,
      year:filterYear
    };

    const formData = {
      data:[formDataArray]
    };

    Axios.post(`api/create/epi/data`, formData)
    .then((response) => {
      setIsPending(false);
      notify_user("Created successfully", 'success');
      handleModalClose();
      getAllEpiPopulation(currentPage);
      setTarget('');
      setDistrict('');
      setEpiPopulation('');
      setFilterYear('');
    }).catch((error) => {
      setIsPending(false);
      notify_user(error?.response?.data?.message, "error")
      console.error(error);
    });
  };

  const handleEditClick = (data) => {
    setTarget(data.target); 
    setEpiPopulation(data.population); 
    setDistrict(data.district);
    setFilterYear(data.year);
    setPopulationId(data.populationId);
    setTargetId(data.targetId)
    setEditPopulationModalOpen(true);
  };
  const handleAddClick = (data) => {
    setTarget(''); 
    setEpiPopulation(''); 
    setDistrict('');
    setAddPopulationModalOpen(true);
  };

  const formatNumber = (number)=>{
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  if (filterLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title> EPI Population </title>
      </Helmet>

      {/* <Container> */}
        <ToastContainer/>
        <div className={`${styles.header_container} p-4 d-flex mb-3 text-white`} >
            <img src="/assets/icons/roles_icon.png" alt="icon" />&nbsp;&nbsp;
            <span className="mt-1"> Targets  </span>
        </div>  
        <div className=' p-3'>
          <div className="row">
          <div className="col-md-3">
            <FormControl fullWidth >
              <InputLabel id="select-label">Select Year</InputLabel>
              <Select
                labelId="select-label"
                id="select-box"
                value={filterYear }
                label={"Select Year"}
                onChange={handleYearFilterChange}
                fullWidth
                size="small" 
              > 
                <MenuItem value="">Select</MenuItem> 
                {filterYearList?.map((value) => (
                  <MenuItem key={value.id} value={value.id}>
                    {value.name}
                  </MenuItem>
                ))}  
              </Select>
            </FormControl>
          </div>
            <div className="col-3">
              <Typography variant="h5" gutterBottom>
                {/* Manage Users */}
              </Typography>
            </div>
            <div className="col-4"> 
            </div>
            <div className="col-2">
                  <div className=" d-flex justify-content-end">
                  {hasPermission("CREATE_USER") &&
                    <button 
                      onClick={()=>{handleAddClick()}} 
                      className="btn general_buttons" 
                      type="button"
                    >
                        <img src="/assets/icons/Plus.png" alt="" />
                        &nbsp;
                        Add
                    </button>
                  }
               
              </div>
            </div>
          </div>
          {/* </Stack> */}
        </div>
        <Card className='p-4'>
          
          <Scrollbar>
           
            <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}>
              <table className={`${styles.table} table-hover table-striped`}>
                <thead className={`${styles.thead} text-center`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      return (
                        <th key={i} className="text-muted th">{el.label}</th>
                      )
                    })}

                  </tr>

                </thead>
                {populationList.length === 0 ? (
                  <tbody >
                    <tr >
                      <td colSpan={8}>
                        <p className="alert alert-info mt-3 w-100 text-center" role="alert">
                          No users available.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                ):(
                <tbody>
                {populationList.map((el, i) => {
                      return (
                        <tr key={i} style={{ cursor: 'pointer' }} className={`${styles.tr}`}>
                          <td key={i} className='text-center'>{i+1}</td>
                          <td className='text-center'>
                            {el?.year}
                          </td>
                          <td className='text-center'>
                                {el?.geolocation?.name}
                          </td>
                          <td className='text-center'>
                            {formatNumber(el?.population)}
                          </td>
                          <td className='text-center'>
                            {formatNumber(el?.target)}
                          </td>
                          <td className='text-center'>
                            <div>
                              <span
                                id="dropdownMenuButton" 
                                data-toggle="dropdown" 
                                aria-haspopup="true" 
                                aria-expanded="false"
                                className={`${styles.action_button} dropleft text-center p-3`} style={{
                                  cursor: 'pointer',
                                }}>
                                <img src="/assets/icons/action.svg" alt="" />
                              </span>
                              <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                              {hasPermission("EDIT_USER") &&
                                <button 
                                  type="button" 
                                  onClick={() =>  handleEditClick({
                                    year: el?.year,
                                    district: el?.geolocation?.id,
                                    population: el?.population,
                                    target:el?.target,
                                    targetId:el?.targetId,
                                    populationId:el?.id
                                  })} 
                                  className=" dropdown-item btn border-0">
                                  Edit
                                </button>
                              }
                              {hasPermission("DELETE_USER") &&
                                <button type="button" 
                                  onClick={() => showAlert(el.id, el?.targetId)}
                                  className="dropdown-item btn border-0 text-danger"
                                >
                                  Delete
                                </button>
                              }
                              </div>
                            </div>

                        </td>
                        </tr>
                        
                      )
                    })}

                </tbody>
                )}
              </table>
            </div>
            {loadingUser &&
              <div className='loader'>
                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
              </div>
            }
          </Scrollbar>
          {/* Pagination*/}
          {currentPage &&<Pagination
            page={currentPage}
            totalPages = {totalPages}
            onChange={handlePageChange}
            response = {response}
          /> }
          
        </Card>
      {/* </Container> */}
      

      {/* Edit Population Modal */}
      <Modal
        open={editPopulationModalOpen}
        onClose={handleModalClose}
        callback={handleModalClose}
        borderRadius="20px"
        p="0px"
      >
          <div className='modal_header other_modal_top'>
            <h5  className='other_modal_header_text text-white'>Edit</h5>
            <span className='close_support_modal' onClick={handleModalClose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div> 
          <Card className="p-5">
          <form action="" className="mt-0" onSubmit={handleUpdate}>
                <div className="row">
                  <div className="col-md-6">
                  <FormControl fullWidth >
                    <InputLabel id="select-label">Select Year</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select-box"
                      value={filterYear }
                      label={"Select Year"}
                      onChange={handleYearFilterChange}
                      fullWidth
                      size="small" 
                    > 
                      <MenuItem value="">Select</MenuItem> 
                      {filterYearList?.map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}  
                    </Select>
                  </FormControl>
                  </div>
                  <div className="col-md-6 mb-4"> 
                  <FormControl fullWidth >
                    <InputLabel id="select-label">Select District</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select-box"
                      value={district}
                      label={"Select District"}
                      onChange={handleDistrictChange}
                      fullWidth
                      size="small" 
                      // required
                    > 
                      <MenuItem value="">Select</MenuItem> 
                      {districtList?.map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}  
                    </Select>
                  </FormControl> 
                  </div> 
              
                  <div className="col-md-6 mb-4">
                      <TextField 
                          label="Epi Population"
                          variant="outlined"
                          fullWidth
                          name="epiPopulation"
                          value={epiPopulation}
                          onChange={handleEPiPopulationChange}
                          type={'text'}
                          // required
                      />
                  </div>
                  <div className="col-md-6 mb-1">
                      <TextField 
                          label="Target"
                          variant="outlined"
                          fullWidth
                          name="target"
                          value={target}
                          title="Target" 
                          type={'text'}
                          onChange={handleTargetChange} 
                      />
                  </div> 
                </div> 
                <div className="mb-4 mt-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button type="button" className={`btn btn-block buttons_outline`} onClick={()=>handleModalClose()}>
                        Close
                    </button>
                    <button type="submit" className={`btn btn-block buttons`}>
                        Update &nbsp; &nbsp;
                        {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                    </button>
                </div>
            </form>
          </Card>      
       
      </Modal> 

      {/* Add Population Modal */}
      <Modal
        open={addPopulationModalOpen}
        onClose={handleModalClose}
        callback={handleModalClose}
        borderRadius="20px"
        p="0px"
      >
          <div className='modal_header other_modal_top'>
            <h5  className='other_modal_header_text text-white'>Add</h5>
            <span className='close_support_modal' onClick={handleModalClose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div> 
          <Card className="p-5">
          <form action="" className="mt-0" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                  <FormControl fullWidth >
                    <InputLabel id="select-label">Select Year</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select-box"
                      value={filterYear }
                      label={"Select Year"}
                      onChange={handleYearFilterChange}
                      fullWidth
                      size="small" 
                    > 
                      <MenuItem value="">Select</MenuItem> 
                      {filterYearList?.map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}  
                    </Select>
                  </FormControl>
                  </div>
                  <div className="col-md-6 mb-4"> 
                  <FormControl fullWidth >
                    <InputLabel id="select-label">Select District</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select-box"
                      value={district}
                      label={"Select District"}
                      onChange={handleDistrictChange}
                      fullWidth
                      size="small" 
                      // required
                    > 
                      <MenuItem value="">Select</MenuItem> 
                      {districtList?.map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}  
                    </Select>
                  </FormControl> 
                  </div> 
              
                  <div className="col-md-6 mb-4">
                      <TextField 
                          label="Epi Population"
                          variant="outlined"
                          fullWidth
                          name="epiPopulation"
                          value={epiPopulation}
                          onChange={handleEPiPopulationChange}
                          type={'text'}
                          // required
                      />
                  </div>
                  <div className="col-md-6 mb-1">
                      <TextField 
                          label="Target"
                          variant="outlined"
                          fullWidth
                          name="target"
                          value={target}
                          title="Target" 
                          type={'text'}
                          onChange={handleTargetChange} 
                      />
                  </div> 
                </div> 
                <div className="mb-4 mt-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button type="button" className={`btn btn-block buttons_outline`} onClick={()=>handleModalClose()}>
                        Close
                    </button>
                    <button type="submit" className={`btn btn-block buttons`}>
                        Add &nbsp; &nbsp;
                        {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                    </button>
                </div>
            </form>
          </Card>      
       
      </Modal> 
      
    </>
  );
}
