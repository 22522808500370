import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel'; 

function MyCheckbox(prop) {
  const rolePermissions = prop?.rolePermissions || [];

  const handleChange = (event) => {
    prop.onCheckboxChange(event.target.checked, prop?.label?.alias);
  };
  const getIsChecked = () =>{
    return prop?.checked ? prop?.checked : rolePermissions.includes(prop?.label?.alias)
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          key={prop?.label?.id}
          checked={getIsChecked()}
          onChange={handleChange}
          color="primary"
        />
      }
      label={prop?.label?.name}
    />
  );
}


export default MyCheckbox;
