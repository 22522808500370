// import React from "react";
import {React, useState, useEffect } from 'react';
import Modal from "@mui/material/Modal";
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import emitter from 'src/emitter.js';
import { useMediaQuery } from 'react-responsive';


const UniversalModal = (props) => {
  const { open, onClose, children } = props;
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Adjust the breakpoint as needed

  const styles = {
    position: props.position ?? 'absolute',
    top: props.top ?? '50%',
    left: props.left ?? '50%',
    transform: props.transform ?? 'translate(-50%, -50%)',
    width: props.width ?? '740px !important',
    height: props.height,
    overflow:props.overflow,
    bgcolor:props.bgcolor ?? '#F5F5F5',
    border: props.border ?? '1px solid #F5F5F5',
    borderRadius:props.borderRadius ?? '5px',
    boxShadow: props.boxShadow ?? '24',
    p: props.p ??'25px',
    minHeight: props.minHeight,
    background: props.background

  }
  // const [title, setTitle] = useState("Add modal title");
  const [modalOpen, setModalOpen] = useState(false);
  // useEffect(() => {
  //   setTitle(props.title);
  // }, [props.title]);

  const handleClose = () => {
    setModalOpen(false);
    // handleCallback();
  };
  // useEffect(() => {emitter.on('close-modal', () => {
  //   setModalOpen(false)
  //   });
  // }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box className={isMobile ? 'mobile_modal_size' : ''} sx={styles}>
        <div className='modal_content'>

          {/* <div className='modal_header'>
            <h5  className='modal_header_text'>{title}</h5>
            <span onClick={handleClose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div> */}

          {children}
          </div>
          


        </Box>

      </Fade>
    </Modal>
  );
};

export default UniversalModal;
