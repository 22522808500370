import PropTypes from 'prop-types';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import Modal from "src/Modals/Modal";
import TextField from '../../components/inputs/Textbox';
import TextArea from '../../components/inputs/TextArea';
import { Card } from '@mui/material';
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
// import axios from 'axios';
import React, { useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { permissions, hasPermission } from '../../Authorization';
import Axios from "../../axiosInstance";
import AxiosUploadFile from "../../axiosFileInstance";



// @mui
import { Box, List, ListItemText } from '@mui/material';
// import { NavLink as RouterLink, useNavigate } from 'react-router-dom';

//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [file, setFile] = useState('');
  const baseUrl = useContext(BaseUrlContext);
  const fileInputRef = useRef(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(true);
  var auth = JSON.parse(localStorage.getItem('auth'));
  const id = auth?.id;
  const email = auth?.email;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const currentLink = window.location.href;
  const regex = /(?<=dashboard\/).+/;
  const link_text = currentLink?.match(regex);
  const result = link_text[0]?.toString()

  const collateTextValue = (event) => {
    const { name, value } = event;
    switch (name) {
      case "subject":
        setSubject(value);
      case "content":
        setContent(value);
      default:
        break;
    }
  };

  // const hasPermission = (permission) =>{
  //   return permissions.includes(permission)
  // }
  
  const handleModalClose = () => {
    setSupportModalOpen(false);

  };
  const [currentPage, setCurrentPage] = useState('')
  const navigate = useNavigate();
  const goto = (e) => {
    // navigate(d)
    setCurrentPage(e)
  };
  // useEffect(() => { 
  //   console.log(result);
  // }, [result]);
  
  

  const notify_user = (message, toast_type) => {
    // console.log(message, toast_type);
    if (toast_type == 'success') {
      toast.success(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#d0efd0',
          color:"#000000"
        },
      })
    }else if(toast_type == 'error'){
      toast.error(message, {
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#f2c4c4',
          color:"#000000"
        },
      })
    }else if (toast_type == 'info'){
      toast.info(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#97ddef',
          color:"#000000"
        },
      })
    }
    
  };

  

  const handleSubmit = async (event) => {
    event.preventDefault();

    const files = fileInputRef.current.files;
    const formData = new FormData();
  
    for (let i = 0; i < files.length; i++) {
      formData.append('attachment', files[i]);
    }

    formData.append('message', content);
    formData.append('subject', subject);
    // formData.append('email', email);
    // formData.append('lastName', lastName);
    // formData.append('firstName', firstName);

    AxiosUploadFile.post(`api/support`, formData)
    .then((response) => {
      notify_user("Message sent successfully, Admin will reach out to you!", "success")
      setContent('');
      setSubject('');
    }).catch((error) => {
      notify_user("Try again later ", "error")
      console.error(error);
    });
    handleModalClose()
  };

  const getUserDetails = async () => { 
    Axios.get(`api/users/${id}`)
    .then((response) => {
      setFirstName(response.data.firstName);
      setLastName(response.data.lastName);
      setLoading(false);
    }).catch((error) => {
      console.error(error);
      setLoading(false);
    });
  };

  useEffect(() => {
    getUserDetails()
  }, []);


  const chooseFile = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...files]);
  };

  const handleLinkClick = (e) => {
    e.preventDefault(); 
    window.location.href = data[4].path;
  };
  const reset = () =>{
    localStorage.setItem('searchedUrl', '');
    localStorage.setItem('queryString', '');
  }

  return (
    <Box {...other}>
       <ToastContainer />
      <List disablePadding sx={{ p: 1 }}>
        <a 
          href={data[0].path}
          rel="noopener noreferrer"
          className={`homepage ${'app' === result ? 'active' : ''}`} 
          // onClick={()=>goto('app')}
          // onClick={() => setCurrentPage("dashboard")}
        >
          <img height="30px" src="/assets/illustrations/nav_homepage_icon.png" alt="Icon" />
          &nbsp;
          Dashboard
        </a>
        <section className='mt-4'>
          {/* <div className='administrative'>
            <img src="/assets/illustrations/campaign_logo.png" alt="Icon" />
            &nbsp;
            Campaign Setup
          </div> */}
          {hasPermission("VIEW_CONSUMPTION") &&
              <a 
                href={data[4].path} 
                rel="noopener noreferrer"
                className={`navItems navLink ${"consumption" === result ? 'active' : ''}`}
                onClick={()=>reset()}
              >
                Consumption
              </a>
            }
          <br />
          {hasPermission("VIEW_COVERAGE") &&
              <a
                href={data[3].path} 
                rel="noopener noreferrer"
                className={`navItems navLink ${"coverage" === result ? 'active' : ''}`}
                onClick={()=>reset()} 
              > 
                  Coverage
              </a>
            }
            <br />
            
            {hasPermission("VIEW_STOCKOUT") &&
              <a
                href={data[5].path} 
                rel="noopener noreferrer"
                className={`navItems navLink ${"stockout" === result ? 'active' : ''}`}
                onClick={()=>reset()} 
              > 
                  Stockout
              </a> 
            }
        </section>
        {/* Campaign Setup*/}
        {/* <section className='mt-4'>
        {hasPermission("CREATE_TEAM_TYPE") || hasPermission("CREATE_PROGRAM") || (hasPermission("CREATE_HCW_ROLES") || hasPermission("VIEW_CAMPAIGN")) ? (
          <div className='administrative'>
            <img src="/assets/illustrations/campaign_logo.png" alt="Icon" />
            &nbsp;
            Campaign Setup
          </div>
          ) : ''}
          {hasPermission("CREATE_TEAM_TYPE") &&
            <a 
              href={data[7].path}
              rel="noopener noreferrer"
              className={`navItems navLink ${"team_type" === result ? 'active' : ''}`}
              onClick={()=>reset()}
            >
              Team Type
            </a>
          }
          <br />
          {hasPermission("CREATE_PROGRAM") &&
            <a
              href={data[8].path} 
              rel="noopener noreferrer"
              className={`navItems navLink ${"program" === result ? 'active' : ''}`}
              onClick={()=>reset()} 
            > 
                Program
            </a>
          }
          {hasPermission("CREATE_HCW_ROLES") &&
            <a
              href={data[9].path} 
              rel="noopener noreferrer"
              className={`navItems navLink ${"hcw_roles" === result ? 'active' : ''}`}
              onClick={()=>reset()} 
            > 
                HCW Roles
            </a>
          }
          {hasPermission("VIEW_CAMPAIGN") &&
            <a
              href={data[10].path} 
              rel="noopener noreferrer"
              className={`navItems navLink ${"campaign" === result ? 'active' : ''}`}
              onClick={()=>reset()} 
            > 
                Campaign
            </a>
          }

        </section> */}

        {/* Team Management */}
        {/* <section className='mt-4'>
          <div className='administrative'>
            <img src="/assets/illustrations/team_management_icon.png" alt="Icon" />
            &nbsp;
            Team Management
          </div>
          {hasPermission("VIEW_TEAM") &&
            <a 
              href={data[5].path}
              rel="noopener noreferrer"
              className={`navItems navLink ${"vaccination_teams" === result ? 'active' : ''}`}
              onClick={()=>reset()}
            >
              Vaccination Team
            </a>
          }
          <br />
          {hasPermission("VIEW_HCW") &&
            <a
              href={data[6].path} 
              rel="noopener noreferrer"
              className={`navItems navLink ${"hcw" === result ? 'active' : ''}`}
              onClick={()=>reset()}
            >
                HCW
            </a>
          }
          {hasPermission("VIEW_RECONCILIATION") &&
            <a
              href={data[11].path} 
              rel="noopener noreferrer"
              className={`navItems navLink ${"reconciliation" === result ? 'active' : ''}`}
              onClick={()=>reset()}
            >
                Reconciliation
            </a>
          }

        </section> */}


        {/* Administration */}
        <section>
          {hasPermission("VIEW_USER") || hasPermission("VIEW_ROLE") ? (
            <div className='administrative text-white'>
              <img style={{height:"25px"}} src="/assets/illustrations/settings.svg" alt="Icon" />
              &nbsp;
              Administrative
            </div>
          ) : ''}
          {hasPermission("VIEW_USER") &&
            <a 
              href={data[1].path}
              rel="noopener noreferrer"
              className={`navItems navLink  ${"user" === result ? 'active' : ''}`}
              onClick={()=>reset()}
            >
              Users
            </a>
          } 
          {hasPermission("VIEW_ROLE") &&
            <a 
              href={data[2].path}
              rel="noopener noreferrer"
              className={`navItems navLink ${"roles" === result ? 'active' : ''}`}
              onClick={()=>reset()}
            >
              Roles
            </a>
          }
          <br />
          {hasPermission("VIEW_ROLE") &&
            <a 
              href={data[6].path}
              rel="noopener noreferrer"
              className={`navItems navLink ${"epi_population" === result ? 'active' : ''}`}
              onClick={()=>reset()}
            >
              Target Setup
            </a>
          }
          <br />
          {/* {hasPermission("VIEW_FACILITY") && */}
            {/* <a 
              href={data[2].path}
              rel="noopener noreferrer"
              className={`navItems navLink ${"facility" === result ? 'active' : ''}`}
              onClick={()=>reset()}
            >
              Facilities
            </a> */}
          {/* } */}
          <br />
          
          
        </section>

        {/* <p className='mt-5 mb-3 help'>Need Help?</p> */}
        {/* <div className=' support_icon'
          onClick={()=>{setSupportModalOpen(true)}} 
        >
          <img height="45px" src="/assets/illustrations/support.png" alt="" />
        </div> */}

        {/* {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))} */}
      </List>
      <Modal
        open={supportModalOpen}
        onClose={handleModalClose}
        title="Add Multiple User"
        callback={handleModalClose}
        borderRadius="20px"
        p="0px"
      >
          <div className='modal_header support_modal_top'>
            <h5  className='support_modal_header_text'>Have any question? We're happy to help</h5>
            <span className='close_support_modal' onClick={handleModalClose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div> 
          <Card>
            <form action="" className="mt-0 support_form" onSubmit={handleSubmit}>
              <div>
                <TextField 
                    name="subject"
                    title="Subject" 
                    value={subject}
                    required
                    onValueChange={collateTextValue} 
                />
              </div>
              {/* <div className='mt-4'>
                <TextField 
                    name="email"
                    value={email}
                    title="Email" 
                    type="email"
                    required
                    onValueChange={collateTextValue} 
                />
              </div> */}
              <div className='mt-4'>
                <TextArea 
                    value={content}
                    name="content"
                    placeholder="Please, type your message here"
                    onValueChange={collateTextValue}
                    minRows='5'
                />
              </div>
              <div className="row mt-4">
                <small style={{fontWeight:'600'}}>
                  Attachments
                </small>
                <div className="col-12 col-md-4">
                  <input 
                    type="file" 
                    name="file" 
                    id="fileInput" 
                    style={{display: 'none'}} 
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    multiple
                  />
                  <button 
                    className='supportAttachment p-3'
                    onClick={chooseFile}
                    type="button"
                  >
                    <img src="/assets/icons/attachment_icon.png" alt="" />
                    &nbsp;  &nbsp;  &nbsp;
                    <span className='text-center'>
                      Upload your file
                    </span>
                  </button>
                  {selectedFiles.length > 0 &&  (
                    <div>
                    {selectedFiles.map((file, index) => (
                      <span key={index} style={{color:'#3c8dbc'}}>{file.name},&nbsp;</span> 
                    ))}
                    </div>
                  )}
                </div>
                <div className="col-md-4 hide_on_mobile mb-3"></div>
                <div className="col-12 col-md-4">
                  <button type="submit" className={` btn btn-block modal_btn p-3`}>
                    Send 
                  </button>
                </div>
              </div>
            </form>
          </Card>
      </Modal>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
          border:'1px solid black'
        },
        '&:hover': {
          color: '#3c8dbc',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
