import { useRef, useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

HC_exporting(Highcharts);

const DistrictColumnChart = (props) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!props.data || props.data.length === 0) {
      return;
    }

    const categories = props.data.map(entry => entry.district);
    const seriesData = props.data.map(entry => ({
      name: entry.district,
      y: entry.percentage,
      numerator: entry.numerator,
      denominator: entry.denominator,
      percentage: entry.percentage
    }));

    const customColors = ['#3c8dbc', '#1EB53A','#f5c700', '#222d32', '#f7a35c', '#8085e9', '#f15c80'];

    const options = {
      chart: {
        type: 'column'
      },
      title: {
        text: props.title,
        style: {
          fontSize: '15px',           
          fontWeight: 'bold',
          color: '#333',
          textAlign: 'center',
          margin: '20px 0',
          fontFamily: 'josefin-sans-header, sans-serif, Arial',
        },
      },
      xAxis: {
        categories: categories,
        crosshair: true,
        accessibility: {
          description: 'Districts'
        },
        gridLineWidth: 1,
        gridLineColor: '#e0e0e0', 
        gridLineDashStyle: 'Dot', 
        tickmarkPlacement: 'on',
        tickLength: 10,
        tickWidth: 1, 
        lineColor: '#000',
        lineWidth: 1
      },
      yAxis: {
        title: {
          text: 'Percentage (%)'
        }
      },
      tooltip: {
        formatter: function () {
          const point = this.point;
          const tooltipText = `<b>${point.category}</b><br/>
                               Numerator: ${point.numerator}<br/>
                               Denominator: ${point.denominator}<br/>
                               Percentage: ${point.percentage}%`;
          return tooltipText;
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          color: '#3c8dbc',
          // colorByPoint: true,
          dataLabels: {
            enabled: true,
            format: '{point.y}%' 
          }
        }
      },
      series: [{
        name: 'Districts',
        data: seriesData,
        // colors: customColors
      }],
      credits: {
        enabled: false
      },
      legend: {
        layout: 'horizontal', 
        align: 'center',
        verticalAlign: 'bottom',
        itemMarginTop: 10,
        itemMarginBottom: 10,
        borderWidth: 1
        // layout: 'vertical', 
        // align: 'right',
        // verticalAlign: 'top',
        // y: 50,  
        // itemMarginTop: 10,
        // itemMarginBottom: 10,
        // borderWidth: 1
      },
    };

    if (chartRef.current) {
      Highcharts.chart(chartRef.current, options);
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.innerHTML = ''; 
      }
    };
  }, [props.data, props.title]);

  return <div ref={chartRef} style={{ height: '474px' }}></div>;
};

export default DistrictColumnChart;

// import { useRef, useEffect } from 'react';
// import Highcharts from 'highcharts';
// import HC_exporting from 'highcharts/modules/exporting';
// import HighchartsReact from 'highcharts-react-official';

// HC_exporting(Highcharts);

// const DistrictColumnChart = (props) => {
//   const chartRef = useRef(null);

//   useEffect(() => {
//     if (!props.data || props.data.length === 0) {
//       return;
//     }

//     const categories = props.data.map(entry => entry.district);
//     const seriesData = props.data.map(entry => ({
//       name: entry.district,
//       y: entry.percentage,
//       numerator: entry.numerator,
//       denominator: entry.denominator,
//       percentage: entry.percentage
//     }));

//     const customColors = ['#3c8dbc', '#1EB53A', '#222d32', '#f7a35c', '#8085e9', '#f15c80'];

//     const options = {
//       chart: {
//         type: 'column'
//       },
//       title: {
//         text: props.title,
//       },
//       xAxis: {
//         categories: categories,
//         crosshair: true,
//         accessibility: {
//           description: 'Districts'
//         },
//         gridLineWidth: 1,
//         gridLineColor: '#e0e0e0', 
//         gridLineDashStyle: 'Dot', 
//         tickmarkPlacement: 'on',
//         tickLength: 10,
//         tickWidth: 1, 
//         lineColor: '#000',
//         lineWidth: 1
//       },
//       yAxis: {
//         title: {
//           text: 'Percentage (%)'
//         }
//       },
//       tooltip: {
//         formatter: function () {
//           const point = this.point;
//           const tooltipText = `<b>${point.category}</b><br/>
//                                Numerator: ${point.numerator}<br/>
//                                Denominator: ${point.denominator}<br/>
//                                Percentage: ${point.percentage}%`;
//           return tooltipText;
//         }
//       },
//       plotOptions: {
//         column: {
//           pointPadding: 0.2,
//           borderWidth: 0,
//           dataLabels: {
//             enabled: true,
//             format: '{point.y}%' 
//           }
//         }
//       },
//       series: [{
//         name: 'Districts',
//         data: seriesData
//       }],
//       colors: customColors,
//       credits: {
//         enabled: false
//       },
//       legend: {
//         layout: 'horizontal',
//         // symbolWidth: 20, 
//         // symbolHeight: 20,
//         align: 'center',
//         verticalAlign: 'bottom',
//         itemMarginTop: 10,
//         itemMarginBottom: 10,
//         borderWidth: 1
//       },
//     };

//     if (chartRef.current) {
//       Highcharts.chart(chartRef.current, options);
//     }

//     return () => {
//       if (chartRef.current) {
//         chartRef.current.innerHTML = ''; 
//       }
//     };
//   }, [props.data, props.title]);

//   return <div ref={chartRef} style={{ height: '474px' }}></div>;
// };

// export default DistrictColumnChart;
