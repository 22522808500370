import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import mapData from 'highcharts/modules/map';
import heatmap from 'highcharts/modules/heatmap';
mapData(Highcharts);
heatmap(Highcharts);

const MapChart = ({ data, title }) => {
  const [options, setOptions] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the map data
        const mapResponse = await fetch('https://code.highcharts.com/mapdata/countries/sl/sl-all.topo.json');
        const mapData = await mapResponse.json();

        // Fetch your data
        const year = Object.keys(data)[0];
        const formattedData = data[year]
          .filter(item => item.longitude && item.latitude)
          .map(item => ({
            x: parseFloat(item.longitude),
            y: parseFloat(item.latitude),
            value: item.total_girls,
            location: item.location
          }));

        setOptions({
          chart: {
            map: mapData,
            type: 'map',
            height: 600,
            margin: [60, 20, 80, 20],
            spacingBottom: 100
          },
          title: {
            text: title,
            align: 'center'
          },
          credits: {
            enabled: false
          },
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            y: 40,
            floating: true,
            itemMarginTop: 10,
            itemMarginBottom: 10,
            borderWidth: 1
          },
          colorAxis: {
            min: 0,
            max: Math.max(...formattedData.map(d => d.value)),
            minColor: 'rgba(0, 34, 255, 0.1)',
            maxColor: 'rgba(0, 34, 255, 1)',
            stops: [
              [0, 'rgba(0, 34, 255, 0.1)'],
              [0.5, 'rgba(0, 34, 255, 0.5)'],
              [1, 'rgba(0, 34, 255, 1)']
            ]
          },
          mapNavigation: {
            enabled: true,
            buttonOptions: {
              verticalAlign: 'bottom'
            }
          },
          mapView: {
            projection: {
              name: 'WebMercator'
            },
            center: [10, 58],
            zoom: 2.8
          },
          series: [{
            type: 'map',
            name: 'Map',
            mapData: mapData,
            borderColor: 'rgba(0, 0, 0, 0.4)',
            borderWidth: 1,
            nullColor: 'rgba(255, 255, 255, 0)'
          }, {
            type: 'heatmap',
            name: 'Total Girls',
            data: formattedData,
            borderWidth: 0,
            nullColor: 'rgba(255,255,255,0)',
            colsize: 0.6,
            rowsize: 0.6,
            tooltip: {
              pointFormat: 'Location: {point.location}, <br/> Lat: {point.y}, Lon: {point.x}, Value: {point.value}'
            },
            interpolation: {
              enabled: true,
              blur: 8
            }
          }]
        });
      } catch (error) {
        console.error('Error loading map data:', error);
      }
    };

    fetchData();
  }, [data]);

  if (!options) return <div>Loading...</div>;

  return (
    <div id="container" style={{ width: '100%', height: '700px' }}>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'mapChart'}
        options={options}
      />
    </div>
  );
};

export default MapChart;
