const FILE_EXTENSIONS = {
  CSV: 'csv',
  EXCEL: 'xlsx',
  PDF: 'pdf',
  JSON: 'json',
};

const exportNames = {
  HCW: 'Healthcare_Workers',
  RECONCILIATION: 'Reconciliation_List',
  CAMPAIGN: 'Campaign_List',
  TEAM: 'Vaccination_Teams',
  USER: 'User',
};

const getBlobOptions = response => ({ type: response.headers['content-type'] });

const getFilename = (response, exportType, pageName) => {
  const disposition = response.headers['content-disposition'];
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(disposition);
  const exportName = exportNames[pageName] || 'export';
  const fileExtension = FILE_EXTENSIONS[exportType] || 'json';
  return matches !== null && matches[1] ? matches[1].replace(/['"]/g, '') : `${exportName}.${fileExtension}`;
};

const createAndDownloadLink = (blobURL, filename) => {
  const link = document.createElement('a');
  link.href = blobURL;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobURL);
};

const exportDownloader = async (response, exportType, pageName, links = '') => {
  const isCSV = exportType === 'CSV';
  const isEXCEL = exportType === 'EXCEL';
  const isPDF = exportType === 'PDF';

  const blobOptions = getBlobOptions(response);

  const jsonData = JSON.stringify(response.data, null, 2);
  const filename = getFilename(response, exportType, pageName);

  const blob = new Blob(
    (isCSV || isEXCEL || isPDF) ? [response.data] : [jsonData],
    blobOptions
  );
  
  const blobURL = URL.createObjectURL(blob);
  
  if (isEXCEL || isPDF) {
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = filename;

    link.click();

    setTimeout(() => {
      link.remove();
      URL.revokeObjectURL(blobURL);
    }, 100);
  } else {
    createAndDownloadLink(blobURL, filename);
  }
};
export { exportDownloader };
