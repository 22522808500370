import { useState, useContext, useEffect } from 'react';
// @mui
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton,InputAdornment, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';
import { useAuth } from 'src/hooks/useAuth';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import TextField from '../../../components/inputs/Textbox';
import Modal from "src/Modals/Modal";
import axios from 'axios';
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from "../../../axiosInstance"; 
import Iconify from '../../../components/iconify';
import emitter from '../../../emitter/eventEmitter';
import CircularProgress from '@mui/material/CircularProgress';


// import Axios from "../../axiosInstance";

import {
  Card,
} from '@mui/material';

// import { useLocation, useNavigate } from 'react-router-dom';



// ----------------------------------------------------------------------
const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  height:'50px',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  paddingTop:'30px',
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));
const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: 'eva:home-fill',
  // },
  {
    label: 'My Profile',
    icon: 'eva:person-fill',
  },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  // },
];


// ----------------------------------------------------------------------

export default function AccountPopover() {
  const baseUrl = useContext(BaseUrlContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const {dispatch, data} = useAuth();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cNewPassword, setCNewPassword] = useState("");
  const [unmatchedPassword, setUnmatchedPassword] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showCnewPassword, setCnewShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const auth = JSON.parse(localStorage.getItem('auth'));
  const id = auth?auth.id:'';
  const [isPending, setIsPending] = useState(false);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
    setCNewPassword('');
    setNewPassword('');
    setOldPassword('');
  };
  const goToPage=(page) => {
    navigate(page)

  }

  const collateTextValue = (event) => {
    const { name, value } = event;
    switch (name) {
      case "oldPassword":
        setOldPassword(value);
      case "newPassword":
        setNewPassword(value);
      case "cNewPassword":
        setCNewPassword(value);
      default:
        break;
    }
  };

  const [addTeamModalOpen, setAddTeamModalOpen] = useState(false);
  
  const handleModalClose = () => {
    setAddTeamModalOpen(false);
    setCNewPassword('');
    setNewPassword('');
    setOldPassword('');
    setUnmatchedPassword(false);
  };

  const handleSubmit = async (event) => {
    setIsPending(true)
    event.preventDefault();
    if (cNewPassword !== newPassword) {
      setUnmatchedPassword(true);
      setIsPending(false);
    }else{
      const formData ={
        current_password:oldPassword,
        new_password:newPassword,
        new_password_confirmation:cNewPassword,
        user_id:id
      }
      // return console.log(formData, id);
      Axios.put(`api/change-password`, formData)
      .then((response) => { 
        notify_user("Password changed successfully", "success");
        setCNewPassword('');
        setNewPassword('');
        setOldPassword('');
        setIsPending(false);
        setUnmatchedPassword(false);
      }).catch((error) => {
        notify_user("Error: Unable to change password, try later ", "error")
        console.error(error);
        setIsPending(false)
      });
      handleModalClose()
    }
  };

  const notify_user = (message, toast_type) => { 
    if (toast_type == 'success') {
      toast.success(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#d0efd0',
          color:"#000000"
        },
      })
    }else if(toast_type == 'error'){
      toast.error(message, {
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#f2c4c4',
          color:"#000000"
        },
      })
    }else if (toast_type == 'info'){
      toast.info(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#97ddef',
          color:"#000000"
        },
      })
    }
    
  };

  const LogoutUser=async()=>{
    localStorage.clear();
    setTimeout(() => {
      window.location.href = '/login'
    }, 500);
    return 
  }

  

  const getUserDetails = async () => { 
    Axios.get(`api/user/${data?.user?.id}`)
    .then((response) => {
      // return console.log(response?.data)
      setUserDetails(response?.data?.data)
      setLoading(false);
    }).catch((error) => {
      console.error(error);
      notify_user(error?.response?.data?.message, "error")
      setLoading(false);
    });
  };

  useEffect(() => {
    const handleProfileUpdated = () => {
      getUserDetails();
    };

    emitter.on('profileUpdated', handleProfileUpdated);

    return () => {
      emitter.off('profileUpdated', handleProfileUpdated);
    };
  }, [getUserDetails]);

  useEffect(() => {
    getUserDetails();
  }, []);
  
  const settings = {
    photoURL: '/assets/images/avatars/settings_icon.png',
  };
  return (
    <>
      <ToastContainer/>
      {/* <div className="container"> */}
      <span className="align_left">
        <strong> Sierra Leone HPV integrated Dashboard </strong>
      </span>
        <IconButton
          sx={{
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
              },
            }),
          }}
        >
          <Avatar src={account.photoURL } alt="photoURL" />
        </IconButton>
      {/* <StyledAccount sx={{ color: 'text.secondary' }}> */}
        {/* <strong> */} 
          <div className='user_detail_wrapper mr-5' >
            {userDetails.first_name && userDetails.last_name ? userDetails.first_name+" "+userDetails.last_name:"" }
            {/* <p className='admin_badge'>{userDetails.role? userDetails.role.name : ""} ({userDetails?.location?.name}) </p> */}
            <p className='admin_badge'>{userDetails.role? userDetails.role.name : ""}</p>
            {/* - {userDetails?.location?.name} */}
          </div>
        {/* </strong> */}
        {/* </StyledAccount> */}
        
        <IconButton
          onClick={handleOpen}
          sx={{
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                // bgcolor:'#c5e5f7',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          <Avatar src={settings.photoURL } alt="photoURL" />
        </IconButton>

        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: 180,
              backgroundColor:'#c5e5f7',
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
            {userDetails.first_name && userDetails.last_name?userDetails.first_name+" "+userDetails.last_name:"User Name" }
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userDetails.email ? userDetails.email :"No email"}
            </Typography>
          </Box>

          <Divider sx={{ border: '1px solid black' }} />

          <Stack sx={{ p: 1 }}>
            {/* {MENU_OPTIONS.map((option) => ( */}
              <MenuItem onClick={()=>{goToPage('/dashboard/profile')}} >
                Edit Profile
              </MenuItem>
              <MenuItem onClick={()=>{setAddTeamModalOpen(true)}} >
                Update Password
              </MenuItem>
            {/* ))} */}
          </Stack>

          <Divider sx={{ border: '1px solid black' }} />

          <MenuItem onClick={LogoutUser} sx={{ m: 1 }}>
            Logout
          </MenuItem>
        </Popover>
      
      {/* </StyledAccount> */}

      <Modal
        open={addTeamModalOpen}
        onClose={handleModalClose}
        title="Add Multiple User"
        callback={handleModalClose}
        borderRadius="20px"
        p="0px"
      >
          <div className='modal_header other_modal_top'>
            <h5  className='other_modal_header_text text-white'>Update Password</h5>
            <span className='close_support_modal' onClick={handleModalClose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div> 
          <Card>
            <form className="mt-0 support_form" onSubmit={handleSubmit}>
                <div>
                <TextField 
                    name="oldPassword"
                    title="Old Password" 
                    value={oldPassword}
                    type={showOldPassword ? 'text' : 'password'}
                    required
                    onValueChange={collateTextValue} 
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowOldPassword(!showOldPassword)} edge="end">
                            <Iconify icon={showOldPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                />
                </div>
              <div className='mt-4'>
                <TextField 
                    name="newPassword"
                    title="New Password" 
                    value={newPassword}
                    type={showPassword ? 'text' : 'password'}
                    required
                    onValueChange={collateTextValue} 
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                />
              </div>
              <div className='mt-4'>
                <TextField 
                    name="cNewPassword"
                    title="Confirm New Password" 
                    value={cNewPassword}
                    type={showCnewPassword ? 'text' : 'password'}
                    required
                    onValueChange={collateTextValue} 
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setCnewShowPassword(!showCnewPassword)} edge="end">
                            <Iconify icon={showCnewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                />
              </div>
              {
                unmatchedPassword && <div>
                <p className='text-danger' >New password does not match confirm password!</p>
                </div>
              }
              <div className="row mt-4">
                <div className="col-md-8 hide_on_mobile mb-3"></div>
                <div className="col-12 col-md-4">
                  <button type="submit" className={` btn btn-block modal_btn p-3`}>
                    Send &nbsp; &nbsp;
                    {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                  </button>
                </div>
              </div>
            </form>
          </Card>


        {/* </div> */}
       
       
      </Modal>
      {/* </div> */}
    </>
  );
}
