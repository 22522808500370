import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';

import { FormControl,FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';



const MySelectBox = ({ value, onSelect, optionList, title, disabled, required, campaign }) => {
  // const recordValue = title === "User Type" ? value.id : value;
  const [selectedOption, setSelectedOption] = useState(value);

  const handleChange = (event) => {
    let selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onSelect(selectedValue); 
  };


  return (
    <div>
      <FormControl fullWidth
        sx={{
          "& .MuiInputLabel-root": {
            color: "#808080"
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#808080"
            },
            "&:hover fieldset": {
              borderColor: "#808080"
            },
            "&.Mui-focused": {
              "& fieldset": {
                borderColor: "#3c8dbc"
              },
              "& .MuiInputLabel-root": {
                color: "#3c8dbc"
              }
            }
          }
        }}
      >
        <InputLabel id="select-label">Select {title}</InputLabel>
        <Select
          labelId="select-label"
          id="select-box"
          value={selectedOption }
          label={"Select"+title}
          onChange={handleChange}
          fullWidth
          size="small"
          disabled={disabled??false}
          required={required??false}
        > 
          <MenuItem value="">Select</MenuItem>
          {optionList?.map((value) => (
            <MenuItem key={value.id} value={value.id}>
               {campaign ? (value.code ?? '') : (value.name ?? value.title ?? `${value?.firstName} ${value?.middleName ?? ''} ${value?.lastName}`)}
              {/* {value.name ?? value.title ?? `${value?.firstName} ${value?.middleName ?? ''} ${value?.lastName}`} */}
            </MenuItem>
          ))}  
        </Select>
        {/* {(selectedOption === '' && required) && <FormHelperText className='text-danger'>This field is required</FormHelperText>} */}
      </FormControl>
    </div>
  );
};

export default MySelectBox;
