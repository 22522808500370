import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Scrollbar from '../../components/scrollbar'; 
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert';
import styles from "./Stockout.module.css"
import Axios from "../../axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import DonutChart from '../../components/highcharts/DonutChart';
import HeatMapChart from '../../components/highcharts/HeatMapChart';
import DistrictColumnChart from '../../components/highcharts/DistrictColumnChart';
import CountryHeatMap1 from '../../components/highcharts/CountryHeatMap1';
import StackedColumnChart from '../../components/highcharts/StackedColumnChart';
import { permissions, hasPermission } from '../../Authorization'; 
import { FormControl,FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';
import { getFilterYears } from '../../CommonMethods';
import CustomSelect from '../../components/inputs/Select';

import {
    Card,
    Container,
  } from '@mui/material';
import Pagination from '../Common/Pagination';
HC_exporting(Highcharts);

const monthList = [
  { 'id': '01', 'name': 'January' },
  { 'id': '02', 'name': 'February' },
  { 'id': '03', 'name': 'March' },
  { 'id': '04', 'name': 'April' },
  { 'id': '05', 'name': 'May' },
  { 'id': '06', 'name': 'June' },
  { 'id': '07', 'name': 'July' },
  { 'id': '08', 'name': 'August' },
  { 'id': '09', 'name': 'September' },
  { 'id': '10', 'name': 'October' },
  { 'id': '11', 'name': 'November' },
  { 'id': '12', 'name': 'December' }
];

export default function StockoutPage() {  
    const [percentageMonthlyAnalysisTitle, setpercentageMonthlyAnalysisTitle] = useState('');
    const [loading, setIsIsLoading] = useState(false);
    const [filterYear, setFilterYear] = useState('All');
    const [filterYearList, setFilterYearList] = useState([]);
    const [filterMonth, setFilterMonth] = useState('');

    const [isPending, setIsPending] = useState(false);
    const [stockoutDaysData, setDaysStockout] = useState([]);
    const [yearlyInOutVaccinationPerDistrict, setYearlyInOutVaccinationPerDistrict] = useState([]); 
 
    const navigate = useNavigate();
    const goto = (d) => {
      navigate('/dashboard/'+d)
    };

    const handleMonthFilterChange = (event) => {
      let selectedValue = event.target.value;
      setFilterMonth(selectedValue);
    }

    // const handleDistrictChange = (value) => {
    //   setDistrict(value);
    //   setSearchParameter((prevSearchParameter) => ({
    //     ...prevSearchParameter, 
    //     district: value,
    //   }));
    // }; 

    // useEffect(() => {
    //   if (district !== '') {
    //     getAllCouncils();
    //   } 
    //   // if (lg !== '') {
    //   //   getAllWards()
    //   // }
    //   setPreviousState(district);
    // }, [district]); 

    const handleCouncilChange = (value) => {
      setCouncils(value);
      setSearchParameter((prevSearchParameter) => ({
        ...prevSearchParameter, 
        council: value,
      }));
    };
    const getAllCouncils = async () => { 
      setIsIsLoading(true)
      Axios.get(`api/geo-location-children?locationId=${district}`)
      .then((response) => {
        setCouncilList(response?.data?.data);
        setIsIsLoading(false)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false)
      });
    };
    

    const getAllFilterYears = async () => {
      setIsIsLoading(true); 
      try {
        let years = await getFilterYears();
        setFilterYearList(years);
        setIsIsLoading(false);

      } catch (error) {
        console.error('Failed to fetch roles:', error);
        notify_user("Unable to get filter years", "error")
        setIsIsLoading(false);

      }
    } 

    const notify_user = (message, toast_type) => { 
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }else if (toast_type == 'info'){
        toast.info(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#97ddef',
            color:"#000000"
          },
        })
      }
      
    };

    const handleYearFilterChange = (event) => {
      let selectedValue = event.target.value;
      setFilterYear(selectedValue);
    }

 
    const getDaysOutOStockChartData = async () => {
      setIsIsLoading(true); 

      const formData = {
        year:filterYear, //'2023',
        month:filterMonth
      };
      Axios.post(`api/stockout/days-out-of-stock`, formData)
      .then((response) => {
        setIsIsLoading(false);
        setDaysStockout(response?.data?.data)
      }).catch((error) => {
        console.error(error);
        setIsIsLoading(false);
        notify_user("Unable to get data", "error");
      });
    };

   

    useEffect(() => {
      getDaysOutOStockChartData();
      getAllFilterYears();
    }, []);
    
    const getMaxYear = () => { 
      const yearIds = filterYearList?.map(item => item.id); 
      const highestYear = Math.max(...yearIds);
      
      return highestYear;
    }

    useEffect(() => {
      let maxYear = getMaxYear();
      // setFilterYear(maxYear);
    }, [filterYearList]); 

    const harvestYears = () => {
      return filterYearList?.map(item => item.id).join(', ');
    }

    
    

    const handleSearchClick = (event) => {
      event.preventDefault();
        getDaysOutOStockChartData(); 
    };

    return (
      <>
        <Helmet>
          <title> Coverage </title>
        </Helmet>
        
  
        <ToastContainer />
            <div className={`${styles.header_container} p-4 d-flex mb-3`} >
                {/* <img src="/assets/icons/roles_icon.png" alt="icon" />&nbsp;&nbsp; */}
                <span className={`${styles.header_text} text-white`}> Stockouts </span>
            </div>

            <div className="">
              <form action="" onSubmit={handleSearchClick}>  
                <div className="row mb-2 ">
                  <div className="col-md-3">
                    <FormControl fullWidth >
                      <InputLabel id="select-label">Filter by Year</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select-box"
                        value={filterYear }
                        label={"Filter by Year"}
                        onChange={handleYearFilterChange}
                        fullWidth
                        size="small" 
                      > 
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="All">All</MenuItem>
                        {filterYearList?.map((value) => (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}  
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-3">
                    <FormControl fullWidth >
                      <InputLabel id="select-label">Filter by Month</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select-box"
                        value={filterMonth }
                        label={"Filter by Month"}
                        onChange={handleMonthFilterChange}
                        fullWidth
                        size="small" 
                      > 
                        <MenuItem value="">Select</MenuItem>
                        {/* <MenuItem value="All">All</MenuItem> */}
                        {monthList?.map((value) => (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}  
                      </Select>
                    </FormControl>
                  </div>  
                  <div className="col-md-2">
                  <button type="submit" className={` btn modal_btn filter_btns`}>
                    Filter &nbsp; &nbsp;
                    {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                  </button>
                  </div>
                </div> 
              </form>
            </div>
            
            <div className="row">
              <div className="col-md-12">
                <Card className='p-5 mt-3'>
                  <DistrictColumnChart data={stockoutDaysData} title={percentageMonthlyAnalysisTitle} />
                </Card>
              </div>
              
            </div>


      </>
    );
  }