import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useContext } from 'react';
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import { useNavigate } from "react-router-dom";
import Select from '../../components/inputs/Select';
import styles from "./User.module.css";
import Axios from "../../axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-toastify/dist/ReactToastify.css';
import { getRoles, getUsertTypes } from '../../CommonMethods';

import TextField from '../../components/inputs/Textbox';
import {
    Card,
    Container,
  } from '@mui/material';

export default function UserPage() {  
    const baseUrl = useContext(BaseUrlContext);
    const navigate = useNavigate();

  // get values to send to backend
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');
    const [role, setRole] = useState('');
    const [geoZone, setGeoZone] = useState('');
    const [address, setAddress] = useState('');
    const [status, setStatus] = useState("");
    const [userTypeList, setUserTypeList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [state, setState] = useState('');
    const [lga, setLG] = useState('');
    const [facility, setFacility] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [stateList, setStateList] = useState([])
    const [lgaList, setLgaList] = useState([]);
    const [ward, setWard] = useState('');
    const [wardList, setWardList] = useState([]);
    const [nationalList, setNationalList] = useState([]);
    const [wardTeamList, setWardTeamList] = useState([]);
    const [showState, setShowState] = useState(false);
    const [showLga, setShowLga] = useState(false);
    const [showNational, setShowNational] = useState(false);
    const [national, setNational] = useState('');
    const [isPending, setIsPending] = useState(false);
    const handleInputChange = (event, newInputValue) => {
      setInputValue(newInputValue);
    }; 

      const statusList  = [
        { id: 1, name: 'Active' },
        { id: 2, name: 'Inactive' }
      ];

      const collateTextValue = (event) => {
        const { name, value } = event;
        if (name === "firstName") {
          setFirstName(value);
        }else if (name === "lastName") {
          setLastName(value);
        }else if (name === "email") {
          setEmail(value);
        }else if (name === "phoneNumber") {
          setphoneNumber(value);
        }
      };
  
    
    //hide state droplist
    // useEffect(() => {
    //   if (userType !== '') {
    //     var desiredTitle = null;

    //     for (var i = 0; i < userTypeList.length; i++) {
    //         if (userTypeList[i].id === userType) {
    //             desiredTitle = userTypeList[i].title;
    //             break;
    //         }
    //     }
    //     if (desiredTitle.toLowerCase() === "state") {
    //       setShowState(true);
    //       setNational('')
    //     }else{
    //       setShowState(false);
    //     }
    //     if (desiredTitle.toLowerCase() === "national" || desiredTitle.toLowerCase() === "admin") {
    //       setShowNational(true);
    //       setState('');
    //     }else{
    //       setShowNational(false);
    //     }

    //   }
      
    // }, [userType,userTypeList]);
    
    // const handleSubmit = (event) => {
    const handleSubmit = async (event) => {
      setIsPending(true);
      event.preventDefault();
      let active = status === 1 ? true : false;
      let location = null;
      if (state !== '' && national === '') 
        location = state;
      
      if (state === '' && national !== '')
        location = national;
        let emalContent = email.toLowerCase();
      const formData = {
        first_name:firstName,
        last_name:lastName,
        user_type_id:userType,
        email:emalContent,
        phone_no:phoneNumber,
        active,
        role_id:role
      };
      if (state !== '' || national !== '') {
        formData['location']={
          id: location
        }
      }
    
      Axios.post(`api/user`, formData)
      .then((response) => {
        setIsPending(false);
        notify_user("User added successfully", 'success');
        notify_user("Default Password is: password1", 'info');
        goto("user")

      }).catch((error) => {
        setIsPending(false);
        notify_user(error?.response?.data?.message, "error")
        console.error(error);
      });
    };

    const getAllUserTypes = async () => { 
      try {
        let user_types = await getUsertTypes();
        setUserTypeList(user_types);
      } catch (error) {
        console.error('Failed to fetch roles:', error);
      }
    };

    const getRolesData = async () => { 
      try {
        let roles = await getRoles();
        setRoleList(roles);
      } catch (error) {
        console.error('Failed to fetch roles:', error);
      }
    };
    useEffect(() => {
      getAllUserTypes();
      getRolesData();
      // getAllStates();
      // getNational();
    }, []); 

    const notify_user = (message, toast_type) => {
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }else if (toast_type == 'info'){
        toast.info(message, {
          autoClose: 20000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#97ddef',
            color:"#000000"
          },
        })
      }
      
    };
    const goto = (d) => {
      navigate('/dashboard/'+d)
    };

    //get the Data onchange of select
    // useEffect(() => {
    //   if (state !== '') {
    //     getAllLgas()
    //   }
    //   if (lga !== '') {
    //     getAllWards()
    //   }
    //   if (ward !== '') {
    //     getAllFacilities();
    //   }
    //   if (facility !== '') {
    //     getFacilityTeams()
    //   }
      
    // }, [state, lga, ward, facility]); 

    const handleRoleChange = (value) => {
        setRole(value)
      }
    const handleUserTypeChange = (value) => {
        setUserType(value)
    }
    const handleStatusChange = (value) => {
        setStatus(value)
    }
    // const handleLgChange = (lgaValue) => {
    //   setLG(lgaValue)
    // }
    // const getHcwRoles = async (page) => {
    //   Axios.get(`api/health-workers/roles`)
    //   .then((response) => { 
    //     setRoleList(response?.data?.content);
    //   }).catch((error) => {
    //     console.error(error);
    //   });
    // };

    // const getAllStates = async () => { 
    //   Axios.get(`api/geoLocations/level/3?size=50`)
    //   .then((response) => {
    //     setStateList(response?.data?.content)
    //   }).catch((error) => {
    //     console.error(error);
    //   });
    // };

    // const getNational = async () => { 
    //   Axios.get(`api/geoLocations/level/1`)
    //   .then((response) => {
    //     setNationalList(response?.data?.content)
    //   }).catch((error) => {
    //     console.error(error);
    //   });
    // };
  
    // const getAllLgas = async () => { 
    //   Axios.get(`api/geoLocations/${state}/children?size=500`)
    //   .then((response) => {
    //     setLgaList(response?.data?.content)
    //   }).catch((error) => {
    //     console.error(error);
    //   });
    // };
    
    // const getAllWards = async () => { 
    //   Axios.get(`api/geoLocations/${lga}/children`)
    //   .then((response) => {
    //     setWardList(response?.data?.content)
    //   }).catch((error) => {
    //     console.error(error);
    //   });
    // };
  
    // const getFacilityTeams = async (page) => { 
    //   Axios.get(`api/facilities/${facility}/teams`)
    //   .then((response) => {
    //     setWardTeamList(response?.data?.content)
    //     setResponse(response?.data)
    //   }).catch((error) => {
    //     console.error(error);
    //   });
    // };
  
    return (
      <>
        <Helmet>
          <title> Add User </title>
        </Helmet>
        
  
        {/* <Container> */}
        <ToastContainer />
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Add Single User
          </Typography>
        </Stack> */}
        <div className={`${styles.header_container} p-4 d-flex mb-3`} >
            {/* <img src="/assets/icons/roles_icon.png" alt="icon" />&nbsp;&nbsp; */}
                <span className={`${styles.header_text} text-white`}> Add User </span>
            </div>
          <Card className='p-4 light_card_background'>
          <form action="" className="mt-0" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-4">
                    <TextField 
                        name="firstName"
                        title="First Name" 
                        value={firstName}
                        required
                        onValueChange={collateTextValue} 
                    />
                </div>
                <div className="col-md-6 mb-1">
                    <TextField 
                        name="lastName"
                        value={lastName}
                        title="Last Name" 
                        required
                        onValueChange={collateTextValue} 
                    />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-4">
                    <TextField 
                        name="email"
                        title="Email" 
                        value={email}
                        type="email"
                        required
                        onValueChange={collateTextValue} 
                    />
                </div>
                <div className="col-md-6 mb-1">
                    <TextField 
                      name="phoneNumber"
                      value={phoneNumber}
                      title="Phone Number"
                      type="tel"
                      onValueChange={collateTextValue} 
                      required
                    />
                </div>
            
                <div className="col-md-6 mb-4"> 
                     <Select
                        name="userType"
                        title="User Type *"
                        value={userType}
                        optionList={userTypeList}
                        onSelect={handleUserTypeChange}
                        required={true}
                      />
                </div>
                {/* {showNational &&
                  <div className="col-md-6 mb-4">
                    <Select
                      name="national"
                      title="National Location"
                      value={national}
                      optionList={nationalList}
                      onSelect={handleNationalChange}
                    />
                  </div>
                } */}
                {/* {showState &&
                  <div className="col-md-6 mb-4">
                    <Select
                      name="state"
                      title="State"
                      value={state}
                      optionList={stateList}
                      onSelect={handleStateChange}
                    />
                  </div>
                } */}
                {/* {showLga &&
                  <div className="col-md-6 mb-4">
                    <Select
                      name="state"
                      title="State"
                      value={lga}
                      optionList={lgaList}
                      onSelect={handleLgaChange}
                    />
                  </div>
                } */}
                
                {/* <div className="col-md-6 mb-1">
                    <Select
                        name="status"
                        title="Status *"
                        value={status}
                        optionList={statusList}
                        onSelect={handleStatusChange}
                        required={true}
                    />
                </div> */}
              
                <div className="col-md-6 mb-4">
                    <Select
                        name="role"
                        title="Role *"
                        value={role}
                        optionList={roleList}
                        onSelect={handleRoleChange}
                        required={true}
                    />
                </div>
                <div className="col-md-6 mb-1">
                    {/* <Select
                        name="geoZone"
                        title="Location"
                        value={geoZone}
                        optionList={locationList}
                        onSelect={handleGeoZoneChange}
                    /> */}
                </div>
              </div> 
              <div className="mb-4 mt-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button type="button" className={`btn btn-block buttons_outline`} onClick={()=>goto("user")}>
                      Back
                  </button>
                  <button type="submit" className={`btn btn-block buttons`}>
                      Add &nbsp; &nbsp;
                      {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                  </button>
              </div>
            </form>
          </Card>
        {/* </Container> */}

      </>
    );
  }