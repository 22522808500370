// component
import SvgColor from '../../../components/svg-color';
import '../header/sty.css'

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;


const navConfig = [
  {
    title: 'Overview',
    path: '/dashboard/app',
    // icon: icon('ic_analytics'),
  },
  {
    title: 'User',
    path: '/dashboard/user',
  },
  {
    title: 'Roles',
    path: '/dashboard/roles',
  },
  {
    title: 'Coverage',
    path: '/dashboard/coverage',
    // icon: icon('ic_user'),
  },
  {
    title: 'Consumption',
    path: '/dashboard/consumption',
    // icon: icon('ic_home'),
    // icon: icon('ic_cart'),
  },
  {
    title: 'Stockout',
    path: '/dashboard/stockout',
    // icon: icon('ic_user'),
  },
  {
    title: 'Epi Setup',
    path: '/dashboard/epi_population',
    // icon: icon('ic_user'),
  },
  
 
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // }
];

export default navConfig;
