
import { createContext } from "react";

export const BaseUrlContext = createContext();



export function BaseUrlContextProvider({children}) {
    
    const API_BASE_URL = process.env.REACT_APP_BASE_URL+"/api";



    return (
        <BaseUrlContext.Provider value={API_BASE_URL}>
            {children}

        </BaseUrlContext.Provider>
    )
}


;
