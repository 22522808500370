import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const MySelectBox = ({ selectedBank, onSelect, required }) => {
  const [selectedOption, setSelectedOption] = useState(selectedBank);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onSelect(selectedValue); 
  };

  const [banks, setBanks] = useState([]);

    //Getting Bank Lists
    useEffect(() => {
        const fetchBanks = async () => {
        try {
            const response = await axios.get('https://api.Paystack.co/bank');
            const bankList = response.data.data;
            const sortedBanks = bankList.sort((a, b) => a.name.localeCompare(b.name));
            setBanks(sortedBanks);
        } catch (error) {
            console.error('Error fetching banks:', error);
        }
        };

        fetchBanks();
    }, []);

  return (

    <div>
      <FormControl fullWidth
        sx={{
          "& .MuiInputLabel-root": {
            color: "#808080"
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#808080"
            },
            "&:hover fieldset": {
              borderColor: "#808080"
            },
            "&.Mui-focused": {
              "& fieldset": {
                borderColor: "#3c8dbc"
              },
              "& .MuiInputLabel-root": {
                color: "#3c8dbc"
              }
            }
          }
        }}
      >
        <InputLabel id="select-label">Select Bank *</InputLabel>
        <Select
          labelId="select-label"
          id="select-box"
          value={selectedOption}
          label="Select Bank"
          onChange={handleChange}
          fullWidth
          size="small"
          required={required??false}
        >
          <MenuItem value="">Select</MenuItem>
          {banks.map((bank) => (
          <MenuItem key={bank.id} value={bank.code}>
            {bank.name}
          </MenuItem>
        ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MySelectBox;
