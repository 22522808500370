import { styled } from '@mui/material/styles';

const LogoHeader = styled('div')(({ theme }) => ({
  height: "120px !important",
  width: "102% !important",
  backgroundColor: "#ffffff",
  position: "absolute",
  left: "-35px",
  top: "-3px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
  padding: "0",
  overflowX: "hidden !important",

  '@media (max-width: 768px)': {
    /* Styles for mobile devices */
    height: "80px",
    width: "100vw",
    left: "0",
    top: "0"
  },
 " @media (max-width: 1024px)": {
    /* Styles for iPads */
    height: "80px",
    width: "100vw",
    left: "0",
    top: "0"
  }
}));

export default LogoHeader;
