import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';

import styles from "./Facility.module.css"
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'; 
import { useState, useEffect, useContext } from 'react'; 
import Select from '../../components/inputs/Select'; 
import CircularProgress from '@mui/material/CircularProgress';
import { permissions, hasPermission } from '../../Authorization';
import Axios from "../../axiosInstance";
import Pagination from '../Common/Pagination_old';
import Stack from '@mui/material/Stack';
import TextField from '../../components/inputs/Textbox';


// @mui
import {
  Card,
  // Stack,
  Container,
  Typography,
  Grid,
} from '@mui/material';
import {
  InnerAppAnalytics,
} from '../../sections/@dashboard/app';

import Scrollbar from '../../components/scrollbar';

import { BaseUrlContext } from 'src/context/BaseUrlContext2';



const TABLE_HEAD = [
  { id: 'sn', label: '#', alignRight: false },
  { id: 'facility_code', label: 'Facility Code', alignRight: false },
  { id: 'facility_name', label: 'Facility Name', alignRight: false },
  { id: 'state', label: 'State', alignRight: false },
  { id: 'lga', label: 'Lga', alignRight: false },
  { id: 'ward', label: 'Ward', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false }
];

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

// ----------------------------------------------------------------------

export default function UserPage() {  
  const navigate = useNavigate();

// get values to send to backend
  const [state, setState] = useState('');
  const [lg, setLG] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [facilityList, setFacilityList] = useState([])
  const [response, setResponse] = useState([])
  const [loadingUser, setLoadingUser] = useState(false)
  const [ward, setWard] = useState("");
  const [wardList, setWardList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [lgaList, setLgaList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPending, setIsPending] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [status, setStatus] = useState("");
  const [filterLoading, setFilterLoading] = useState(false);

  const statusList  = [
    { id: 1, name: 'Active' },
    { id: 2, name: 'Inactive' },
  ];
  const [searchParameter, setSearchParameter] = useState({
    'name':'',
    'state':'',
    'lga':'',
    'ward':'',
    'code':'',
    'status':'',
  });
  const [pageAnalytics, setPageAnalytics] = useState({
    "activeFacilities":'',
    "inactiveFacilities":'',
    "totalFacilities":''
  }); 

  const handleStateChange = (stateValue) => {
    setState(stateValue);
    setSearchParameter((prevSearchParameter) => ({
      ...prevSearchParameter, 
      state: stateValue,
    }));
  }
  const handleWardChange = (zoneValue) => {
    setWard(zoneValue);
    setSearchParameter((prevSearchParameter) => ({
      ...prevSearchParameter, 
      ward: zoneValue,
    }));
  }
  
  const handleLgChange = (lgaValue) => {
    setLG(lgaValue);
    setSearchParameter((prevSearchParameter) => ({
      ...prevSearchParameter, 
      lga: lgaValue,
    }));
  }
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const [previousState, setPreviousState] = useState('');

  const handleFilterForm = () =>{
    let  location = '';
    if (state !== '' && lg === '') 
      location = state;
    
    if (state !== '' && lg !== '') 
      location = lg;

    if (state !== '' && lg !== '' && ward !== '') 
      location = ward;
    return location;
  }
  
  const collateTextValue = (event) => {
    const { name, value } = event;
    if (name === "name") {
      setName(value);
      setSearchParameter((prevSearchParameter) => ({
        ...prevSearchParameter, 
        name: value,
      }));
    }if (name === "code"){
      setCode(value);
      setSearchParameter((prevSearchParameter) => ({
        ...prevSearchParameter, 
        code: value,
      }));
    }
  };
  const handleStatusChange = (value) => {
    setStatus(value);
    setSearchParameter((prevSearchParameter) => ({
      ...prevSearchParameter, 
      status: value,
    }));
  };

  const getAllFacility = async (page) => { 
    
    let url = localStorage.getItem('searchedUrl');
    let newUrl; 
      if (url) {
        newUrl = url;
        setShowReset(true);
        let queryString = localStorage.getItem('queryString');

        if (queryString) {
          setFilterLoading(true);
          let searchParameters = JSON.parse(queryString); 
          setName(searchParameters.name);
          setWard(searchParameters.ward);
          setState(searchParameters.state);
          setLG(searchParameters.lga);
          setStatus(searchParameters.status);
          setCode(searchParameters.code);
          setTimeout(() => {
            setFilterLoading(false);
          }, 500)
          //Setting the url back to default without reloading
          window.history.replaceState({}, document.title, window.location.pathname); 
          setLoadingUser(false);
        }
      } else {
        newUrl = `api/facilities/filter?page=${page - 1}&size=20`;
      }
    Axios.get(newUrl)
    .then((response) => {
      setLoadingUser(false);
      let data = response?.data?.data?.content.sort((a, b) => a.name.localeCompare(b.name));

      setFacilityList(data);
      setResponse(response?.data?.data);
      setPageAnalytics(response?.data?.analytics);
      setFilterLoading(false);
    }).catch((error) => {
      setLoadingUser(false);
      setFilterLoading(false);
      console.error(error);
    });
  };

  useEffect(() => {
    getAllFacility(currentPage);
  }, [currentPage]); 

  const goto = (d) => {
    navigate('/dashboard/'+d)
  };

  const handleSearchClick = (event) => {
    event.preventDefault();
    setIsPending(true);
    setLoadingUser(true);

    let location = "";
    if (state !== "") 
      location = handleFilterForm(); 
    let active = "";
    if (status !== '') 
    active = (status === 1) ? true : false;

    let prevUrl = localStorage.getItem('searchedUrl');
    if (prevUrl) {
      localStorage.removeItem('searchedUrl')
    }
    let setUrl = `api/facilities/filter?page=${currentPage - 1}&size=20&location=${location}&name=${name}&code=${code}&active=${active}`;
    localStorage.setItem('searchedUrl',setUrl);

    const queryString = JSON.stringify(searchParameter);
    localStorage.setItem('queryString',queryString);

    getAllFacility(currentPage);  
    setTimeout(() => {
      setShowReset(true);
      setIsPending(false);
    }, 1200);
  };

  const getAllStates = async () => { 
    setLoadingUser(true)
    Axios.get(`api/geoLocations/level/3?size=50`)
    .then((response) => {
      setStateList(response?.data?.content)
      setLoadingUser(false)
    }).catch((error) => {
      console.error(error);
      setLoadingUser(false);
    });
  };

  const getAllLgas = async () => {
    setLoadingUser(true) 
    Axios.get(`api/geoLocations/${state}/children?size=500`)
    .then((response) => {
      // setLG("");
      setLgaList(response?.data?.content)
      setLoadingUser(false)
    }).catch((error) => {
      setLoadingUser(false);
      console.error(error);
    });
  };
  
  const getAllWards = async () => { 
    setLoadingUser(true)
    Axios.get(`api/geoLocations/${lg}/children`)
    .then((response) => {
      setWardList(response?.data?.content)
      setLoadingUser(false)
    }).catch((error) => {
      setLoadingUser(false);
      console.error(error);
    });
  };

  useEffect(() => {
    if (state !== '') {
      getAllLgas()
    }

    // if (state !== previousState) {
    //   setLG('');
    //   setLgaList([]);
    //   setWard('');
    //   setWardList([]);
    // }

    if (lg !== '') {
      getAllWards()
    }
    setPreviousState(state);
  }, [state, lg]); 

  useEffect(() => {
    getAllStates();
  }, []); 
  
  const reset = () =>{
    localStorage.setItem('searchedUrl', '');
    localStorage.setItem('queryString', '');
    window.location.reload();
  } 

  const showAlert = (id) => {
    Swal({
      title: 'Warning!',
      text: 'Are you sure you want to delete this Facility?',
      icon: 'warning',
      buttons: {
        confirm: {
          text: 'Confirm',
          value: 'confirm',
          className: 'sweet_alert_true',
        },
        extraButton: {
          text: 'cancel',
          value: 'cancel',
          className: 'sweet_alert_false',
        },
      },
    }).then((value) => {
      switch (value) {
        case 'confirm':
          handleDeleteFacility(id)
          break;
        case 'cancel':
          // Handle extra button click
          break;
        default:
          // Handle other cases or dismissals
          break;
      }
    });
  };
  // const handleModalClose = () => {
  //   setAddTeamModalOpen(false);
  //   setSelectedFiles([]);
  // };
  const notify_user = (message, toast_type) => {
    if (toast_type == 'success') {
      toast.success(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#d0efd0',
          color:"#000000"
        },
      })
    }else if(toast_type == 'error'){
      toast.error(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#f2c4c4',
          color:"#000000"
        },
      })
    }else if (toast_type == 'info'){
      toast.info(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#97ddef',
          color:"#000000"
        },
      })
    }
    
  };

  const handleDeleteFacility = async (id) => {
    setLoadingUser(true);
    Axios.delete(`api/facilities/${id}`)
    .then((response) => {
      notify_user("Facility deleted successfully", "success")
      getAllFacility(currentPage)
      setLoadingUser(false)
    }).catch((error) => {
      console.error(error);
      notify_user(error?.response?.data?.message, "error")
      setLoadingUser(false)
    });
    // handleModalClose()
  };

  if (filterLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Helmet>
        <title> Facility | VTCD </title>
      </Helmet>

      <Container>
        <div className={`${styles.card_background}  p-2`} >
          Facilities Overview
        </div>
        <div className={`p-3 ${styles.card_background1} mb-4`}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <InnerAppAnalytics title="Total Facilities" total={pageAnalytics.totalFacilities} color="success"/>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InnerAppAnalytics total={pageAnalytics.activeFacilities} title="Active" color="success" />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InnerAppAnalytics  total={pageAnalytics.inactiveFacilities} title="Inactive" color="success"/>
              </Grid>
            </Grid>
        </div>
        {/* Card Analytics Ends*/}
        <div className=' p-3 mt-3'>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h5" gutterBottom>
              Manage Facilities
            </Typography>

            <div className=" d-flex justify-content-end">
            {hasPermission("CREATE_FACILITY") &&
              <button 
                type="button" 
                className="btn general_buttons" 
                onClick={()=>{goto("add_facility")}} 
              >
                  <img src="/assets/icons/Plus.png" alt="" />
                  &nbsp;
                  New Facility
              </button>
            }
            </div>
          </Stack>
        </div>
        
        <Card className='p-4'>
          
          <Scrollbar>
            {/* Fiter*/}
            <form action="" onSubmit={handleSearchClick}>
              <div className="row pt-2">
                <div className="col-md-3">
                  <div className="mb-3 ">
                      <TextField 
                        name="name"
                        value={name}
                        title="Facility Name"
                        onValueChange={collateTextValue} 
                      />
                    </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3 ">
                      <TextField 
                        name="code"
                        value={code}
                        title="Facility Code"
                        onValueChange={collateTextValue} 
                      />
                    </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-4">
                      <Select
                        name="state"
                        title="State"
                        value={state}
                        optionList={stateList}
                        onSelect={handleStateChange}
                      />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="mb-4">
                      <Select
                        name="lga"
                        title="Lga"
                        value={lg}
                        optionList={lgaList}
                        onSelect={handleLgChange}
                      />
                  </div>

                </div>
                <div className="col-md-3">
                  <div className="mb-4">
                    <Select
                        name="ward"
                        title="Ward"
                        value={ward}
                        optionList={wardList}
                        onSelect={handleWardChange}
                      />
                  </div>
                </div>
                
                <div className={`col-md-3 ${styles.mobile_margin_top}`}>
                  <div className="mb-3">
                      <Select
                          name="status"
                          title="Status"
                          value={status}
                          optionList={statusList}
                          onSelect={handleStatusChange}
                      />
                  </div>
                </div>
                <div className="col-md-4">
                  <button type="submit" className={` btn modal_btn filter_btns`}>
                    Search &nbsp; &nbsp;
                    {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                  </button>
                  {
                    showReset && 
                    <span style={{paddingLeft:"15px"}}>
                      <button type="button" onClick={reset} className={`  filter_btns btn btn-warning`}>
                        Reset 
                      </button>
                    </span>
                  }
                </div>
              </div>
            </form>
            {/* Fiter Ends*/}
            <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}>
              <table className={`${styles.table} table-hover table-striped`}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      return (
                        <th key={i} className="text-muted th">{el.label}</th>
                      )
                    })}

                  </tr>

                </thead>
                {facilityList.length === 0 ? (
                  <tbody >
                    <tr >
                      <td colSpan={8}>
                        <p className="alert alert-info mt-3 w-100 text-center" role="alert">
                          No Facility available.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                ):(
                <tbody>
                {facilityList.map((el, i) => {
                      return (
                        <tr key={i} style={{ cursor: 'pointer' }} className={`${styles.tr}`}>
                          <td key={i}>{i+1}</td>
                          <td>
                            {el.code}
                          </td>
                          <td>
                                {el.name}
                          </td>
                          <td>
                            {el.stateName}
                          </td>
                          <td>
                            {el.lgaName}
                          </td>
                          <td>
                            {el.geoLocation.name}
                          </td>
                          <td>
                            {
                              el.active ? (
                              <span className={"badge_success"}>
                                Active
                              </span>):(
                                <span className={"badge_danger"}>
                                Inactive
                              </span>
                              )
                            }
                          </td>
                          <td>
                            <div>
                              <span
                                id="dropdownMenuButton" 
                                data-toggle="dropdown" 
                                aria-haspopup="true" 
                                aria-expanded="false"
                                className={`${styles.action_button} dropleft text-center p-3`} style={{
                                  cursor: 'pointer',
                                }}>
                                <img src="/assets/icons/action.svg" alt="" />
                              </span>
                              <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                              {hasPermission("EDIT_FACILITY") &&
                                <button 
                                  type="button" 
                                  onClick={() => goto("edit_facility?id=" + el.id)} 
                                  className=" dropdown-item btn border-0">
                                  Edit
                                </button>
                              }{hasPermission("DELETE_FACILITY") &&
                              <button 
                                type="button"
                                onClick={() => showAlert(el.id)} 
                                // onClick={() => goto("edit_facility?id=" + el.id)} 
                                className=" dropdown-item btn border-0 text-danger">
                                Delete
                              </button>
                            }
                              </div>
                            </div>

                        </td>
                        </tr>
                        
                      )
                    })}

                </tbody>
                )}
              </table>
            </div>
            {loadingUser &&
              <div className='loader'>
                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
              </div>
            }
          </Scrollbar>
          {/* Pagination*/}
            <Pagination
              response = {response}
              count={response?.totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />  
        </Card>
      </Container>


    </>
  );
}
