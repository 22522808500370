import { NavLink as RouterLink, useNavigate } from 'react-router-dom';

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { connect } from 'react-redux';
import Iconify from '../../../components/iconify';

import { login } from '../../../actions/authActions';


// ----------------------------------------------------------------------

import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import toast from 'react-hot-toast';

import { useContext, useState } from "react"
import { BaseUrlContext } from 'src/context/BaseUrlContext';
import axios from 'axios';
import { useAuth } from 'src/hooks/useAuth';

export default function LoginForm() {
  const navigate = useNavigate();
  const { dispatch } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signUpError, setSignUpError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const baseUrl = useContext(BaseUrlContext);
  const [showText, setShowText] = useState(false);
  const notify = () => toast.success('login successful .');
  const notifyError = () => toast.error("That didn't work please try again later .");
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + '/' + dd + '/' + yyyy;

  

  const handleLogin = () => {
    const credentials = {
      email,
      password
    };

    const fetchData = async () => {
      try {
        setIsPending(true)
        setSignUpError(null)

        // Making the API request
        const res = await axios({
          method: "POST",
          url: `${baseUrl}/merchant/login`,
          data: credentials,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        notify();
        localStorage.setItem('auth', JSON.stringify(res.data.data));
        localStorage.setItem('dl', today);
        dispatch({ type: 'LOGIN', credentials: res.data.data })





        // if (!res.data.data.verified_email) {
        //   navigate('/verify_email');
        //   return;
        // }
        // if (!res.data.data.onboarded) {
        //   navigate('/onboarding');
        //   return;
        // }
        setTimeout(() => {
          window.location.href = 'dashboard/app'
          // window.location.href = '/app/dashboard'

        }, 2000);
      } catch (err) {
        // return console.log(err);
        notifyError()
        setSignUpError(err.response.data.message)
        if (['Email is not verified yet'].includes(err.response.data.message)) {
          localStorage.setItem('e', credentials.email)
          setTimeout(() => {
            navigate('/verify_email');
          }, 1000);

        }

      } finally {
        setIsPending(false)
      }
    };
    fetchData(true);

  };
 

  return (
    <>
      <Stack spacing={3}>
        <TextField 
          name="email" 
          label="Email address" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin}>
        Login
      </LoadingButton>
    </>
  );
}
