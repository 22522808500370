import { useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

HC_exporting(Highcharts);

const R23LineChart = (props) => {
  const { title, data } = props;

  // Transform data into series format expected by Highcharts
  const seriesData = data.map(item => ({
    name: item.district,
    y: item.percentage
  }));

  const options = {
    chart: {
      type: 'line',
    },
    title: {
      text: title,
      style: {
        fontSize: '15px',           
        fontWeight: 'bold',
        color: '#333',
        textAlign: 'center',
        margin: '20px 0',
        fontFamily: 'josefin-sans-header, sans-serif, Arial',
      },
    },
    yAxis: {
      title: {
        text: 'Percentage (%)',
      },
      labels: {
        formatter: function () {
          return this.value + '%';
        }
      }
    },
    xAxis: {
      categories: data.map(item => item.district),
      accessibility: {
        rangeDescription: `Range: Districts`,
      },
      gridLineWidth: 1,
      gridLineColor: '#e0e0e0', 
      gridLineDashStyle: 'Dot', 
      tickmarkPlacement: 'on',
      tickLength: 10,
      tickWidth: 1, 
      lineColor: '#000',
      lineWidth: 1
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.series.name}</b><br/>${this.x}: ${this.y}%`;
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Percentage',
      data: seriesData,
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      }],
    },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default R23LineChart;
