import Axios from "./axiosInstance";



export const getRoles = async () => { 
  try {
    const response = await Axios.get(`api/role`);
    return response?.data?.data?.data;
  } catch (error) {
    console.error(error);
  } 
};

export const getUsertTypes = async () => { 
  try {
    const response = await Axios.get(`api/userTypes`);
    return response?.data?.data;
  } catch (error) {
    console.error(error);
  } 
};

export const getFilterYears = async () => {
  try {
    const response = await Axios.get(`api/target-years`);
    return response?.data?.data;
  } catch (error) {
    console.error(error);
    return error;
  } 
}