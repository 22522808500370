import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useContext } from 'react';
import Scrollbar from '../../components/scrollbar';
import { useNavigate } from "react-router-dom";
import styles from "./Roles.module.css"
import Axios from "../../axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';

import CheckBox from '../../components/inputs/CheckBox';
import TextField from '../../components/inputs/Textbox';
import {
    Card,
    Container
  } from '@mui/material';

  const TABLE_HEAD = [
    { id: 'Permission', label: 'Permission', alignRight: false },
    { id: 'Create', label: '', alignRight: false },
    { id: 'Edit', label: '', alignRight: false },
    { id: 'Delete', label: '', alignRight: false },
  ];


export default function UserPage() {  
    const navigate = useNavigate();
    const [roleName, setRoleName]= useState('');
    const [roleDescription, setRoleDescription]= useState('');
    const [rolePermissions, setRolePermissions] = useState([]);
    const [permissionList, setPermissionList] = useState([]);
    const [response, setResponse] = useState([])
    const [loading, setLoading] = useState(true);
    const [loadingUser, setLoadingUser] = useState(false)
    const [groupedData, setGroupedData] = useState([])
    const [isPending, setIsPending] = useState(false);
    const[selectAll, setSelectAll] = useState(false);
    const[permissionCount, setPermissionCount] = useState([]);
    const collateTextValue = (event) => {
      const { name, value } = event;
      switch (name) {
        case "roleName":
            setRoleName(value);
        case "roleDescription":
            setRoleDescription(value);
        default:
          break;
      } 
    };

    const handleSubmit = async (event) => {
      setIsPending(true)
      event.preventDefault();
      // let aliases = [];
      // // return console.log(rolePermissions)
      // rolePermissions.forEach((element) => {
      //   const aliasObject = {
      //     alias: element,
      //   };
      //   aliases.push(aliasObject);
      // });

      const formData = {
        description:roleDescription,
        name:roleName,
        permissions:rolePermissions //aliases
      };
      Axios.post(`api/role`, formData)
      .then((response) => {
        setIsPending(false)
        notify_user("Role added successfully", "success")
        goto("roles")
      }).catch((error) => {
        setIsPending(false)
        notify_user(error.response.data.message, "error")
        console.error(error);
      });
    };

    const notify_user = (message, toast_type) => {
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }else if (toast_type == 'info'){
        toast.info(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#97ddef',
            color:"#000000"
          },
        })
      }
      
    };

    const handleCheckboxChange = (isChecked, id) => {
      if (isChecked) {
          setRolePermissions((previous)=>[...previous, id]);
      } else {
          setRolePermissions((previous)=>previous.filter(value => value !== id));
      }
    };
    
    const goto = (d) => {
        navigate('/dashboard/'+d)
      };

    const getPermission = async (page) => { 
      setLoadingUser(true);
      Axios.get(`api/permission?size=1000`)
      .then((response) => {
        setLoadingUser(false);
        setPermissionList(response?.data?.data)
        setResponse(response?.data?.data)
        setLoading(false)
        setPermissionCount(response?.data?.data?.length)

        groupData(response?.data?.data)
      }).catch((error) => {
        setLoadingUser(false);
        setLoading(false)
        console.error(error);
      });
    };
    useEffect(() => {
      getPermission();
    }, []); 

    const groupData = (permissionList) =>{
      const mygroupedData = permissionList.reduce((acc, permission) => {
        const { entity_name, name, id, alias } = permission;
        if (!acc[entity_name]) {
          acc[entity_name] = [];
        }
        acc[entity_name].push({ id, name, alias });
        return acc;
      }, {});

      
      setGroupedData(mygroupedData)

    }

    if (loading) {
      return <div>Loading...</div>;
    }
    

    const handleSelectAllChange = () => {
      const updatedGroupedData = { ...groupedData };
      for (const key in updatedGroupedData) {
        if (Array.isArray(updatedGroupedData[key])) {
          updatedGroupedData[key].forEach((obj) => {
            obj.isChecked = !obj.isChecked; 
            handleCheckboxChange(obj.isChecked, obj.alias); 
          });
        }
      }
      setGroupedData(updatedGroupedData);
      setSelectAll(!selectAll)
    };
    
    
    return (
      <>
        <Helmet>
          <title> Add Roles </title>
        </Helmet>
        
  
        {/* <Container> */}
        <ToastContainer />
            <div className={`${styles.header_container} p-4 d-flex mb-3`} >
                <span className={`${styles.header_text} text-white`}> Add New Role </span>
            </div>
            <Card className='p-5 pt-3 light_card_background'>
              <form action="" className="mt-5" onSubmit={handleSubmit}>
                  <div className="row">
                      <div className="col-md-6 mb-4">
                          <TextField 
                              name="roleName"
                              title="Role Name" 
                              value={roleName}
                              onValueChange={collateTextValue} 
                              required
                          />
                      </div>
                      <div className="col-md-6 mb-1">
                          <TextField 
                              name="roleDescription"
                              value={roleDescription}
                              title="Role Description" 
                              onValueChange={collateTextValue} 
                              required
                          />
                      </div>
                  </div>
                  <div className='col-md-12 mb-3' key="select-all-checkbox">
                    {/* checked */}
                    <CheckBox
                      label={{
                        id: 1,
                        name: 'Select All',
                        alias: 'cb1',
                      }}
                      checked={permissionCount === rolePermissions.length}
                      onCheckboxChange= {handleSelectAllChange}
                    /> 
                  </div>
                  <div className='border_bottom mb-3' style={{color:"#575151"}}>
                      <h5>
                          Permissions
                      </h5>
                  </div>
                  <Scrollbar>
                      <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}>
                          <table className={`${styles.table} table-hover table-striped`}>
                              <thead className={`${styles.thead}`}>
                                  <tr>
                                      {TABLE_HEAD.map((el, i) => {
                                      return (
                                          <th key={i} className="text-muted th">{el.label}</th>
                                      )
                                      })}
                                  </tr>
                              </thead>
                              {groupedData.length === 0 ? (
                              <tbody >
                                  <tr >
                                  <td colSpan={8}>
                                      <p className="alert alert-info mt-3 w-100 text-center" role="alert">
                                      No Permission/Role available.
                                      </p>
                                  </td>
                                  </tr>
                              </tbody>
                              ):(
                              <tbody>
                                {Object.entries(groupedData).map(([group, items]) => (
                                  <tr key={group} style={{ cursor: 'pointer' }} className={styles.tr}>
                                    <td className='text-black' colSpan="2">{group}</td>
                                    <td>
                                      <div className="row text-black">
                                      {items.map((item) => (
                                        <div className='col-md-4' key={item.id}>
                                          <CheckBox label={item} rolePermissions={rolePermissions}  onCheckboxChange={handleCheckboxChange} />
                                        </div>
                                      ))}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>

                              )}
                          </table>
                      </div>
                      {loadingUser &&
                          <div className='loader'>
                              <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
                          </div>
                      }
                  </Scrollbar>
                  <div className="mb-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <button type="button" className={`btn btn-block buttons_outline`} onClick={()=>goto("roles")}>
                          Back
                      </button>
                      <button type="submit" className={`btn btn-block buttons`}>
                          Add &nbsp; &nbsp;
                        {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                      </button>
                  </div>

              </form>
            </Card>
        {/* </Container> */}

      </>
    );
  }