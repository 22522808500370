import { useRef, useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

HC_exporting(Highcharts);

const RegionalColumnChart = (props) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!props.data || Object.keys(props.data).length === 0) {
      return;
    }

    const years = Object.keys(props.data);

    const seriesData = years.map(year => ({
      name: year,
      data: props.data[year].map(entry => ({
        y: entry.percentage,
        numerator: entry.numerator,
        denominator: entry.denominator,
        region: entry.region
      }))
    }));

    const allRegions = years.reduce((acc, year) => {
      props.data[year].forEach(entry => {
        if (!acc.includes(entry.region)) {
          acc.push(entry.region);
        }
      });
      return acc;
    }, []);

    const customColors = ['#3c8dbc', '#1EB53A', '#f5c700', '#222d32', '#f7a35c', '#8085e9', '#f15c80'];

    const options = {
      chart: {
        type: 'column'
      },
      title: {
        text: props.title,
        style: {
          fontSize: '15px',           
          fontWeight: 'bold',
          color: '#333',
          textAlign: 'center',
          margin: '20px 0',
          fontFamily: 'josefin-sans-header, sans-serif, Arial',
        },
      },
      xAxis: {
        categories: allRegions, 
        crosshair: true,
        accessibility: {
          description: 'Regions'
        },
        gridLineWidth: 1,
        gridLineColor: '#e0e0e0', 
        gridLineDashStyle: 'Dot', 
        tickmarkPlacement: 'on',
        tickLength: 10,
        tickWidth: 1, 
        lineColor: '#000',
        lineWidth: 1
      },
      yAxis: {
        title: {
          text: 'Percentage (%)'
        }
      },
      tooltip: {
        formatter: function () {
          return `<b>${this.x}</b><br/>` +
                 `Year: ${this.series.name}<br/>` +
                 `Numerator: ${this.point.numerator}<br/>` +
                 `Denominator: ${this.point.denominator}<br/>` +
                 `Percentage: ${this.y}%`;
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y}%',
            style: {
              textOutline: 'none' 
            }
          }
        }
      },
      series: seriesData,
      colors: customColors,
      credits: {
        enabled: false
      },
      legend: {
        layout: 'horizontal',
        // symbolWidth: 20, 
        // symbolHeight: 20,
        align: 'center',
        verticalAlign: 'bottom',
        itemMarginTop: 10,
        itemMarginBottom: 10,
        borderWidth: 1
        // layout: 'vertical', 
        // align: 'right',
        // verticalAlign: 'top',
        // y: 50,  
        // itemMarginTop: 10,
        // itemMarginBottom: 10,
        // borderWidth: 1
      },

    };

    if (chartRef.current) {
      chartRef.current.chart = Highcharts.chart(chartRef.current, options);
    }

    return () => {
      if (chartRef.current && chartRef.current.chart) {
        chartRef.current.chart.destroy();
      }
    };
  }, [props.data, props.title]);

  return <div ref={chartRef} style={{ height: '500px' }} />;
};

export default RegionalColumnChart;
