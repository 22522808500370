import React from 'react';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useState, useEffect, useContext } from 'react';

const LongTextArea = (props) => {
    const [textValue, setTextValue] = useState('');

    const handleTextChange = (event) => {
        const value = event.target.value;
        setTextValue(value);
        props.onValueChange(event.target)
    };
  return (
    <TextareaAutosize
        name={props.name}
        label={props.title}
        variant="outlined"
        value={textValue}
        onChange={handleTextChange}
        required={props.required??false}
        aria-label="minimum height"
        minRows={props.minRows??3}
        placeholder={props.placeholder}
        style={{
            width: '100%',
            padding: '8px',
            fontFamily: 'Arial, sans-serif',
            fontSize: '14px',
            border: '1px solid #ccc',
            borderRadius: '4px',
        }}
        sx={{
            "& .MuiInputLabel-root": {
              color: "#808080"
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#808080"
              },
              "&:hover fieldset": {
                borderColor: "#808080"
              },
              "&.Mui-focused": {
                "& fieldset": {
                  borderColor: "#3c8dbc"
                },
                "& .MuiInputLabel-root": {
                  color: "#3c8dbc"
                }
              }
            }
          }}
    />
  );
};

export default LongTextArea;
