
import { createContext } from "react";

export const BaseUrlContext = createContext();



export function BaseUrlContextProvider2({children}) {
    
    const API_BASE_URLs = process.env.REACT_APP_BASE_URL+"/api";

    return (
        <BaseUrlContext.Provider value={API_BASE_URLs}>
            {children}
        </BaseUrlContext.Provider>
    )
}

;
