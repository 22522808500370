import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import 'bootstrap/dist/css/bootstrap.min.css';

const ExportMenu = ({ tabledataPerDistrict, yearMonth }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    const readyCsvData = transformDataForCSV(tabledataPerDistrict);
    setCsvData(readyCsvData);
  }, [tabledataPerDistrict]);

  const transformDataForCSV = (data) => {
    return data.map((el, i) => {
      const target = el?.target || 0;
      const outSchoolPercentage = target === 0 ? 0 : Math.round((el?.outSchool / target) * 100);
      const inSchoolPercentage = target === 0 ? 0 : Math.round((el?.inSchool / target) * 100);
      const remaining = target - (el?.outSchool + el?.inSchool);
      const remainingPercentage = target === 0 ? 0 : Math.round((remaining / target) * 100);

      return {
        sn: i + 1,
        district: el.district,
        outSchool: el.outSchool.toLocaleString(),
        inSchool: el.inSchool.toLocaleString(),
        outSchoolPercentage: outSchoolPercentage + '%',
        inSchoolPercentage: inSchoolPercentage + '%',
        target: el.target.toLocaleString()
      };
    });
  };

  const headers = [
    { label: "S. No", key: "sn" },
    { label: "District", key: "district" },
    { label: "Out of School", key: "outSchool" },
    { label: "In School", key: "inSchool" },
    { label: "Out School Percentage", key: "outSchoolPercentage" },
    { label: "In School Percentage", key: "inSchoolPercentage" },
    { label: "Target", key: "target" }
  ];

  const exportPDF = () => {
    const doc = new jsPDF();
    const tableColumn = headers.map(el => el.label);

    const tableRows = tabledataPerDistrict.map((el, i) => {
      const target = el?.target || 0;
      const outSchoolPercentage = target === 0 ? 0 : Math.round((el?.outSchool / target) * 100);
      const inSchoolPercentage = target === 0 ? 0 : Math.round((el?.inSchool / target) * 100);
      const remaining = target - (el?.outSchool + el?.inSchool);
      const remainingPercentage = target === 0 ? 0 : Math.round((remaining / target) * 100);

      return [
        i + 1,
        el.district,
        el.outSchool.toLocaleString(),
        el.inSchool.toLocaleString(),
        outSchoolPercentage + '%',
        inSchoolPercentage + '%',
        target.toLocaleString()
      ];
    });

    doc.setFontSize(14);
    
    // Get the document width
    const docWidth = doc.internal.pageSize.getWidth();
    const period = yearMonth ? ","+yearMonth:'';
    const text = 'National HPV Coverage In and Out Schools breakdown per district'+period;
    const textWidth = doc.getTextWidth(text);

    // Calculate X coordinate to center the text
    const x = (docWidth - textWidth) / 2;
    
    doc.text(text, x, 22);

    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
    });

    doc.save('HPV_coverage.pdf');
};


  const exportPNG = () => {
    const input = document.getElementById('tableToExport');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'HPV_coverage.png';
      link.click();
    });
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="mb-3">
      <DropdownToggle className="bg-transparent border-0 p-0">
        <img height={'20px'} src="/assets/icons/bars.svg" alt="bars"/>
      </DropdownToggle>
      <DropdownMenu left style={{fontSize:"14px"}}>
        <DropdownItem onClick={exportPDF}>Download PDF document</DropdownItem>
        <DropdownItem>
          <CSVLink data={csvData} headers={headers} filename="HPV_coverage.csv" className="text-dark" style={{textDecoration:'none'}}>
            Download CSV file
          </CSVLink>
        </DropdownItem>
        <DropdownItem onClick={exportPNG}>Download PNG image</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ExportMenu;
