import { Helmet } from 'react-helmet-async'; 
import { useState, useEffect, useContext, useRef } from 'react';
import Autocomplete from '../../components/inputs/Autocomplete'; 
import Select from '../../components/inputs/Select';
import styles from "./User.module.css" 
import { useNavigate } from "react-router-dom";
import Axios from "../../axiosInstance";
import FileAxios from "../../axiosFileInstance";
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { permissions, hasPermission } from '../../Authorization';
import Pagination from '../Common/Pagination';

import Stack from '@mui/material/Stack';
import { exportDownloader } from '../../utils/exportUtils';


import TextField from '../../components/inputs/Textbox';
// import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import emitter from 'src/emitter.js';

import Modal from "src/Modals/Modal";
// @mui
import {
  Card,
  Container,
  Typography,
  Grid,
} from '@mui/material';
import {
  InnerAppAnalytics,
} from '../../sections/@dashboard/app';
// components
import Scrollbar from '../../components/scrollbar';

const TABLE_HEAD = [
  { id: 'sn', label: '#', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'team', label: 'Phone No', alignRight: false },
  { id: 'userType', label: 'User Type', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];


export default function UserPage() {

  const navigate = useNavigate();
  const goto = (d) => {
    navigate('/dashboard/'+d)
  };
 

  
  const handleModalClose = () => {
    setAddUserModalOpen(false);
    setSelectedFiles([]);
  };

//============================================ AUTO COMPLETE=================================================//

  const [state, setState] = useState([]);
  const [lg, setLG] = useState([]);
  const [userType, setUserType] = useState("");
  const [userList, setUserList] = useState([])
  const [response, setResponse] = useState([])
  const [stateList, setStateList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);
  const [filterUserValues,setFilterUserValues] = useState([]);
  const [status, setStatus] = useState("");
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dialogue, setDialogue] = useState(false);
  const [dialogueContent, setDialogueContent] = useState('');
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false)
  const csvDownloadBaseUrl = process.env.REACT_APP_CSV_TEMPLATE_BASE_URL;
  const [currentPage, setCurrentPage] = useState(1);
  const [showReset, setShowReset] = useState(false);
  const [name, setName] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageAnalytics, setPageAnalytics] = useState({
    "activeUsers":'',
    "inactiveUsers":'',
    "totalUsers":''
  });
  const [searchParameter, setSearchParameter] = useState({
    'name':'',
    'state':'',
    'userType':'',
  });
  const handleDialogueCLose = () => {
    setDialogue(false)
  }
  const handleStateChange = (value) => {
    setState(value);
    setSearchParameter((prevSearchParameter) => ({
      ...prevSearchParameter, 
      state: value,
    }));
  };

  const openDialogue = (response) =>{
    setDialogue(true);
    setDialogueContent(response)
  }

  const handleUserTypeChange = (value) => {
    setUserType(value);
    setSearchParameter((prevSearchParameter) => ({
      ...prevSearchParameter, 
      userType: value,
    }));
  };

  //Sweet Alert
  const showAlert = (id) => {
      Swal({
        title: 'Warning!',
        text: 'Are you sure you want to delete this User?',
        icon: 'warning',
        buttons: {
          confirm: {
            text: 'Confirm',
            value: 'confirm',
            className: 'sweet_alert_true',
          },
          extraButton: {
            text: 'cancel',
            value: 'cancel',
            className: 'sweet_alert_false',
          },
        },
      }).then((value) => {
        switch (value) {
          case 'confirm':
            handleDeleteUser(id)
            break;
          case 'cancel':
            // Handle extra button click
            break;
          default:
            // Handle other cases or dismissals
            break;
        }
      });
  };

  const notify_user = (message, toast_type) => {
    if (toast_type == 'success') {
      toast.success(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#d0efd0',
          color:"#000000"
        },
      })
    }else if(toast_type == 'error'){
      toast.error(message, {
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#f2c4c4',
          color:"#000000"
        },
      })
    }else if (toast_type == 'info'){
      toast.info(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#97ddef',
          color:"#000000"
        },
      })
    }
    
  };
  // Delete Users
  const handleDeleteUser = (id) => {
    setLoadingUser(true);
    Axios.delete(`api/user/${id}`)
    .then((response) => {
      notify_user("User deleted successfully", "success")
      getAllUsers(currentPage);
      setLoadingUser(false);
    }).catch((error) => {
      notify_user("Could not delete User, try later", "error")
      console.error(error);
      setLoadingUser(false);
    }); 
  };

//============================================ Normal Select End=================================================//


  const baseUrl = useContext(BaseUrlContext);
  const autoCompleteEndpoint = `${baseUrl}/users/filter`;

  const handleAutoCompleteChange = (name, value) => {
    setFilterUserValues(value);
  }

  const handleSearchClick = (event) => {
    event.preventDefault();

    setLoadingUser(true);
    let location = "";
    if (state !== "") 
      location = handleFilterForm();

    let active = true;
    if (status !== '') 
      active = (status === 1) ? true : false;

    let prevUrl = localStorage.getItem('searchedUrl');
    if (prevUrl) {
      localStorage.removeItem('searchedUrl')
    }
    let setUrl = `api/users/filter?page=${currentPage - 1}&size=20&name=${name}&active=${active}&location=${location}&userType=${userType}`;
    localStorage.setItem('searchedUrl',setUrl);

    const queryString = JSON.stringify(searchParameter);
    localStorage.setItem('queryString',queryString);

    setIsPending(true);
    getAllUsers(currentPage); 
      setTimeout(() => {
        setShowReset(true);
        setIsPending(false);
      }, 1200);
  };
  const getAllUsers = async (page) => { 
    setLoadingUser(true);
    

    // let userId = [];
    // if (filterUserValues !== []) 
    //   userId = filterUserValues[0];
     let url = localStorage.getItem('searchedUrl');
      let newUrl; 
        if (url) {
          newUrl = url;
          setShowReset(true);
          let queryString = localStorage.getItem('queryString');
  
          if (queryString) {
            setFilterLoading(true);
            let searchParameters = JSON.parse(queryString); 
            setName(searchParameters.name);
            setState(searchParameters.state);
            setUserType(searchParameters.userType);
            setLG(searchParameters.lga);
            setStatus(searchParameters.status);
            setTimeout(() => {
              setFilterLoading(false);
            }, 500)
            //Setting the url back to default without reloading
            window.history.replaceState({}, document.title, window.location.pathname); 
            setLoadingUser(false);
          }
        } else {
          newUrl = `api/user?page=${page - 1}&size=20`;
        }

    Axios.get(newUrl) 
    .then((response) => {
      setLoadingUser(false);
      let data = response?.data?.data?.data.sort((a, b) => a.first_name.localeCompare(b.first_name));
      setUserList(data)
      setResponse(response?.data?.data);
      setPageAnalytics(response?.data?.analytics);
      setTotalPages(response?.data?.data?.last_page);
    }).catch((error) => {
      setLoadingUser(false);
      console.error(error);
      const statusCode = error?.response?.status;
      if (statusCode === 401) {
        returnToLogin()
      }
    });
  };
  const handlePageChange = (page, event) => {
    setCurrentPage(page);
  };
  // const handlePageChange = (page) => {
  //   console.log("page ="+page)
  //   setCurrentPage(page);
  // };
  
  useEffect(() => {
    getAllUsers(currentPage);
    // getAllStates();
    // getAllUserTypes();
  }, [currentPage]); 

  const returnToLogin = () => {
    localStorage.clear();
    window.location.href = '/login'
  }

  // const getAllStates = async () => {
  //   setLoadingUser(true);
  //   Axios.get(`api/geoLocations/level/3?size=50`)
  //   .then((response) => {
  //     setStateList(response?.data?.content)
  //     setLoadingUser(false);
  //   }).catch((error) => {
  //     console.error(error);
  //     setLoadingUser(false);
  //   });
  // };

  const reset = () =>{
    localStorage.setItem('searchedUrl', '');
    localStorage.setItem('queryString', '');
    window.location.reload();
  }

  const getAllUserTypes = async () => {
    setLoadingUser(true);
    Axios.get(`api/userTypes`)
    .then((response) => {
      setUserTypeList(response?.data?.content);
      setLoadingUser(false);
    }).catch((error) => {
      console.error(error);
      setLoadingUser(false);
    });
  };

  const searchFilter = async (event) => { 
    event.preventDefault();
    return
    setLoadingUser(true);

    let location = "";
    if (state !== "") 
      location = handleFilterForm();
    // return console.log(location);
    setLoadingUser(true);
    let active = true;
    if (status !== '') 
      active = (status === 1) ? true : false;

    let userId = [];
    if (filterUserValues != []) 
      userId = filterUserValues[0];
    
    // return console.log("User page",userType);
    setLoadingUser(true);
    Axios.get(`api/users/search?codes=${userId}&active=${active}&location=${location}&userType=${userType}`)
    .then((response) => {
      setLoadingUser(false);
      setTeamList(response?.data?.content)
      setResponse(response?.data)
      setShowReset(true)
    }).catch((error) => {
      setLoadingUser(false);
      console.error(error);
    });
  };

  const handleFilterForm = () =>{
    let  location = '';
    if (state !== '') 
      location = state;
    
    // if (state !== '' && lg !== '') 
    //   location = lg;

    // if (state !== '' && filterLg !== '' && ward !== '') 
    //   location = ward;
    return location;
  }
  const handleExport = async (event) => {
    let pageName = "USER";

    event.preventDefault();
    return;
    // setLoadingUser(true);

    // let location = "";
    // if (state !== "") 
    //   location = handleFilterForm();

    // let active = true;
    // if (status !== '') 
    //   active = (status === 1) ? true : false;

    // let userId = [];
    // if (filterUserValues !== []) 
    //   userId = filterUserValues[0];
    

    // setLoadingUser(true);
    //   Axios.get(`api/users/export?codes=${userId}&active=${active}&location=${location}&format=CSV`)
    //   .then((response) => {
    //     exportDownloader(response, exportType, pageName)
        

    //     setLoadingUser(false);
    //     notify_user("Export successful", "success") 
    //     setShowReset(true)
    //   }).catch((error) => {
    //     notify_user(error, "error")
    //     setLoadingUser(false);
    //     console.error(error);
    //   });


  
  }

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...files]);
  };
  const chooseFile = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    setIsPending(true);
    setLoadingUser(true);
    const files = fileInputRef.current.files;
    const formData = new FormData();

    
    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }

    FileAxios.post(`api/users/upload`, formData)
    .then((response) => { 
      openDialogue(response?.data);
      setLoadingUser(false); 
      setIsPending(false)
      getAllUsers(currentPage)

    }).catch((error) => {
      notify_user(error.message, "error")
      console.error(error);
      setIsPending(false);
      setLoadingUser(false);
    });
    handleModalClose()
  }; 

  const collateTextValue = (event) => {
    const { name, value } = event;
    if (name === "name") {
      setName(value);
      setSearchParameter((prevSearchParameter) => ({
        ...prevSearchParameter, 
        name: value,
      }));
    }
  };
  if (filterLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title> Manage  Users </title>
      </Helmet>

      {/* <Container> */}
        <ToastContainer/>
        <div className={`${styles.header_container} p-4 d-flex mb-3 text-white`} >
            <img src="/assets/icons/roles_icon.png" alt="icon" />&nbsp;&nbsp;
            <span className="mt-1" > Manage Users  </span>
        </div> 
        {/* <div className={`p-3 ${styles.card_background1} mb-4`}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <InnerAppAnalytics title="Total Users" total={pageAnalytics.totalUsers} color="success"/>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InnerAppAnalytics total={pageAnalytics.activeUsers} title="Active" color="success" />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InnerAppAnalytics  total={pageAnalytics.inactiveUsers} title="Inactive" color="success"/>
              </Grid>
            </Grid>
        </div> */}

        <div className=' p-3'>
          {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}> */}
          <div className="row">
            <div className="col-6">
              <Typography variant="h5" gutterBottom>
                {/* Manage Users */}
              </Typography>
            </div>
            <div className="col-4">
              {/* <div className="d-flex justify-content-end">
              {hasPermission("EXPORT_USER") &&
                <button 
                  onClick={handleExport}
                  className={`${styles.export_btn} btn`}
                >
                  <img src="/assets/icons/export.png" alt="" />
                  &nbsp;
                  Export Users
                </button>
              }
              </div> */}
            </div>
            <div className="col-2">
                  <div className=" d-flex justify-content-end">
                  {hasPermission("CREATE_USER") &&
                    <button 
                      onClick={()=>{goto('add_users')}} 
                      className="btn general_buttons" 
                      type="button"
                    >
                        <img src="/assets/icons/Plus.png" alt="" />
                        &nbsp;
                        New User
                    </button>
                  }
                {/* <div className="">
                {hasPermission("CREATE_USER") &&
                  <button type="button" className="btn general_buttons" data-toggle="dropdown" aria-expanded="false">
                      <img src="/assets/icons/Plus.png" alt="" />
                      &nbsp;
                      New User
                  </button>
                }
                  <div className="dropdown-menu dropdown-menu-right light_green">
                  {hasPermission("CREATE_USER") &&
                    <button 
                      onClick={()=>{goto('add_users')}} 
                      className="dropdown-item" 
                      type="button"
                    >Add Single User</button>
                  }
                  {hasPermission("IMPORT_USER") &&
                    <>
                      <hr />
                      <button 
                        onClick={()=>{setAddUserModalOpen(true)}} 
                        className="dropdown-item" 
                        type="button"
                      >Upload Multiple Users</button>
                    </>
                  }
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* </Stack> */}
        </div>
        <Card className='p-4'>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
          
          <Scrollbar>
            {/* filters */}
          {/* <form action="" onSubmit={handleSearchClick}>
            <div className="row pt-2">
            <div className={`col-md-3`}>
              <TextField 
                name="name"
                value={name}
                title="Name"
                onValueChange={collateTextValue} 
              /> 
              </div>
              <div className="col-md-3">
                <div className="mb-3">
                  <Select
                    name="userType"
                    title="User Type"
                    value={userType}
                    optionList={userTypeList}
                    onSelect={handleUserTypeChange}
                  />
                </div>
              </div>
              <div className={`col-md-3`}>
                <div className="mb-3 ">
                  <Select
                    name="state"
                    title="State"
                    value={state}
                    optionList={stateList}
                    onSelect={handleStateChange}
                  />
                    
                </div>
              </div> 

                
              <div className="col-md-3">
                <button type="submit" className={` btn modal_btn filter_btns`}>
                  Search &nbsp; &nbsp;
                  {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                </button>
                {
                  showReset && 
                  <span style={{paddingLeft:"15px"}}>
                    <button type="button" onClick={reset} className={`  filter_btns btn btn-warning`}>
                      Reset 
                    </button>
                  </span>
                }
              </div>
            </div>
            
          </form> */}
            <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}>
              <table className={`${styles.table} table-hover table-striped`}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      return (
                        <th key={i} className="text-muted th">{el.label}</th>
                      )
                    })}

                  </tr>

                </thead>
                {userList.length === 0 ? (
                  <tbody >
                    <tr >
                      <td colSpan={8}>
                        <p className="alert alert-info mt-3 w-100 text-center" role="alert">
                          No users available.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                ):(
                <tbody>
                {userList.map((el, i) => {
                      return (
                        <tr key={i} style={{ cursor: 'pointer' }} className={`${styles.tr}`}>
                          <td key={i}>{i+1}</td>
                          <td>
                            {el?.first_name+" "+el?.last_name}
                          </td>
                          <td>
                                {el?.email}
                          </td>
                          <td>
                            {el?.profile?.phone_no}
                          </td>
                          <td>
                            {el?.user_type?.name}
                          </td>
                          <td>
                            {el?.role?.name}
                          </td>
                          <td>
                            {
                              el.active ? (
                              <span className={"badge_success"}>
                                Active
                              </span>):(
                                <span className={"badge_danger"}>
                                Inactive
                              </span>
                              )
                            }
                            
                          </td>
                          <td>
                            <div>
                              <span
                                id="dropdownMenuButton" 
                                data-toggle="dropdown" 
                                aria-haspopup="true" 
                                aria-expanded="false"
                                className={`${styles.action_button} dropleft text-center p-3`} style={{
                                  cursor: 'pointer',
                                }}>
                                <img src="/assets/icons/action.svg" alt="" />
                              </span>
                              <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                              {hasPermission("EDIT_USER") &&
                                <button 
                                  type="button" 
                                  onClick={() => goto("edit_users?id=" + el.id)} 
                                  className=" dropdown-item btn border-0">
                                  Edit
                                </button>
                              }
                              {hasPermission("DELETE_USER") &&
                                <button type="button" 
                                  onClick={() => showAlert(el.id)}
                                  className="dropdown-item btn border-0 text-danger"
                                >
                                  Delete
                                </button>
                              }
                              </div>
                            </div>

                        </td>
                        </tr>
                        
                      )
                    })}

                </tbody>
                )}
              </table>
            </div>
            {loadingUser &&
              <div className='loader'>
                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
              </div>
            }
          </Scrollbar>
          {/* Pagination*/}
          {currentPage &&<Pagination
            page={currentPage}
            totalPages = {totalPages}
            onChange={handlePageChange}
            response = {response}
          /> }
          {/* <nav>
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo; Previous</button>
                </li>

                {Array.from({ length: totalPages }, (_, index) => (
                    <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    </li>
                ))}

                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}> Next &raquo;</button>
                </li>
            </ul>
        </nav> */}
        </Card>
      {/* </Container> */}
      

      {/* Add Team Modal */}
      <Modal
        open={addUserModalOpen}
        onClose={handleModalClose}
        callback={handleModalClose}
        borderRadius="20px"
        p="0px"
      >
          <div className='modal_header other_modal_top'>
            <h5  className='other_modal_header_text'>Upload Multiple Users</h5>
            <span className='close_support_modal' onClick={handleModalClose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div> 
          <Card>
            <form  onSubmit={handleUpload}>
              <div className="row p-5">
                <div className="col-12 col-md-8 text-center">
                    <input 
                      type="file" 
                      name="file" 
                      id="fileInput" 
                      style={{display: 'none'}} 
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      accept=".csv"
                      // multiple
                    />
                    <button 
                      className='supportAttachment p-3'
                      onClick={chooseFile}
                      type="button"
                    >
                      <img height="50px" src="/assets/icons/upload icon.jpg" alt="" />
                      &nbsp;  &nbsp;  &nbsp;
                      <span className='text-center'>
                        Click to upload CSV
                      </span>
                    </button>
                    {selectedFiles.length > 0 &&  (
                      <div>
                      {selectedFiles.map((file, index) => (
                        <span key={index} style={{color:'#3c8dbc'}}>{file.name},&nbsp;</span> 
                      ))}
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-4 pt-3">
                    <button type="submit" className={` btn btn-block modal_btn p-3`}>
                      Upload &nbsp; &nbsp;
                      {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                    </button>
                  </div>
                  <div className="col-12 pt-4">
                    <a 
                      href={`${csvDownloadBaseUrl}/User_Data_Upload_Template.csv`}
                    >
                      Download Template
                    </a>
                </div>
              </div>
            </form>
          </Card>


        {/* </div> */}
       
       
      </Modal>

      <Modal
        open={dialogue}
        onClose={handleDialogueCLose}
        callback={handleDialogueCLose}
        borderRadius="20px"
        p="0px"
      >
          <div className='modal_header other_modal_top'>
            {/* <h3  className='other_modal_header_text'>Upload Details</h3> */}
            <span className='close_support_modal' onClick={handleDialogueCLose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div> 
          <Card className='text-center pt-3'>
            <h4>Upload Details</h4>
                {/* <p>{dialogueContent.total}</p> */}
                {Object.entries(dialogueContent).map(([key, value], index) => (
                  <p style={{fontSize:"20x", fontWeight:"600"}} key={index}>{key}: {value}</p>
                ))}

          </Card>       
       
      </Modal>
      
    </>
  );
}
