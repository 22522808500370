import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useContext } from 'react';
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import { useNavigate, useLocation } from "react-router-dom";

import { useParams } from 'react-router-dom';
import Autocomplete from '../../components/inputs/Autocomplete';
import TextArea from '../../components/inputs/TextArea';
import SelectBank from '../../components/inputs/SelectBank';
import Select from '../../components/inputs/Select';
import styles from "./User.module.css"
import { styled } from '@mui/material/styles'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import Axios from "../../axiosInstance";
import TextField from '../../components/inputs/Textbox';
import AuditLog from '../Common/AuditLog'
import { getRoles, getUsertTypes } from '../../CommonMethods';

import {
    Card,
    Container, 
  } from '@mui/material';

export default function UserPage() {  

    const baseUrl = useContext(BaseUrlContext);
  
  
  
    const navigate = useNavigate();

 
    const [state, setState] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState({ id: "", title: '' });
    const [phoneNumber, setphoneNumber] = useState('');
    const [status, setStatus] = useState("");
    const [userTypeList, setUserTypeList] = useState([]);
    const [userData, setUserData] = useState([]);
    const [userId, setUserId] = useState();
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState('');
    const [roleList, setRoleList] = useState([]);
    const [stateList, setStateList] = useState([])
    const [lgaList, setLgaList] = useState([])
    const [wardList, setWardList] = useState([]);
    const [wardTeamList, setWardTeamList] = useState([]);
    const [showState, setShowState] = useState(false);
    const [response, setResponse] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [showNational, setShowNational] = useState(false);
    const [national, setNational] = useState('');
    const [nationalList, setNationalList] = useState([]);
    const [sytemInformation, setSystemInformation] = useState([]);



      const statusList  = [
        { id: 1, name: 'Active' },
        { id: 2, name: 'Inactive' }
      ];
  
    const collateTextValue = (event) => {
      const { name, value } = event;
      if (name === "firstName") {
        setFirstName(value);
      }else if (name === "lastName") {
        setLastName(value);
      }else if (name === "email") {
        setEmail(value);
      }else if (name === "phoneNumber") {
        setphoneNumber(value);
      }
    };
    
  //  useEffect(() => {
     
  //  }, [input]);
    const handleSubmit = async (event) => {
      setIsPending(true);
      event.preventDefault();

      let active = status === 1 ? true : false; 
      let location = null;
      if (state !== '' && national === '') 
        location = state;
      
      if (state === '' && national !== '')
        location = national;
        
      const formData = {
        first_name:firstName,
        last_name:lastName,
        user_type_id:userType,
        role_id:role,
        email,
        phone_no:phoneNumber,
        active
      }
      // if (state !== '' || national !== '') {
      //   formData['location']={
      //     id: location
      //   }
      // }
      
      Axios.put(`api/user/${userId}`, formData)
      .then((response) => {
        notify_user("User edited successfully", "success");
        goto("user");
        setIsPending(false);

      }).catch((error) => {
        setIsPending(false);
        notify_user("User was not edited", "error")
        console.error(error);
      });
    };

    const getRolesData = async () => { 
      try {
        let roles = await getRoles();
        setRoleList(roles);
      } catch (error) {
        console.error('Failed to fetch roles:', error);
      }
    };

    const getAllUserTypes = async () => { 
      try {
        let user_types = await getUsertTypes();
        setUserTypeList(user_types);
      } catch (error) {
        console.error('Failed to fetch roles:', error);
      }
    };
    const handleStateChange = (value) => {
        setState(value)
    }

    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);
      const id = urlParams.get('id');
      getSingleUserDetails(id)
      getAllUserTypes();
      getRolesData();
      // getRoles();
      // getAllStates();
      // getNational();
    }, [location]);

    const getSingleUserDetails = async (id) => {
      Axios.get(`api/user/${id}`)

      .then((response) => { 
        // const location = response?.data?.location?.code;
        setFirstName(response?.data?.data?.first_name)
        setLastName(response?.data?.data?.last_name)
        setEmail(response?.data?.data?.email)
        setphoneNumber(response?.data?.data?.profile?.phone_no)
        setUserType(response?.data?.data?.user_type?.id)
        setUserId(response?.data?.data?.id)
        setRole(response?.data?.data?.role?.id)
        setStatus(response?.data?.data?.active?1:2)
        // setState(response?.data?.location?.id)
        // if (location.toLowerCase() === "national") {
        //   setNational(response?.data?.location?.id)
        // }
        // if (location.toLowerCase() === "state") {
        //   setState(response?.data?.location?.id)
        // }
        setLoading(false);
      }).catch((error) => {
        console.error(error);
        setLoading(false);

      });
    };

    const handleNationalChange = (value) => {
      setNational(value)
    }

    const notify_user = (message, toast_type) => {
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }else if (toast_type == 'info'){
        toast.info(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#97ddef',
            color:"#000000"
          },
        })
      }
      
    };
    const goto = (d) => {
      navigate('/dashboard/'+d)
    };

    const handleGeoZoneChange = (geoZoneValue) => {
        setGeoZone(geoZoneValue)
    }
    const handleRoleChange = (value) => {
        setRole(value)
      }
    const handleUserTypeChange = (value) => {
        setUserType(value)
    }
    const handleStatusChange = (value) => {
        setStatus(value)
    }
    const handleLgChange = (lgaValue) => {
      setLG(lgaValue)
    }

     //hide state droplist
    //  useEffect(() => {
    //   if (userType !== '') {
    //     var desiredTitle = '';

    //     for (var i = 0; i < userTypeList.length; i++) {
    //         if (userTypeList[i].id === userType) {
    //             desiredTitle = userTypeList[i].title;
    //             break;
    //         }
    //     }
    //     if (desiredTitle.toLowerCase() === "state") {
    //       setShowState(true);
    //       setNational('')
    //     }else{
    //       setShowState(false);
    //     }
    //     if (desiredTitle.toLowerCase() === "national" || desiredTitle.toLowerCase() === "admin") {
    //       setShowNational(true);
    //       setState('');
    //     }else{
    //       setShowNational(false);
    //     }

    //   }
      
    // }, [userType,userTypeList]);


    if (loading) {
      return <div>Loading...</div>;
    }
  
    return (
      <>
        <Helmet>
          <title> Edit User </title>
        </Helmet>
        
  
        {/* <Container> */}
        <ToastContainer />
        <div className={`${styles.header_container} p-4 d-flex mb-3 text-white`} >
            {/* <img src="/assets/icons/roles_icon.png" alt="icon" />&nbsp;&nbsp; */}
                <span className={`${styles.header_text}`}> Edit User </span>
            </div>
          <Card className='p-4 light_card_background'>
          <form className="mt-0" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-4">
                    <TextField 
                        name="firstName"
                        title="First Name" 
                        value={firstName}
                        required
                        onValueChange={collateTextValue} 
                    />
                </div>
                <div className="col-md-6 mb-1">
                    <TextField 
                        name="lastName"
                        value={lastName}
                        title="Last Name" 
                        required
                        onValueChange={collateTextValue} 
                    />
                </div>
              
                <div className="col-md-6 mb-4">
                    <TextField 
                        name="email"
                        title="Email" 
                        value={email}
                        type="email"
                        required
                        onValueChange={collateTextValue} 
                    />
                </div>
                <div className="col-md-6 mb-1">
                    <TextField 
                        name="phoneNumber"
                        value={phoneNumber}
                        title="Phone Number"
                        type="tel"
                        required
                        onValueChange={collateTextValue} 
                    />
                </div>

                <div className="col-md-6 mb-4">
                <Select
                        name="userType"
                        title="User Type"
                        value={userType}
                        optionList={userTypeList}
                        onSelect={handleUserTypeChange}
                        required={true}
                    />
                </div>
                {/* {showNational &&
                  <div className="col-md-6 mb-4">
                    <Select
                      name="national"
                      title="National Location"
                      value={national}
                      optionList={nationalList}
                      onSelect={handleNationalChange}
                    />
                  </div>
                } */}
                {/* {showState &&
                  <div className="col-md-6 mb-4">
                    <Select
                      name="state"
                      title="State"
                      value={state}
                      optionList={stateList}
                      onSelect={handleStateChange}
                    />
                  </div>
                } */}
                <div className="col-md-6 mb-1">
                    <Select
                        name="status"
                        title="Status"
                        value={status}
                        optionList={statusList}
                        onSelect={handleStatusChange}
                        required={true}
                    />
                </div>

                <div className="col-md-6 mb-4">
                      <Select
                          name="role"
                          title="Role"
                          value={role}
                          optionList={roleList}
                          onSelect={handleRoleChange}
                          required={true}
                      />
                  </div>
              </div>
              <div className="mb-4 mt-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button type="button" className={`btn btn-block buttons_outline`} onClick={()=>goto("user")}>
                      Back
                  </button>
                  <button className={`btn btn-block buttons`}>
                      Update &nbsp; &nbsp;
                      {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                  </button>
              </div>
            </form>
          </Card>

          {/* <AuditLog prop={sytemInformation}/>
        </Container> */}

      </>
    );
  }