import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';

const MyTextbox = (props) => {
  const [textValue, setTextValue] = useState(props.value);
  const [showValidationError, setShowValidationError] = useState(false)
  const [showAccNoValidationError, setShowAccNoValidationError] = useState(false)
  const [showProgramCodeError, setShowProgramCodeError] = useState(false)
  const [showEmailValidationError, setShowEmailValidationError] = useState(false)


  const handleTextChange = (event) => {
    const value = event.target.value;

    const validateProgramCode = () => {
      setShowProgramCodeError(true);
      props.onValueChange(value.slice(0, 4));
      setTextValue(value.slice(0, 4));
      if (value.length === 4) {
        setShowProgramCodeError(false);
        setTextValue(value);  
        props.onValueChange(event.target, value)
      }
      if (value.length > 4) {
        setShowAccNoValidationError(false);
      }
    }

    const validateAccountNumber = () => {
      // const value = event.target.value;
      setShowAccNoValidationError(true);
      props.onValueChange(value.slice(0, 10));
      setTextValue(value.slice(0, 10));
      if (value.length === 10) {
        setShowAccNoValidationError(false);
        setTextValue(value);
        props.onValueChange(value)
        // props.onValueChange(event.target, value)
      }
      if (value.length > 10) {
        setShowAccNoValidationError(false);
      }
    }
    const validatePhoneNumber = () => {
      // const value = event.target.value;
      setShowValidationError(true);
      props.onValueChange(value.slice(0, 11)); 
      setTextValue(value.slice(0, 11)); 
      if (value.length === 11) {
        setShowValidationError(false);
        setTextValue(value);
        props.onValueChange(event.target)
      }
      if (value.length > 11) {
        setShowValidationError(false);
      }

    }
    const validateEmail = () => {
      setShowEmailValidationError(true);
      props.onValueChange(value);
      setTextValue(value);
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
      if (emailPattern.test(value)) {
        setShowEmailValidationError(false);
        props.onValueChange(event.target);
        setTextValue(value);
      }
    };

    
    if (props.type === 'tel') {
      validatePhoneNumber()
    }else if(props.type === 'number'){
      validateAccountNumber()
    }else if(props.type === 'email'){
      validateEmail()
    }else if(props.code === 'code'){
      validateProgramCode()
    }
    else{
      setTextValue(value);
      props.onValueChange(event.target)
    }
    
  };
  if (props.type === 'tel') {
    const newType = "number"
  }

  return (
    <>
    <TextField
      name={props.name}
      label={props.title}
      type={props?.type === 'tel' ? 'number' : (props.type ? props.type : 'text')}
      variant="outlined"
      value={textValue}
      style={{width:"100%"}}
      onChange={handleTextChange}
      required={props.required??false}
      disabled={props.disabled??false}
      InputProps={props.InputProps??{}}
      sx={{
        "& .MuiInputLabel-root": {
          color: "#808080"
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#808080"
          },
          "&:hover fieldset": {
            borderColor: "#808080"
          },
          "&.Mui-focused": {
            "& fieldset": {
              borderColor: "#3c8dbc"
            },
            "& .MuiInputLabel-root": {
              color: "#3c8dbc"
            }
          }
        }
      }}
    />
    {
      showValidationError && <p className='text-danger'>
        <small>
          Phone number must be 11 digits
        </small>
      </p>
    }{
      showAccNoValidationError && <p className='text-danger'>
        <small>
          Account number must be 10 digits
        </small>
      </p>
    }{
      showEmailValidationError && <p className='text-danger'>
        <small>
          Email address must contain @ and .domain
        </small>
      </p>
    }{
      showProgramCodeError && <p className='text-danger'>
        <small>
          Code cannot be more than 4 characters
        </small>
      </p>
    }
    </>
  );
};

export default MyTextbox;
