// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Auth_password_changed_success__cJ4wn{
    height: 100px;
    background-color: #ebf1eb;
    border-radius: 50%;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Auth_div_container__SCm3I{
    height: 100% !important;
    width: 100% !important;
    margin: 60px 0px 0px 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow-y: hidden !important;
}

.Auth_password_changed__BR3nV{
    color: #3c8dbc;
    font-size: 20px;
    font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Auth.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,mCAAmC;IACnC,wBAAwB;IACxB,kCAAkC;IAClC,8BAA8B;IAC9B,6BAA6B;AACjC;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".password_changed_success{\n    height: 100px;\n    background-color: #ebf1eb;\n    border-radius: 50%;\n    width: 100px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.div_container{\n    height: 100% !important;\n    width: 100% !important;\n    margin: 60px 0px 0px 0px !important;\n    display: flex !important;\n    justify-content: center !important;\n    align-items: center !important;\n    overflow-y: hidden !important;\n}\n\n.password_changed{\n    color: #3c8dbc;\n    font-size: 20px;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"password_changed_success": `Auth_password_changed_success__cJ4wn`,
	"div_container": `Auth_div_container__SCm3I`,
	"password_changed": `Auth_password_changed__BR3nV`
};
export default ___CSS_LOADER_EXPORT___;
