import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useContext, useRef } from 'react';
import Select from '../../components/inputs/Select';
import styles from "./Geolocation.module.css"
import { useNavigate } from "react-router-dom";
import Axios from "../../axiosInstance";
import FileAxios from "../../axiosFileInstance";
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { permissions, hasPermission } from '../../Authorization';
import Pagination from '../Common/Pagination_old';
import Modal from "src/Modals/Modal";


// @mui
import {
  Card, 
  Stack, 
  Container,
  Typography, 
  Grid,
} from '@mui/material';
import {
  InnerAppAnalytics, 
} from '../../sections/@dashboard/app';
// components 
import Scrollbar from '../../components/scrollbar';  
const TABLE_HEAD = [
  { id: 'sn', label: '#', alignRight: false },
  { id: 'code', label: 'Code', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'parent', label: 'Parent', alignRight: false },
  { id: 'level', label: 'Level', alignRight: false }, 
  { id: 'action', label: 'Action', alignRight: false },
];


export default function UserPage() {
  const fileInputRef = useRef(null);

  const navigate = useNavigate();
  const goto = (d) => {
    navigate('/dashboard/'+d)
  };
  const [loadingUser, setLoadingUser] = useState(false)

  const chooseFile = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dialogue, setDialogue] = useState(false);
  const [dialogueContent, setDialogueContent] = useState('');

  const [state, setState] = useState("");
  const [lg, setLG] = useState("");
  const [ward, setWard] = useState("");
  const [geoLocationList, setGeolocationList] = useState([]);
  const [response, setResponse] = useState([]);
  const [stateList, setStateList] = useState([])
  const [lgaList, setLgaList] = useState([])
  const [wardList, setWardList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPending, setIsPending] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [previousState, setPreviousState] = useState('');
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const csvDownloadBaseUrl = process.env.REACT_APP_CSV_TEMPLATE_BASE_URL;

  const [pageAnalytics, setPageAnalytics] = useState({
    "lgaCount":'',
    "stateCount":'',
    "wardCount":''
  });

  const [searchParameter, setSearchParameter] = useState({
    'name':'',
    'state':'',
    'lga':'',
    'ward':'',
    'teamCode':'',
    'teamType':'',
    'status':''
  });

  const handleStateChange = (value) => {
    setState(value);
    setSearchParameter((prevSearchParameter) => ({
      ...prevSearchParameter, 
      state: value,
    }));
  };
  
  const handleLgaChange = (value) => {
    setLG(value);
    setSearchParameter((prevSearchParameter) => ({
      ...prevSearchParameter, 
      lga: value,
    }));
  };

  const handleWardChange = (value) => {
    setWard(value);
    setSearchParameter((prevSearchParameter) => ({
      ...prevSearchParameter, 
      ward: value,
    }));
  };

  const handleDialogueCLose = () => {
    setDialogue(false)
  }

  const openDialogue = (response) =>{
    setDialogue(true);
    setDialogueContent(response)
  }

  const handleFilterForm = () =>{
    let  location = '';
    if (state !== '' && lg === '') 
      location = state;
    
    if (state !== '' && lg !== '') 
      location = lg;

    if (state !== '' && lg !== '' && ward !== '') 
      location = ward;
    return location;
  }



  const notify_user = (message, toast_type) => {
    if (toast_type == 'success') {
      toast.success(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#d0efd0',
          color:"#000000"
        },
      })
    }else if(toast_type == 'error'){
      toast.error(message, {
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#f2c4c4',
          color:"#000000"
        },
      })
    }else if (toast_type == 'info'){
      toast.info(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#97ddef',
          color:"#000000"
        },
      })
    }
    
  };
//============================================ Normal Select End=================================================//

const handleSearchClick = (event) => {
  event.preventDefault();
  setIsPending(true);

  setLoadingUser(true);

    let location = "";
    if (state !== "") 
      location = handleFilterForm(); 

    let prevUrl = localStorage.getItem('searchedUrl');
    if (prevUrl) {
      localStorage.removeItem('searchedUrl')
    }
    let setUrl = `api/geoLocations/filter?page=${currentPage - 1}&size=20&location=${location}`;
    localStorage.setItem('searchedUrl',setUrl);

    const queryString = JSON.stringify(searchParameter);
    localStorage.setItem('queryString',queryString);

  getAllLocations(currentPage); 
  setTimeout(() => {
    setShowReset(true);
    setIsPending(false);
  }, 1200);
};

  const getAllLocations = async (page) => { 
    
    let url = localStorage.getItem('searchedUrl');
      let newUrl; 
        if (url) {
          newUrl = url;
          setShowReset(true);
          let queryString = localStorage.getItem('queryString');
  
          if (queryString) {
            setFilterLoading(true);
            let searchParameters = JSON.parse(queryString); 
            setWard(searchParameters.ward);
            setState(searchParameters.state);
            setLG(searchParameters.lga);
            setTimeout(() => {
              setFilterLoading(false);
            }, 500)
            //Setting the url back to default without reloading
            window.history.replaceState({}, document.title, window.location.pathname); 
            setLoadingUser(false);
          }
        } else {
          newUrl = `api/geoLocations/filter?page=${page - 1}&size=20`;
        }

    Axios.get(newUrl)
    .then((response) => {
      setLoadingUser(false);
      
      let data = response?.data?.data?.content.sort((a, b) => a.name.localeCompare(b.name));
      setGeolocationList(data);
      setResponse(response?.data?.data);
      setPageAnalytics(response?.data?.analytics);
      setFilterLoading(false);
    }).catch((error) => {
      setLoadingUser(false);
      setFilterLoading(false);
      console.error(error);
      const statusCode = error?.response?.status;
      if (statusCode === 401) {
        returnToLogin()
      }
    });
  };

  useEffect(() => {
    getAllLocations(currentPage);
  }, [currentPage]); 

  useEffect(() => {
    if (state !== '') {
      getAllLgas();
    }
    // if (state !== previousState) {
    //   setLG('');
    //   setLgaList([]);
    //   setWard('');
    //   setWardList([]);
    // }
    if (lg !== '') {
      getAllWards()
    }
    setPreviousState(state);
  }, [state, lg]); 

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const getAllStates = async () => { 
    setLoadingUser(true)
    Axios.get(`api/geoLocations/level/3?size=50`)
    .then((response) => {
      setStateList(response?.data?.content)
      setLoadingUser(false)
    }).catch((error) => {
      console.error(error);
      setLoadingUser(false)
    });
  };
  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...files]);
  };

  const getAllLgas = async () => { 
    setLoadingUser(true)
    Axios.get(`api/geoLocations/${state}/children?size=500`)
    .then((response) => {
      // setLG("");
      setLgaList(response?.data?.content);
      setLoadingUser(false)
    }).catch((error) => {
      console.error(error);
      setLoadingUser(false)
    });
  };
  
  const getAllWards = async () => { 
    setLoadingUser(true)
    Axios.get(`api/geoLocations/${lg}/children?size=1000`)
    .then((response) => {
      setWardList(response?.data?.content)
      setLoadingUser(false)
    }).catch((error) => {
      console.error(error);
      setLoadingUser(false)
    });
  };

  const handleModalClose = () => {
    setAddLocationModalOpen(false);
    setSelectedFiles([]);
  };


  const returnToLogin = () => {
    localStorage.clear();
    window.location.href = '/login'
  }
  
    useEffect(() => {
      getAllStates()
    }, []); 

    const formatPaginationDisplayText = (pageNumber, pageSize, totalElements) => {
      const startIndex = pageNumber * pageSize;
      const endIndex = Math.min(startIndex + pageSize, totalElements);
      return `${startIndex + 1} - ${endIndex} of ${totalElements}`;
    };

    const reset = () =>{
      localStorage.setItem('searchedUrl', '');
      localStorage.setItem('queryString', '');
      window.location.reload();
    }

    const handleUpload = async (event) => {
      event.preventDefault();
      setIsPending(true);
      setLoadingUser(true);
      const files = fileInputRef.current.files;
      const formData = new FormData();
  
      
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
      }
      FileAxios.post(`api/geoLocations/upload`, formData)
      .then((response) => {
        openDialogue(response?.data)
        setIsPending(false)
        getAllLocations(currentPage)
        setLoadingUser(false);
      }).catch((error) => {
        notify_user(error.message, "error")
        console.error(error);
        setIsPending(false)
        setLoadingUser(false);
      });
      handleModalClose()
    };

    if (filterLoading) {
      return <div>Loading...</div>;
    }

  return (
    <>
      <Helmet>
        <title> Geolocations | VTCD </title>
      </Helmet>

      <Container>
        <ToastContainer/>
        <div className={`${styles.card_background}  p-2`} >
          Location Overview
        </div>
        <div className={`p-3 ${styles.card_background1} mb-4`}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <InnerAppAnalytics title="Total State" total={pageAnalytics.stateCount} color="success"/>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InnerAppAnalytics total={pageAnalytics.lgaCount} title="Total Lga" color="success" />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InnerAppAnalytics  total={pageAnalytics.wardCount} title="Total Ward" color="success"/>
              </Grid>
            </Grid>
        </div>

        <div className=' p-3'>
          <div className="row">
            <div className="col-6">
              <Typography variant="h5" gutterBottom>
                Manage Locations
              </Typography>
            </div>
            <div className="col-4">
              <div className="d-flex justify-content-end">
              </div>
            </div>
            {/* ======================================================================================= */}
            <div className="col-2">
              <div className=" d-flex justify-content-end">
                <div className="">
                {hasPermission("CREATE_GEOLOCATION") &&
                  <button onClick={()=>{goto('add_geolocation')}}  type="button" className="btn general_buttons" >
                      <img src="/assets/icons/Plus.png" alt="" />
                      &nbsp;
                      New
                  </button>
                }
                 
                </div>
              </div>
            </div>
            {/* =========================================================================================== */}
            {/* <div className="col-2">
              <div className=" d-flex justify-content-end">
                <div className="">
                {hasPermission("CREATE_GEOLOCATION") &&
                  <button type="button" className="btn general_buttons" data-toggle="dropdown" aria-expanded="false">
                      <img src="/assets/icons/Plus.png" alt="" />
                      &nbsp;
                      New Locations
                  </button>
                }
                  <div className="dropdown-menu dropdown-menu-right light_green">
                  {hasPermission("CREATE_GEOLOCATION") &&
                    <button 
                      onClick={()=>{goto('add_geolocation')}} 
                      className="dropdown-item" 
                      type="button"
                    >Add Single Location</button>
                  }
                  {hasPermission("CREATE_GEOLOCATION") &&
                    <>
                      <hr />
                      <button 
                        onClick={()=>{setAddLocationModalOpen(true)}} 
                        className="dropdown-item" 
                        type="button"
                      >Upload Multiple Locations</button>
                    </>
                  }
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* </Stack> */}
        </div>
        <Card className='p-4'>
          {/* <geoLocationListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
          
          <Scrollbar>
          <form action="" onSubmit={handleSearchClick}>
            <div className="row pt-2">
              <div className={`col-md-3`}>
                <div className="mb-3 ">
                    <Select
                    name="state"
                    title="State"
                    value={state}
                    optionList={stateList}
                    onSelect={handleStateChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-3">
                  <Select
                    name="lga"
                    title="Lga"
                    value={lg}
                    optionList={lgaList}
                    onSelect={handleLgaChange}
                  />
                </div>
                
              </div>
              <div className="col-md-3">
                <div className="mb-3">
                  <Select
                    name="ward"
                    title="Ward"
                    value={ward}
                    optionList={wardList}
                    onSelect={handleWardChange}
                  />
                </div>
              </div>
              {/* <div className="col-md-2"> 
                <button type="submit" className={` btn btn-block modal_btn filter_btns`}>
                  Search 
                </button>
              </div> */}
                <div className="col-md-3">
                  <button type="submit" className={` btn modal_btn filter_btns`}>
                    Search &nbsp; &nbsp;
                    {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                  </button>
                  {
                    showReset && 
                    <span style={{paddingLeft:"15px"}}>
                      <button type="button" onClick={reset} className={`  filter_btns btn btn-warning`}>
                        Reset 
                      </button>
                    </span>
                  }
                </div>
            </div>
            
          </form>
            <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}>
              <table className={`${styles.table} table-hover table-striped`}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      return (
                        <th key={i} className="text-muted th">{el.label}</th>
                      )
                    })}

                  </tr>

                </thead>
                {geoLocationList.length === 0 ? (
                  <tbody >
                    <tr >
                      <td colSpan={8}>
                        <p className="alert alert-info mt-3 w-100 text-center" role="alert">
                          No Location available.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                ):(
                <tbody>
                {geoLocationList.map((el, i) => {
                      return (
                        <tr key={i} style={{ cursor: 'pointer' }} className={`${styles.tr}`}>
                          <td key={i}>{i+1}</td>
                          <td>
                            {el.code}
                          </td>
                          <td>
                                {el.name}
                          </td>
                          <td>
                            {el.parent ? el.parent.name:"-"}
                          </td>
                          <td>
                            {el.level.name}
                          </td>
                         
                          <td>
                            <div>
                              <span
                                id="dropdownMenuButton" 
                                data-toggle="dropdown" 
                                aria-haspopup="true" 
                                aria-expanded="false"
                                className={`${styles.action_button} dropleft text-center p-3`} style={{
                                  cursor: 'pointer',
                                }}>
                                <img src="/assets/icons/action.svg" alt="" />
                              </span>
                              <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                              {hasPermission("EDIT_GEOLOCATION") &&
                                <button 
                                  type="button" 
                                  onClick={() => goto("edit_geolocation?id=" + el.id)} 
                                  className=" dropdown-item btn border-0">
                                  Edit
                                </button>
                              }
                                {/* <button type="button" 
                                  onClick={() => showAlert(el.id)}
                                  className="dropdown-item btn border-0 text-danger"
                                >
                                  Delete
                                </button> */}
                              </div>
                            </div>

                        </td>
                        </tr>
                        
                      )
                    })}

                </tbody>
                )}
              </table>
            </div>
            {loadingUser &&
              <div className='loader'>
                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
              </div>
            }
          </Scrollbar>
          {/* Pagination*/}
          <Pagination
            response = {response}
            count={response?.totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />  
        </Card>
      </Container>

 {/* Add Location Modal */}
 <Modal
        open={addLocationModalOpen}
        onClose={handleModalClose}
        title="Add Multiple Locations"
        callback={handleModalClose}
        borderRadius="20px"
        p="0px"
      >
          <div className='modal_header other_modal_top'>
            <h5  className='other_modal_header_text'>Upload Multiple Locations</h5>
            <span className='close_support_modal' onClick={handleModalClose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div> 
          <Card>
            <form  onSubmit={handleUpload}>
              <div className="row p-5">
                {/* <input type="file" name="" id="" /> */}
                <div className="col-12 col-md-8 text-center">
                    <input 
                      type="file" 
                      name="file" 
                      id="fileInput" 
                      style={{display: 'none'}} 
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      accept=".csv"
                      // multiple
                    />
                    <button 
                      className='supportAttachment p-3'
                      onClick={chooseFile}
                      type="button"
                    >
                      <img height="50px" src="/assets/icons/upload icon.jpg" alt="" />
                      &nbsp;  &nbsp;  &nbsp;
                      <span className='text-center'>
                        Click to upload CSV
                      </span>
                    </button>
                    {selectedFiles.length > 0 &&  (
                      <div>
                      {selectedFiles.map((file, index) => (
                        <span key={index} style={{color:'#3c8dbc'}}>{file.name},&nbsp;</span> 
                      ))}
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-4 pt-3">
                    <button type="submit" className={` btn btn-block modal_btn p-3`}>
                      Upload &nbsp; &nbsp;
                      {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                    </button>
                  </div>
                  <div className="col-12 pt-4">
                    <a 
                      href={`${csvDownloadBaseUrl}/Health_Worker_Data_Upload_Template.csv`}
                    >
                      Download Template
                    </a>
                </div>
              </div>
            </form>
          </Card>


        {/* </div> */}
       
       
      </Modal>
      <Modal
        open={dialogue}
        onClose={handleDialogueCLose}
        callback={handleDialogueCLose}
        borderRadius="20px"
        p="0px"
      >
          <div className='modal_header other_modal_top'>
            {/* <h3  className='other_modal_header_text'>Upload Details</h3> */}
            <span className='close_support_modal' onClick={handleDialogueCLose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div> 
          <Card className='text-center pt-3'>
            <h4>Upload Details</h4>
                {/* <p>{dialogueContent.total}</p> */}
                {Object.entries(dialogueContent).map(([key, value], index) => (
                  <p style={{fontSize:"20x", fontWeight:"600"}} key={index}>{key}: {value}</p>
                ))}

          </Card>       
       
      </Modal>
    </>
  );
}
