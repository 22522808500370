import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useContext } from 'react';
import { BaseUrlContext } from 'src/context/BaseUrlContext2';
// import { useNavigate } from "react-router-dom";
import Scrollbar from '../../components/scrollbar';
import Modal from "src/Modals/Modal";
import { useNavigate } from "react-router-dom";
import styles from "./Roles.module.css"
import { styled } from '@mui/material/styles';
import Axios from "../../axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AuditLog from '../Common/AuditLog'

// import CheckBox from '../../components/inputs/CheckBox';
// import TextField from '../../components/inputs/Textbox';
import TextField from '@mui/material/TextField';

import {
    Card,
    Container,
    Typography,
    IconButton,
    Grid,
  } from '@mui/material';

  const TABLE_HEAD = [
    { id: 'Permission', label: 'Permission', alignRight: false },
    { id: 'Create', label: '', alignRight: false },
    { id: 'Edit', label: '', alignRight: false },
    { id: 'Delete', label: '', alignRight: false },
  ];


export default function UserPage() {  
    const navigate = useNavigate();
    const [roleName, setRoleName]= useState('');
    const [roleDescription, setRoleDescription]= useState('');
    const [rolePermissions, setRolePermissions] = useState([]);
    const [roleId, setRoleId] = useState('');
    const [permissionList, setPermissionList] = useState([]);
    const [response, setResponse] = useState([])
    const [loading, setLoading] = useState(false);
    const baseUrl = useContext(BaseUrlContext);
    const [loadingUser, setLoadingUser] = useState(false);
    const [groupedData, setGroupedData] = useState([]);
    const [checked, setChecked] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [sytemInformation, setSystemInformation] = useState([]);

    const collateTextValue = (event) => {
      const { name, value } = event;
      switch (name) {
        case "roleName":
            setRoleName(value);
        case "roleDescription":
            setRoleDescription(value);
        default:
          break;
      }
    };
    
    const handleSubmit = async (event) => {
      setIsPending(true);
      event.preventDefault();
      let aliases = [];
      rolePermissions.forEach((element) => {
        const aliasObject = element.alias;
        aliases.push(aliasObject);
      });

      const formData = {
        description:roleDescription,
        name:roleName,
        permissions:aliases
      };
      // return console.log(formData);
      // const uniqueObject = Object.values(formData.aliases).reduce((accumulator, obj) => {
      //   if (!accumulator.some(item => item.alias === obj.alias)) {
      //     accumulator.push(obj);
      //   }
      //   return accumulator;
      // }, []); 
      Axios.put(`api/role/${roleId}`, formData)
      .then((response) => {
        setIsPending(false);
        notify_user("Role updated successfully", "success")
        goto("roles")
      }).catch((error) => {
        setIsPending(false);
        notify_user(error.message, "error")
        console.error(error);
      });
    };

    const notify_user = (message, toast_type) => {
      if (toast_type == 'success') {
        toast.success(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#d0efd0',
            color:"#000000"
          },
        })
      }else if(toast_type == 'error'){
        toast.error(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#f2c4c4',
            color:"#000000"
          },
        })
      }else if (toast_type == 'info'){
        toast.info(message, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#97ddef',
            color:"#000000"
          },
        })
      }
      
    };

    const goto = (d) => {
        navigate('/dashboard/'+d)
      };

    const getPermission = async (page) => { 
      setLoadingUser(true);
      Axios.get(`api/permission?size=400`)
      .then((response) => {
        setLoadingUser(false);
        setPermissionList(response?.data?.data)
        setResponse(response?.data?.data)
        setLoading(false)

        groupData(response?.data?.data)
      }).catch((error) => {
        setLoadingUser(false);
        setLoading(false)
        console.error(error);
      });
    };
    
    useEffect(() => {
      getPermission();
    }, []); 

    const groupData = (permissionList) =>{
      const mygroupedData = permissionList.reduce((acc, permission) => {
        const { entity_name, name, id, alias, isChecked } = permission;
        if (!acc[entity_name]) {
          acc[entity_name] = [];
        }
        acc[entity_name].push({ id, name, alias, isChecked });
        return acc;
      }, {});
      
      setGroupedData(mygroupedData)

    }
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const id = urlParams.get('id');
        getSingleRoleDetails(id)
    }, [location]);

        const getSingleRoleDetails = async (id) => { 
            setLoading(true)

            Axios.get(`api/role/${id}`)
            .then((response) => {
              console.log(response?.data);
                setSystemInformation(response?.data?.data)
                setRoleDescription(response?.data?.data?.description)
                setRoleName(response?.data?.data?.name)
                setRolePermissions(response?.data?.data?.permissions)
                setRoleId(response?.data?.data?.id)
                setLoading(false);
            }).catch((error) => {
                console.error(error);
                setLoading(false);
            });
        };

        let myNewArray = [];
        useEffect(() => {
            myNewArray = getcheckedData(rolePermissions, permissionList)
        }, [rolePermissions, permissionList]);

        const getcheckedData = (rolePermission, permissionList) => {
            permissionList =  permissionList?.map(permission => {
              const isChecked = rolePermission?.some(role => role?.id === permission?.id);
              return { ...permission, isChecked };
            });

            groupData(permissionList);

          }

        const CustomCheckBox = (props)=>{
          const {id, name, isChecked, alias} = props.items
          const [checked, setChecked]= useState(isChecked);
          
          
          const setCHeckedValue = () => {
            const updatedChecked = !checked;
            props.items.isChecked = updatedChecked;
            setChecked(updatedChecked);
        
            if (updatedChecked) {
              // Checkbox is checked, add to rolePermissions
              rolePermissions.push(props.items);
            } else {
              // Checkbox is unchecked, remove from rolePermissions
              const index = rolePermissions.findIndex((item) => item.id === id);
              if (index !== -1) {
                rolePermissions.splice(index, 1);
              }
            }
          };
          
          return (
            <div className="col-md-4">
                <FormControlLabel
                control={
                  <Checkbox
                    key={id}
                    checked={checked}
                    onChange={setCHeckedValue}
                    color="primary"
                  />
                }
                label={name}
              />
            </div>
          )
        }

    if (loading) {
      return <div>Loading...</div>;
    }
    const handleSelectAllChange = (event) => {
      
    };
    return (
      <>
        <Helmet>
          <title> Edit Roles </title>
        </Helmet>
        
  
        {/* <Container> */}
        <ToastContainer />
            <div className={`${styles.header_container} p-4 d-flex mb-3 text-white`} >
            {/* <img src="/assets/icons/roles_icon.png" alt="icon" />&nbsp;&nbsp; */}
                <span className={`${styles.header_text}`}> Edit Role </span>
            </div>
            <Card className='p-5 pt-3 light_card_background'>
                <form action="" className="mt-5" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <TextField
                                name={roleName}
                                label={"Role Name"}
                                variant="outlined"
                                value={roleName}
                                style={{width:"100%"}}
                                required
                                onChange={(e)=>{setRoleName(e.target.value)}} 
                                sx={{
                                    "& .MuiInputLabel-root": {
                                    color: "#808080"
                                    },
                                    "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "#808080"
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#808080"
                                    },
                                    "&.Mui-focused": {
                                        "& fieldset": {
                                        borderColor: "#3c8dbc"
                                        },
                                        "& .MuiInputLabel-root": {
                                        color: "#3c8dbc"
                                        }
                                    }
                                    }
                                }}
                            />
                        </div>
                        <div className="col-md-6 mb-1">
                            <TextField 
                                name={roleDescription}
                                value={roleDescription}
                                label="Role Description" 
                                style={{width:"100%"}}
                                variant="outlined"
                                required
                                onChange={(e)=>{setRoleDescription(e.target.value)}}
                                sx={{
                                    "& .MuiInputLabel-root": {
                                    color: "#808080"
                                    },
                                    "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "#808080"
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#808080"
                                    },
                                    "&.Mui-focused": {
                                        "& fieldset": {
                                        borderColor: "#3c8dbc"
                                        },
                                        "& .MuiInputLabel-root": {
                                        color: "#3c8dbc"
                                        }
                                    }
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className='border_bottom mb-3' style={{color:"#575151"}}>
                        <h5>
                            Permissions
                        </h5>
                    </div>
                    
                    <Scrollbar>
                        <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}>
                            <table className={`${styles.table} table-hover table-striped`}>
                                <thead className={`${styles.thead}`}>
                                    <tr>
                                        {TABLE_HEAD.map((el, i) => {
                                        return (
                                            <th  key={i} className="text-muted th">{el.label}</th>
                                        )
                                        })}
                                    </tr>
                                </thead>
                                {Object.entries(groupedData).length === 0 ? (
                                <tbody >
                                    <tr >
                                    <td colSpan={8}>
                                        <p className="alert alert-info mt-3 w-100 text-center" role="alert">
                                        No Permission/Role available.
                                        </p>
                                    </td>
                                    </tr>
                                </tbody>
                                ):(

                                  <tbody>
                                  {Object.entries(groupedData).map(([group, items]) => (
                                    <tr key={group} style={{ cursor: 'pointer' }} className={styles.tr}>
                                      <td className='text-black' colSpan="2">{group}</td>
                                      <td>
                                          <div className="row text-black">
                                            {items.map((item, i) =>  <CustomCheckBox key={i} items={item}/>)}
                                          </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>

                                )}
                            </table>
                        </div>
                        {loadingUser &&
                            <div className='loader'>
                                <CircularProgress size={80} style={{ color: '#3c8dbc' }} />
                            </div>
                        }
                    </Scrollbar>

                    
                    <div className="mb-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button type="button" className={`btn btn-block buttons_outline`} onClick={()=>goto("roles")}>
                            Back
                        </button>
                        <button type="submit" className={`btn btn-block buttons`}>
                            Update &nbsp; &nbsp;
                          {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                        </button>
                    </div>

                </form>
            </Card> 
          {/* <AuditLog prop={sytemInformation}/> */}

        {/* </Container> */}

      </>
    );
  }