import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
// sections
import { LoginForm } from '../../sections/auth/login';

// @mui
import { IconButton, InputAdornment, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TextField from '../../components/inputs/Textbox';
import { BaseUrlContext } from 'src/context/BaseUrlContext2';

// components

import { login } from '../../actions/authActions';


// ----------------------------------------------------------------------

import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// import { toast } from 'react-hot-toast';
import { Toaster, toast } from 'react-hot-toast';

import { useContext, useState } from "react"
import axios from 'axios';
import { useAuth } from 'src/hooks/useAuth';
import LogoHeader from './ExternalHeader';
import Axios from "../../axiosInstance";
import {
  Card
} from '@mui/material';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
export default function LoginPage() {
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');

  const [email, setEmail] = useState('');
  const notify = (res) => toast.success(res);
  const notifyError = () => toast.error("That didn't work please try again later .");
  const [signUpError, setSignUpError] = useState(null);
  const [isPending,setIsPending] = useState(false);
  const baseUrl = useContext(BaseUrlContext);
  
  // const { dispatch } = useAuth();
  // const [showPassword, setShowPassword] = useState(false);
  // const [email, setEmail] = useState('');
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const collateTextValue = (event) => {
    const { name, value } = event;
    if (name === "email"){
      setEmail(value);
    }
  };
  
  const goto = (d) => {
      navigate('/'+d)
  };

  const handleSubmit = async (event) => {
    setIsPending(true);
    event.preventDefault();
    const formData = {
      email
    };
    axios.post(`${baseUrl}/auth/forgot-password`, formData)
    .then((response) => {
      let email = response?.data?.data.email;
      let userId = response?.data?.data.id;
      goto(`verification?identity=${userId}&email=${encodeURIComponent(email)}`)
    }).catch((error) => {
      if (error.response.data.message) {
        notify_user(error.response.data.message, "error")
      }else{
        notify_user("Email address not sent", "error")
      }
      console.error(error);
    });
  };

  const notify_user = (message, toast_type) => {
    if (toast_type == 'success') {
      toast.success(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#d0efd0',
          color:"#000000"
        },
      })
    }else if(toast_type == 'error'){
      toast.error(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#f2c4c4',
          color:"#000000"
        },
      })
    }else if (toast_type == 'info'){
      toast.info(message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#97ddef',
          color:"#000000"
        },
      })
    }
    
  };

  

  const handleClick = () => {
    navigate('/login', { replace: true });
  };

 

  return (
    <>
      <Toaster />
      <Helmet>
        <title> Forgot Password </title>
      </Helmet>

      
      <StyledRoot sx={{backgroundColor:"#c5dbe8"}}>
        <LogoHeader>
          <Logo
            sx={{
              position: 'fixed',
              top: { xs: 16, sm: 24, md: 40 },
              left: { xs: 16, sm: 24, md: 40 },
            }}
          />
        </LogoHeader>

        <Container maxWidth="sm">
          <StyledContent>

            <Typography variant="h4" gutterBottom>
              <div className='text-center'>
                Forgot Password
              </div>
            </Typography>

            <p className='theme_link'
              onClick={()=>handleClick()}
            >
              Back
            </p>
            <Card sx={{p:4}}>
            {/* <Form /> */}
            <Stack spacing={3} className="mt-5"> 
              <TextField 
                  name="email"
                  title="Enter your Email address" 
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  onValueChange={collateTextValue} 
                  required
              />
            </Stack>

            <LoadingButton className="mt-3 bg_green" fullWidth size="large" type="submit" variant="contained" onClick={handleSubmit}>
              <span>
                Send &nbsp;
              </span>
              {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
            </LoadingButton>
            {/* <div className="mt-3 bg_green" onClick={handleSubmit}>
            <span>
                Send &nbsp;
              </span>
              {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
            </div> */}
            
            </Card>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
